import React from 'react';
import { useHoleSection } from '../../components/HoleSectionProvider';
// import { GlobalStateContext } from '../../components/GlobalStateContext';

import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

function SidebarModules() {
    const { holeSection } = useHoleSection();
    // const { selectedItems } = useContext(GlobalStateContext);
    // const activeModules = selectedItems?.active_modules;

    return (

        <Nav defaultActiveKey="#" className="flex-column ms-3">

                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/trajectory" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Trajectory Design
                </Nav.Link>

                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/hydraulics" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Hydraulics
                </Nav.Link>

                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/swab_and_surge" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Swab and Surge
                </Nav.Link>

                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/torque_and_drag" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Torque and Drag
                </Nav.Link>
            
                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/casing_design" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Casing Design
                </Nav.Link>
            
                <Nav.Link
                    as={Link}
                    to={holeSection.length > 0 ? "/kick_tolerance" : "#"}
                    disabled={!holeSection.length > 0}
                >
                    Kick Tolerance
                </Nav.Link>          

        </Nav>
    );
}

export default SidebarModules;