// dataProvider.js
import axios from 'axios';

// const apiUrl = 'http://127.0.0.1:5000';
const apiUrl = process.env.REACT_APP_ADMIN_API_BASE_URL || 'https://www.welldesigner.com/api';

const customDataProvider = {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const response = await axios.get(`${apiUrl}/${resource}`, {
            params: {
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
                ...params.filter,
            },
            withCredentials: true,
        });

        return {
            data: response.data.data || response.data,
            total: response.data.total,
        };
    },

    getOne: async (resource, params) => {
        const response = await axios.get(`${apiUrl}/${resource}/${params.id}`, {
            withCredentials: true,
        });
        return {
            data: response.data,
        };
    },

    getMany: async (resource, params) => {
        const response = await axios.get(`${apiUrl}/${resource}`, {
            params: {
                id: params.ids,
            },
            withCredentials: true,
        });
        return {
            data: response.data.data || response.data,
        };
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const response = await axios.get(`${apiUrl}/${resource}`, {
            params: {
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
                [params.target]: params.id,
                ...params.filter,
            },
            withCredentials: true,
        });

        return {
            data: response.data.data || response.data,
            total: response.data.total,
        };
    },

    // Implement other methods (create, update, delete) if necessary
};

export default customDataProvider;
