import React, { useState } from "react";
import AnnularPressureLossCalculator from "../utils/AnnularPressureLossCalculator";
import PressureDropInsidePipeCalculator from "../utils/PressureDropInsidePipeCalculator";
import DrillBitPressureLossCalculator from "../utils/DrillBitPressureLossCalculator";
import SurfaceEquipmentPressureLossCalculator from "../utils/SurfaceEquipmentPressureLossCalculator";
import { Tab, Tabs } from "react-bootstrap";

export default function AnnularPressureLossForm() {
    const [formData, setFormData] = useState({
        mw: "",
        yp: "",
        pv: "",
        flowRate: "",
        diamHole: "",
        diamPipe: "",
        length: "",
        fann3: "",
        fann6: "",
        fann600: "",
        fann300: "",
        cuttingsConc: "",
        pipeEccentricity: "",
        hydraulic_model: "Herschel Bulkley",
        toolLength: "",
        pipeId: "",
        cumRate: ""
    });

    const [result, setResult] = useState(null);
    const [annularVelocity, setAnnularVelocity] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { flowRate, diamHole, diamPipe } = formData;
        const av = Math.round((24.51 * flowRate) / (Math.pow(diamHole, 2) - Math.pow(diamPipe, 2)));
        setAnnularVelocity(av);
        const annPressLoss = AnnularPressureLossCalculator({ ...formData, av });
        setResult(annPressLoss);
    };

    return (
        <div className="container mt-5 min-vh-100">
            <h2>Pressure Loss Calculators</h2>
            <Tabs defaultActiveKey="annularPressureLoss" className="mb-3">
                <Tab eventKey="annularPressureLoss" title="Annular Pressure Loss">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Mud Weight (mw)</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="mw"
                                    value={formData.mw}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Yield Point (yp)</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="yp"
                                    value={formData.yp}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Plastic Viscosity (pv)</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="pv"
                                    value={formData.pv}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>Flow Rate</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="flowRate"
                                    value={formData.flowRate}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Hole Diameter (diamHole)</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="diamHole"
                                    value={formData.diamHole}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Pipe Diameter (diamPipe)</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="diamPipe"
                                    value={formData.diamPipe}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>Length</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="length"
                                    value={formData.length}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Fann 3</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="fann3"
                                    value={formData.fann3}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Fann 6</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="fann6"
                                    value={formData.fann6}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>Fann 600</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="fann600"
                                    value={formData.fann600}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Fann 300</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="fann300"
                                    value={formData.fann300}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Cuttings Concentration</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="cuttingsConc"
                                    value={formData.cuttingsConc}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label>Pipe Eccentricity</label>
                                <input
                                    type="number"
                                    step="any"
                                    name="pipeEccentricity"
                                    value={formData.pipeEccentricity}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Hydraulic Model</label>
                                <select
                                    name="hydraulic_model"
                                    value={formData.hydraulic_model}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    <option value="Herschel Bulkley">Herschel Bulkley</option>
                                    <option value="Power law">Power law</option>
                                    <option value="Bingham plastic">Bingham plastic</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">
                            Calculate
                        </button>
                    </form>
                    {annularVelocity !== null && (
                        <div className="mt-4">
                            <h3>Calculated Annular Velocity:</h3>
                            <p>{annularVelocity} ft/min</p>
                        </div>
                    )}
                    {result !== null && (
                        <div className="mt-4">
                            <h3>Calculated Annular Pressure Loss:</h3>
                            <p>{result} psi</p>
                        </div>
                    )}
                </Tab>
                <Tab eventKey="pressureDropInsidePipe" title="Pressure Drop Inside Pipe">
                    <PressureDropInsidePipeCalculator formData={formData} setFormData={setFormData} />
                </Tab>
                <Tab eventKey="drillBitPressureLossCalculator" title="Pressure Drop at Bit">
                    <DrillBitPressureLossCalculator />
                </Tab>
                <Tab eventKey="surfaceEquipmentPressureLossCalculator" title="Surface Pressure Drop">
                    <SurfaceEquipmentPressureLossCalculator />
                </Tab>
            </Tabs>
        </div>
    );
}
