import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Spinner, Table } from "react-bootstrap";

const ModuleSelection = ({ onSubmit, userId }) => {
    const [modules, setModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchModules = async () => {
            try {
                setLoading(true);

                // Fetch all available modules
                const allModulesResponse = await axios.get(`${baseURL}/modules`, {
                    withCredentials: true,
                });

                // Fetch the user's subscribed modules
                const subscribedModulesResponse = await axios.get(
                    `${baseURL}/users/${userId}/subscriptions`,
                    {
                        withCredentials: true,
                    }
                );

                const allModules = allModulesResponse.data;
                const subscribedModules = subscribedModulesResponse.data.map(
                    (subscription) => subscription.module_id
                );

                // Filter out modules the user is already subscribed to
                const availableModules = allModules.filter(
                    (module) => !subscribedModules.includes(module.id)
                );

                console.log('availableModules: ', availableModules);
                console.log('subscribedModulesResponse: ', subscribedModulesResponse.data);


                setModules(availableModules);
            } catch (error) {
                console.error("Error fetching modules:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchModules();
    }, [baseURL, userId]);

    const handleModuleToggle = (moduleId) => {
        setSelectedModules((prev) =>
            prev.includes(moduleId)
                ? prev.filter((id) => id !== moduleId)
                : [...prev, moduleId]
        );
    };

    const handleSubmit = () => {
        onSubmit(selectedModules);
    };

    const calculateTotalPrice = () => {
        const total = selectedModules
            .map((moduleId) => {
                const module = modules.find((mod) => mod.id === moduleId);
                const price = Number(module?.price); // Convert to number
                return !isNaN(price) ? price : 0; // Fallback to 0 if conversion fails
            })
            .reduce((sum, price) => sum + price, 0); // Calculate total price

        return total.toFixed(2); // Ensure numeric output with 2 decimal places
    };

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <div>
            {/* <h6>Select Modules</h6> */}
            {modules.length === 0 ? (
                <div className="bg-light p-3">
                    <p>You are subscribed to all available modules.</p>

                </div>
            ) : (
                    <div>
                        <p>Please subscribe to the remaining modules here:</p>
                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Module Name</th>
                                    <th>Description</th>
                                    <th>Price (USD)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modules.map((module) => (
                                    <tr key={module.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`module-${module.id}`}
                                                checked={selectedModules.includes(module.id)}
                                                onChange={() => handleModuleToggle(module.id)}
                                            />
                                        </td>
                                        <td>
                                            <label
                                                className="form-check-label"
                                                htmlFor={`module-${module.id}`}
                                            >
                                                {module.name}
                                            </label>
                                        </td>
                                        <td>
                                            <label
                                                className="form-check-label"
                                                htmlFor={`module-${module.id}`}
                                            >
                                                {module.description}
                                            </label>
                                            
                                        </td>
                                        <td>${Number(module.price).toFixed(2)} {/* Convert string to number */}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>
                
            )}
            {modules.length > 0 && (
                <div>
                    <p className="mt-3">
                        <strong>Total Price: ${calculateTotalPrice()}</strong>
                    </p>
                    <Button className="mt-3" onClick={handleSubmit}>
                        Subscribe
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ModuleSelection;
