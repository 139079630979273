import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

export const BlockList = () => (
    <List>
        <Datagrid>
            <TextField source="block_name" />
            <ReferenceField source="company_id" reference="companies" />
            <ReferenceField source="company_id" reference="companies" label="Company">
                <TextField source="company_name" />
            </ReferenceField>

            <TextField source="id" />
        </Datagrid>
    </List>
);