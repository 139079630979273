import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

export const WellList = () => (
    <List>
        <Datagrid>
            {/* <ReferenceField source="field_id" reference="fields" /> */}
            <ReferenceField source="field_id" reference="fields" label="Field">
                <TextField source="field_name" />
            </ReferenceField>
            <TextField source="id" />
            <TextField source="well_name" />
        </Datagrid>
    </List>
);