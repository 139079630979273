import React, { useRef, useEffect, useState, useContext, useCallback, useMemo } from 'react';
import TrajectoryForm from './TrajectoryForm';
import 'chart.js/auto';
// import { round } from 'mathjs';
import { Scatter } from 'react-chartjs-2';
import axios from 'axios';
import { Button, Alert, Modal, Table, Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { GlobalStateContext } from '../GlobalStateContext';
// import AddTargetForm from './AddTargetForm';

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { calculateSurveyDataFields } from '../utils/calculateSurveyDataFields';
import FullScreenModal from './FullScreenModal';

Chart.register(...registerables, ChartDataLabels);


const TrajectoryDesigner = () => {
    // const { token } = useToken();
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const { selectedItems } = useContext(GlobalStateContext);
    const [surveyData, setSurveyData] = useState([]);
    const [endMD, setEndMD] = useState('');
    const [startIncl, setStartIncl] = useState('');
    const [endIncl, setEndIncl] = useState('');
    const [startAzimuth, setStartAzimuth] = useState('');
    const [endAzimuth, setEndAzimuth] = useState('');
    const [sectionType, setSectionType] = useState('vertical'); // Defaults to vertical
    const wellId = selectedItems?.well_id;
    const wellboreId = selectedItems?.wellbore_id;
    const [surveyName, setSurveyName] = useState('');
    const wellboreName = selectedItems?.wellbore_name;

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success'); // success, danger, etc.
    
    // const [targetCoordinates, setTargetCoordinates] = useState([]);
    const siteData = selectedItems?.siteData;

    const siteNorthing = siteData?.northing;
    const siteEasting = siteData?.easting;
    const waterDepth = siteData?.water_depth;
    const epsgCode = siteData?.epsg_code;

    const [showModal, setShowModal] = useState(false);
    const [showLabel, setShowLabel] = useState(false);
    const [viewVsChartControls, setViewVsChartControls] = useState(false);
    const [viewPlanChartControls, setViewPlanChartControls] = useState(false);

    const chartRef = useRef(null);
    const [xMin, setXMin] = useState(null);
    const [xMax, setXMax] = useState(null);
    const [yMin, setYMin] = useState(null);
    const [yMax, setYMax] = useState(null);

    const [planxMin, setPlanXMin] = useState(null);
    const [planxMax, setPlanXMax] = useState(null);
    const [planyMin, setPlanYMin] = useState(null);
    const [planyMax, setPlanYMax] = useState(null);

    const [showTitle, setShowTitle] = useState(true);
    const [showDataLabels, setShowDataLabels] = useState(false);
    const [showGridLines, setShowGridLines] = useState(true);

    const [showPlanTitle, setShowPlanTitle] = useState(true);
    const [showPlanDataLabels, setShowPlanDataLabels] = useState(false);
    const [showPlanGridLines, setShowPlanGridLines] = useState(true);
    const [showFullScreenModal, setShowFullScreenModal] = useState(false);


    const handleViewVsControlsCheckboxChange = (e) => {
        setViewVsChartControls(e.target.checked);
    };

    const handleViewPlanControlsCheckboxChange = (e) => {
        setViewPlanChartControls(e.target.checked);
    };

    // Helper function for degrees to radians conversion
    const toRadians = (degrees) => (degrees * Math.PI) / 180;
    const toDegrees = (radians) => (radians * 180) / Math.PI;

    const round = (n, decimals = 0) =>
        // Convert 'n' to a string in scientific notation with the desired number of decimals
        Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);
    
    const [surveyTargets, setSurveyTargets] = useState([]);
    const [newSurveyTarget, setNewSurveyTarget] = useState({
        target_name: '',
        northing: '',
        easting: '',
        tvd: ''
    });

    const fetchSurveyTargets = useCallback(async () => {
        try {
            const response = await axios.get(
                `${baseURL}/input/survey_targets/${wellId}/${wellboreId}`,
                {withCredentials: true}
            );
            setSurveyTargets(response.data);
        } catch (error) {
            console.error('Error fetching survey targets:', error);
            setAlertMessage('Failed to load survey targets.');
        }
    }, [wellId, wellboreId, baseURL]); // Only recreate fetchSurveyTargets if these values change

    // Fetch survey targets on component mount
    useEffect(() => {
        fetchSurveyTargets();
    }, [fetchSurveyTargets]); // Now useEffect only depends on the memoized function


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSurveyTarget((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const addSurveyTarget = async () => {
        try {
            const response = await axios.post(
                `${baseURL}/input/survey_targets/${wellId}/${wellboreId}`,
                newSurveyTarget,
                { withCredentials: true }
            );
            setSurveyTargets((prevTargets) => [...prevTargets, response.data]);
            setNewSurveyTarget({ target_name: '', northing: '', easting: '', tvd: '' }); // Reset form
            setAlertMessage('Survey target added successfully.');
        } catch (error) {
            console.error('Error adding survey target:', error);
            setAlertMessage('Failed to add survey target.');
        }
    };

    const updateSurveyTarget = async (survey_field_id, updatedTarget) => {
        try {
            const response = await axios.put(
                `${baseURL}/input/survey_targets/${survey_field_id}`,
                updatedTarget,
                { withCredentials: true }
            );
            setSurveyTargets((prevTargets) =>
                prevTargets.map((target) =>
                    target.survey_field_id === survey_field_id ? response.data : target
                )
            );
            setAlertMessage('Survey target updated successfully.');
        } catch (error) {
            console.error('Error updating survey target:', error);
            setAlertMessage('Failed to update survey target.');
        }
    };

    const deleteSurveyTarget = async (survey_field_id) => {
        try {
            await axios.delete(
                `${baseURL}/input/survey_targets/${survey_field_id}`,
                { withCredentials: true }
            );
            setSurveyTargets((prevTargets) =>
                prevTargets.filter((target) => target.survey_field_id !== survey_field_id)
            );
            setAlertMessage('Survey target deleted successfully.');
        } catch (error) {
            console.error('Error deleting survey target:', error);
            setAlertMessage('Failed to delete survey target.');
        }
    };

    // Memoize siteData to avoid unnecessary recalculations in case siteData changes frequently
    const memoizedSiteData = useMemo(() => siteData, [siteData]);

    const fetchSurveyData = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}/input/surveys/${wellId}/${wellboreId}`, { withCredentials: true });

            if (response.data && Array.isArray(response.data)) {
                const sortedData = response.data.sort((a, b) => a.md_survey - b.md_survey);

                // Await the calculated data as it involves asynchronous operations
                const calculatedData = await calculateSurveyDataFields(sortedData, memoizedSiteData);

                setSurveyData(calculatedData); // Now set the resolved data
            } else {
                console.error('Unexpected data format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching survey data:', error);
            setAlertMessage('Failed to load existing survey data.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    }, [wellId, wellboreId, baseURL, memoizedSiteData]); // Memoize dependencies

    const calculateDLS = (dMD, dl2_degrees) => {
        return dl2_degrees / dMD * 100
    };

    const calculateTVD = (lastSurvey, endIncl, dMD, beta_i) => {
        const rf = (2 / beta_i) * Math.tan(beta_i / 2);
        return lastSurvey.tvd_survey + (dMD / 2) * (Math.cos(toRadians(lastSurvey.incl_survey)) + Math.cos(toRadians(endIncl))) * rf;
    };

    // Function to handle the addition of new survey data
    const handleAddSurveySection = (newSurvey) => {
        setSurveyData((prevSurveyData) => {
            const updatedData = [...prevSurveyData, newSurvey];

            return calculateSurveyDataFields(updatedData, selectedItems); // Recalculate NS, EW, PHD
        });
    };

    // Fetch existing survey data when the component mounts
    useEffect(() => {
        if (wellId && wellboreId) {
            fetchSurveyData();
        }
    }, [wellId, wellboreId, fetchSurveyData]);

    // Function to generate vertical section
    const handleAddVertical = (endMD) => {
        const lastSurvey = surveyData[surveyData.length - 1] || {
            md_survey: 0,
            incl_survey: 0,
            azim_survey: 0,
            tvd_survey: 0,
            ns: 0,
            ew: 0,
        };
        const dMD = round((endMD - lastSurvey.md_survey),2);

        const newSurvey = {
            md_survey: endMD,
            incl_survey: lastSurvey.incl_survey,
            azim_survey: lastSurvey.azim_survey,
            tvd_survey: lastSurvey.tvd_survey + dMD,
            ns: lastSurvey.ns,
            ew: lastSurvey.ew,
            // dls: 0, // No dogleg for vertical section
            phd: 0,
            // vs: 0,  // No vertical section movement
            dls_survey: 0, // DLS is 0 for vertical sections
            dl_survey: 0,
            survey_name: surveyName,
            show_label: showLabel

        };

        handleAddSurveySection(newSurvey);

    };

    // Function to generate build section
    const handleAddBuild = (endMD, startIncl, endIncl, startAzimuth, endAzimuth) => {
        const lastSurvey = surveyData[surveyData.length - 1] || {
            md_survey: 0,
            incl_survey: 0,
            azim_survey: 0,
            tvd_survey: 0,
            ns: 0,
            ew: 0,
            phd: 0,
            dl_survey: 0,
        };

        const dMD = round((endMD - lastSurvey.md_survey), 2);

        const deltaIncl = toRadians(endIncl - lastSurvey.incl_survey);
        const deltaAzim = toRadians(endAzimuth - lastSurvey.azim_survey);

        const beta_i = Math.acos(Math.cos(deltaIncl) - Math.sin(toRadians(lastSurvey.incl_survey)) * Math.sin(toRadians(endIncl)) * (1 - Math.cos(deltaAzim)));
        const rf = (2 / beta_i) * Math.tan(beta_i / 2);

        const dE = (dMD / 2) * (Math.sin(toRadians(lastSurvey.incl_survey)) * Math.sin(toRadians(lastSurvey.azim_survey)) + Math.sin(toRadians(endIncl)) * Math.sin(toRadians(endAzimuth))) * rf;
        const dN = (dMD / 2) * (Math.sin(toRadians(lastSurvey.incl_survey)) * Math.cos(toRadians(lastSurvey.azim_survey)) + Math.sin(toRadians(endIncl)) * Math.cos(toRadians(endAzimuth))) * rf;

        const tvd_survey = round((calculateTVD(lastSurvey, endIncl, dMD, beta_i)), 2);

        // const dPHD = dN * Math.cos(toRadians(endAzimuth)) + dE * Math.sin(toRadians(endAzimuth));
        const dPHD = Math.sqrt((lastSurvey.ns + dN) ** 2 + (lastSurvey.ew + dE) ** 2);


        const newSurvey = {
            md_survey: endMD,
            incl_survey: endIncl,
            azim_survey: endAzimuth,
            tvd_survey: tvd_survey,
            ns: lastSurvey.ns + dN,
            ew: lastSurvey.ew + dE,
            phd: lastSurvey.phd + dPHD,
            dls_survey: calculateDLS(dMD, beta_i * 180 / Math.PI),
            dl_survey: beta_i * 180 / Math.PI,
            survey_name: surveyName,
            show_label: showLabel

        };

        // setSurveyData((prevSurveyData) => [...prevSurveyData, newSurvey]);
        handleAddSurveySection(newSurvey);

    };

    // Function to generate tangent section
    const handleAddTangent = (endMD) => {
        const lastSurvey = surveyData[surveyData.length - 1] || {
            md_survey: 0,
            incl_survey: 0,
            azim_survey: 0,
            tvd_survey: 0,
            ns: 0,
            ew: 0,
            phd: 0,
            dl_survey: 0,
        };

        const dMD = round((endMD - lastSurvey.md_survey), 2);

        // Calculate the dogleg severity
        const incl1 = toRadians(lastSurvey.incl_survey);
        const incl2 = toRadians(endIncl);
        const azim1 = toRadians(lastSurvey.azim_survey);
        const azim2 = toRadians(endAzimuth);

        // const deltaIncl = incl2 - incl1;
        const deltaAzim = azim2 - azim1;

        const dogleg = Math.acos(
            Math.sin(incl1) * Math.sin(incl2) * Math.cos(deltaAzim) +
            Math.cos(incl1) * Math.cos(incl2)
        );

        // Dogleg severity
        const dls_survey = (dogleg / dMD) * 100;

        // Calculate the ratio factor (RF)
        const rf = (dogleg === 0) ? 1 : (2 / dogleg) * Math.tan(dogleg / 2);

        // Compute TVD, Northing, and Easting displacements
        const tvd_survey = lastSurvey.tvd_survey + dMD * rf * (Math.cos(incl1) + Math.cos(incl2)) / 2;
        const dN = dMD * rf * (Math.sin(incl1) * Math.cos(azim1) + Math.sin(incl2) * Math.cos(azim2)) / 2;
        const dE = dMD * rf * (Math.sin(incl1) * Math.sin(azim1) + Math.sin(incl2) * Math.sin(azim2)) / 2;

        // Horizontal displacement (PHD)
        const dPHD = Math.sqrt(dN ** 2 + dE ** 2);

        const newSurvey = {
            md_survey: endMD,
            incl_survey: endIncl,
            azim_survey: endAzimuth,
            tvd_survey,
            ns: lastSurvey.ns + dN,
            ew: lastSurvey.ew + dE,
            phd: lastSurvey.phd + dPHD,
            dls_survey, // Dogleg severity
            dl_survey: dogleg,
            survey_name: surveyName,
            show_label: showLabel

        };

        // setSurveyData((prevSurveyData) => [...prevSurveyData, newSurvey]);
        handleAddSurveySection(newSurvey);
    };

    // Function to generate horizontal section
    const handleAddHorizontal = (endMD) => {
        // You can implement a horizontal section generation function
        const lastSurvey = surveyData[surveyData.length - 1]

        const dMD = round((endMD - lastSurvey.md_survey), 2);

        const dE = dMD * (Math.sin(toRadians(lastSurvey.incl_survey)) * Math.sin(toRadians(lastSurvey.azim_survey)));
        const dN = dMD * (Math.sin(toRadians(lastSurvey.incl_survey)) * Math.cos(toRadians(lastSurvey.azim_survey)));
        const dPHD = dN * Math.cos(toRadians(endAzimuth)) + dE * Math.sin(toRadians(endAzimuth));

        const newSurvey = {
            md_survey: endMD,
            incl_survey: lastSurvey.incl_survey,
            azim_survey: lastSurvey.azim_survey,
            tvd_survey: lastSurvey.tvd_survey,
            ns: lastSurvey.ns + dN,
            ew: lastSurvey.ew + dE,
            // vs: lastSurvey.vs + dMD * Math.sin(toRadians(endIncl)),
            phd: lastSurvey.phd + dPHD,
            dls_survey: 0, // this is a tangent section, hence 0
            dl_survey: 0,
            survey_name: surveyName,
            show_label: showLabel

        };
        handleAddSurveySection(newSurvey);

    };

    // Function to generate drop section
    const handleAddDropSection = (endMD, startIncl, endIncl) => {
        // You can implement a drop section generation function
    };

    // Handle form submission for adding trajectory sections
    const handleAddSection = () => {
        const endMDVal = parseFloat(endMD);

        switch (sectionType) {
            case 'vertical':
                handleAddVertical(endMDVal);
                break;

            case 'build': {
                const startInclVal = parseFloat(startIncl);
                const endInclVal = parseFloat(endIncl);
                const startAzimuthVal = parseFloat(startAzimuth);
                const endAzimuthVal = parseFloat(endAzimuth);

                handleAddBuild(endMDVal, startInclVal, endInclVal, startAzimuthVal, endAzimuthVal);
                break;
            }

            case 'tangent':
                handleAddTangent(endMDVal);
                break;

            case 'horizontal':
                handleAddHorizontal(endMDVal);
                break;

            case 'drop': {
                const dropStartInclVal = parseFloat(startIncl);
                const dropEndInclVal = parseFloat(endIncl);
                handleAddDropSection(endMDVal, dropStartInclVal, dropEndInclVal);
                break;
            }

            default:
                break;
        }
    };


    // Get the last survey entry
    const lastSurvey = surveyData[surveyData.length - 1];

    // Get the last ns and ew values
    const lastNS = lastSurvey?.ns;
    const lastEW = lastSurvey?.ew;
    const vsAzim = toDegrees(Math.atan2(lastEW, lastNS)).toFixed(2);

    const saveSurveys = async () => {
        try {
            // Filter out surveys that are already saved (assuming 'id' is assigned by the backend)
            const unsavedSurveyData = surveyData.filter(survey => !survey.isSaved);

            const cleanSurveyData = unsavedSurveyData.map(survey => {
                return {
                    well_id: parseInt(wellId),
                    wellbore_id: parseInt(wellboreId),
                    md_survey: survey.md_survey,
                    incl_survey: survey.incl_survey,
                    azim_survey: survey.azim_survey,
                    tvd_survey: survey.tvd_survey,
                    dls_survey: survey.dls_survey,
                    dl_survey: survey.dl_survey,
                    survey_name: survey.survey_name,
                };
            });

            if (cleanSurveyData.length > 0) {  // Only send if there is new data
                await axios.post(`${baseURL}/input/survey`, JSON.stringify(cleanSurveyData), {
                    withCredentials: true
                });

                // Mark newly saved surveys as "saved"
                const updatedSurveyData = surveyData.map(survey =>
                    unsavedSurveyData.includes(survey)
                        ? { ...survey, isSaved: true }
                        : survey
                );
                setSurveyData(updatedSurveyData);  // Update state with the new status

                setAlertMessage('Surveys saved successfully.');
                setAlertVariant('success');
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds
            } else {
                console.log('No new surveys to save.');
            }
        } catch (error) {
            console.error('Error saving survey data:', error);
            setAlertMessage('Failed to save survey data.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    };

    // Function to handle saving new survey data sections
    const handleSave = () => {
        saveSurveys();
    };

    const calculateDisplacement = (northing, easting, siteNorthing, siteEasting, azimuth) => {
        const targetLocalNorthing = 3.28 * (northing - siteNorthing);
        const targetLocalEasting = 3.28 * (easting - siteEasting);
        const phd = Math.sqrt(targetLocalNorthing ** 2 + targetLocalEasting ** 2);

        // return targetLocalNorthing * Math.cos(toRadians(azimuth)) + targetLocalEasting * Math.sin(toRadians(azimuth));
        return phd;

    };

    const scatterChartData = {
        datasets: [
            {
                label: 'Survey Data',
                data: Array.isArray(surveyData) && surveyData?.map((point) => ({
                    x: point.ew,
                    y: point.ns,
                    surveyName: point.survey_name,
                    incl: point.incl_survey,
                })),
                // Other properties...
                showLine: true, // Connect the scatter dots with a line
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                tension: 0.4, // Add tension to make the line curved (value between 0 and 1)

            },
            {
                label: 'Target Coordinates',
                // data: targetCoordinates.map((target) => ({
                //     x: 3.28*(target.easting - siteEasting),
                //     y: 3.28 * (target.northing - siteNorthing),
                //     targetName: target.targetName, // Store the target name

                // })),
                data: surveyTargets?.map((target) => ({
                    x: 3.28 * (target.easting - siteEasting),
                    y: 3.28 * (target.northing - siteNorthing),
                    targetName: target.target_name, // Store the target name

                })),
                backgroundColor: 'red', // Different color for target points
                showLine: false,
                pointRadius: 6, // Scatter point size
            },
        ],
    };
    const tvdDisplacementChartData = {
        datasets: [
            {
                label: 'Survey Data',
                data: Array.isArray(surveyData) && surveyData?.map((point) => ({
                    x: point.phd, // Displacement
                    y: point.tvd_survey, // TVD
                    surveyName: point.survey_name,
                    incl: point.incl_survey,


                })),
                showLine: true, // Connect the scatter dots with a line
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                tension: 0.4, // Add tension to make the line curved (value between 0 and 1)
                // showLine: showDataLabels, // Show data lines based on toggle

            },
            {
                label: 'Target Coordinates',
                // data: targetCoordinates.map((target) => ({
                //     x: calculateDisplacement(target.northing, target.easting, siteData?.northing, siteData?.easting, endAzimuth),
                //     y: target.tvd,
                //     targetName: target.targetName, // Store the target name

                // })),
                data: surveyTargets.map((target) => ({
                    x: calculateDisplacement(target.northing, target.easting, siteData?.northing, siteData?.easting, endAzimuth),
                    y: target.tvd,
                    targetName: target.target_name, // Store the target name

                })),
                backgroundColor: 'red',
                showLine: false,
                pointRadius: 6,
            },
        ],
    };

    // Chart options with axis labels and legend repositioning
    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: `Vertical Section (ft) Azim: ${vsAzim} deg`, // Template literal for concatenation
                },
                min: xMin,
                max: xMax,
                ticks: {
                    callback: function (value) {
                        return round(value); // Round to 2 decimal places
                    },
                },
                grid: { display: showGridLines },
            },
            y: {
                title: {
                    display: true,
                    text: 'TVD (ft)',
                },
                min: yMin,
                max: yMax,
                ticks: {
                    callback: function (value) {
                        return round(value); // Round to 2 decimal places
                    },
                },
                grid: { display: showGridLines },
                reverse: true,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom', // Move the legend to the bottom of the chart
            },
            datalabels: {
                align: 'right',
                anchor: 'end',
                formatter: (value) =>
                    showDataLabels
                        ? value.targetName
                            ? value.targetName
                            : value.surveyName !== undefined && value.surveyName !== null
                                ? `${value.surveyName} Incl: ${value.incl}` // Proper string concatenation
                                : ''
                        : '', // Display label if showDataLabels is true
                color: 'black',
                font: {
                    weight: 'bold',
                },
            },
            title: {
                display: showTitle,
                text: `${wellboreName} Well Trajectory Vertical Section`, // Template literal for concatenation
            },
        },
    };

    // Additional chart options for the Easting/Northing chart
    const eastingNorthingOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'EW (ft)',
                },
                min: planxMin,
                max: planxMax,
                ticks: {
                    callback: function (value) {
                        return round(value); // Round to 2 decimal places
                    }
                },
                grid: { display: showPlanGridLines },
            },
            y: {
                title: {
                    display: true,
                    text: 'NS (ft)',
                },
                min: planyMin,
                max: planyMax,
                ticks: {
                    callback: function (value) {
                        return round(value); // Round to 2 decimal places
                    }
                },
                grid: { display: showPlanGridLines },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom', // Move the legend to the bottom of the chart
            },
            datalabels: {
                align: 'right',
                anchor: 'end',
                // formatter: (value) => value.show_label ? (value.targetName ? value.targetName : value.sectionName) : '', // Display label if show_label is true
                formatter: (value) => showPlanDataLabels ? (value.targetName ? value.targetName : value.surveyName !== undefined && value.surveyName !== null ? value.surveyName + 'Incl: ' + value.incl : '') : '', // Display label if show_label is true
                color: 'black',
                font: {
                    weight: 'bold',
                },
            },
            title: {
                display: showPlanTitle,
                text: wellboreName + ' Well Trajectory Plan View',
            },
        },
    };

    // Function to remove the last entry from surveyData
    const handleRemoveLast = () => {
        setSurveyData(surveyData.slice(0, -1)); // Remove the last element
    };

    const handleReset = () => {
        // Show the modal when the reset button is clicked
        setShowModal(true);
    };

    const confirmReset = () => {
        // This function runs when the user confirms the reset
        setSurveyData([]); // Clear the survey data
        setShowModal(false); // Close the modal
    };

    const cancelReset = () => {
        // Close the modal without resetting anything
        setShowModal(false);
    };

    const handleCheckboxChange = (e) => {
        setShowLabel(e.target.checked);
    };

    const updateChartScales = () => {
        chartRef.current.options.scales.x.min = xMin;
        chartRef.current.options.scales.x.max = xMax;
        chartRef.current.options.scales.y.min = yMin;
        chartRef.current.options.scales.y.max = yMax;

        chartRef.current.options.scales.x.min = planxMin;
        chartRef.current.options.scales.x.max = planxMax;
        chartRef.current.options.scales.y.min = planyMin;
        chartRef.current.options.scales.y.max = planyMax;

        chartRef.current.options.plugins.title.display = showTitle;
        chartRef.current.options.scales.x.grid.display = showGridLines;
        chartRef.current.options.scales.y.grid.display = showGridLines;

        chartRef.current.update();
    };

    const handleViewClick = () => {
        setShowFullScreenModal(true);
    };

    const handleCloseModal = () => {
        setShowFullScreenModal(false);
    };

    return (
        <div className='mt-5'>
            <h5>Trajectory Designer</h5>

            <div className='border-top border-primary mb-4'>
                {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}

                <div className='mt-4  text-primary'>
                    <h6>Survey Targets</h6>

                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Target Name</th>
                            <th>Northing(m)</th>
                            <th>Easting(m)</th>
                            <th>TVD(ft)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {surveyTargets.map((target) => (
                            <tr key={target.survey_field_id}>
                                <td>{target.target_name}</td>
                                <td>{target.northing}</td>
                                <td>{target.easting}</td>
                                <td>{target.tvd}</td>
                                <td>
                                    <Button variant="outline-primary" size="sm" className="rounded-circle" onClick={() => updateSurveyTarget(target.survey_field_id, target)}>
                                        {/* Update */}
                                        <i className="bi bi-pen"></i>
                                    </Button>
                                    <Button variant="outline-danger" size="sm" className="rounded-circle" onClick={() => deleteSurveyTarget(target.survey_field_id)}>
                                        {/* Delete */}
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div>
                    <span className={surveyTargets.length < 1 ? "text-danger" : ""}>
                        {surveyTargets.length < 1 ? "You have not added any targets." : ""}<br />
                    </span>
                </div>

                <div className='mt-4 text-primary'>
                    <h6>Add Survey Target</h6>
                </div>
                
                <Form onSubmit={(e) => { e.preventDefault(); addSurveyTarget(); }}>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="formTargetName">
                                <Form.Label>Target Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="target_name"
                                    value={newSurveyTarget.target_name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="formNorthing">
                                <Form.Label>Northing(m)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="northing"
                                    value={newSurveyTarget.northing}
                                    onChange={handleInputChange}
                                    step="any"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formEasting">
                                <Form.Label>Easting(m)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="easting"
                                    value={newSurveyTarget.easting}
                                    onChange={handleInputChange}
                                    step="any"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formTVD">
                                <Form.Label>TVD(ft)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="tvd"
                                    value={newSurveyTarget.tvd}
                                    onChange={handleInputChange}
                                    step="any"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Button variant="primary" type="submit">Add Survey Target</Button>
                </Form>
            </div>

            {/* <div className='mb-5 mt-5'>
                <AddTargetForm onAddTarget={handleAddTarget} targetCoordinates={targetCoordinates} setTargetCoordinates={ setTargetCoordinates} />
            </div> */}
            
            <div className='border-top border-primary'>
                <div className='mt-4 text-primary'>
                    <h6>Survey Sections</h6>
                </div>
            </div>

            <TrajectoryForm
                surveyName={surveyName}
                setSurveyName={setSurveyName}
                sectionType={sectionType}
                setSectionType={setSectionType}
                endMD={endMD}
                setEndMD={setEndMD}
                startIncl={startIncl}
                setStartIncl={setStartIncl}
                endIncl={endIncl}
                setEndIncl={setEndIncl}
                startAzimuth={startAzimuth}
                setStartAzimuth={setStartAzimuth}
                endAzimuth={endAzimuth}
                setEndAzimuth={setEndAzimuth}
                handleAddSection={handleAddSection}
                showLabel={showLabel}
                // setShowLabel={setShowLabel}
                handleCheckboxChange={handleCheckboxChange}
                handleRemoveLast={handleRemoveLast}
                handleReset={handleReset}
            />

            {/* Survey data table */}
            <div className='mt-5'>
                <hr />
                {surveyData.length > 0 && (
                    <div className='float-end mb-3'>
                        <Alert show={showAlert} variant={alertVariant}>
                            {alertMessage}
                        </Alert>
                        <Button variant="outline-primary" onClick={handleSave} title='Save to database' aria-label="Save">
                            {/* Save to database */}
                            {/* <i className="bi bi-save"></i> */}
                            Save

                        </Button> {' '}
                        <Button variant="outline-primary" onClick={handleViewClick} title='View Geodetic survey' aria-label="View Geodetic Survey">
                            {/* Geodetic survey */}
                            {/* <i className="bi bi-eye"></i> */}
                            View

                        </Button> {' '}
                        <Button variant="outline-danger" onClick={handleReset} title='Delete the entire survey table' aria-label="Delete Table">
                            {/* Reset */}
                            <i className="bi bi-trash"></i>
                        </Button> {' '}
                        <Button variant='outline-secondary' title='Download' aria-label="Download">
                            {/* Download */}
                            <i className="bi bi-download"></i>

                        </Button>
                        {/* Full screen modal */}
                        <FullScreenModal
                            show={showFullScreenModal}
                            handleClose={handleCloseModal}
                            surveyData={surveyData}
                            siteNorthing={siteNorthing}
                            siteEasting={siteEasting}
                            waterDepth={waterDepth}
                            epsgCode={epsgCode}

                        />
                    </div>
                )}

                <div className='mb-3'>
                <h6>Survey Data</h6>
                    <table className='table table-bordered text-small'>
                    <thead>
                        <tr>
                                <th>MD(ft)</th>
                                <th>Incl(°)</th>
                                <th>Azi Grid(°)</th>
                                <th>TVD(ft)</th>
                                <th>NS(ft)</th>
                                <th>EW(ft)</th>
                                <th>Closure Azi(°)</th>
                            <th>DLS (°/100ft)</th>
                            <th>VS</th>
                        </tr>
                    </thead>
                    <tbody>
                            {Array.isArray(surveyData) && surveyData?.map((survey, index) => (
                            <tr key={index}>
                                <td>{survey.md_survey.toFixed(2)}</td>
                                <td>{survey.incl_survey}</td>
                                <td>{survey.azim_survey}</td>
                                <td>{survey.tvd_survey.toFixed(2)}</td>
                                <td>{survey?.ns.toFixed(2)}</td>
                                <td>{survey?.ew.toFixed(2)}</td>
                                <td>{toDegrees(Math.atan2(survey?.ew, survey?.ns)).toFixed(2)}</td>
                                <td>{survey.dls_survey.toFixed(2)}</td>
                                <td>{survey?.phd.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                {/* Button to remove the last section */}
                {surveyData.length > 0 && (
                    <>
                        
                        {/* Modal */}
                        <Modal show={showModal} onHide={cancelReset}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Reset</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to reset all survey data? This action cannot be undone.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={cancelReset}>Cancel</Button>
                                <Button variant="danger" onClick={confirmReset}>Reset</Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                )}

            </div>

            {/* TVD vs MD Scatter Plot */}
            {/* <div style={{ marginTop: '20px' }}>
                <Scatter
                    data={generateScatterData('phd', 'tvd_survey', 'TVD vs PHD')}
                    options={chartOptions}
                />
            </div> */}

            {/* Northing vs Easting Scatter Plot */}
            {/* <div style={{ marginTop: '20px' }}>
                <Scatter
                    data={generateScatterData('easting', 'northing', 'Easting vs Northing')}
                    options={eastingNorthingOptions}
                />
            </div> */}
            <div className='mt-5'>
                {/* Render Charts */}
                <Row className='mb-4'>
                    <Col md={viewPlanChartControls ? 9 : 12}>
                        <Form.Group controlId='planCheckbox' className='text-primary'>
                            <Form.Check
                                type='checkbox'
                                label='View chart controls'
                                checked={viewPlanChartControls}
                                onChange={handleViewPlanControlsCheckboxChange}

                            />
                        </Form.Group>
                        <Scatter data={scatterChartData} options={eastingNorthingOptions} />

                    </Col>
                    {viewPlanChartControls && (
                        <Col md="3" className='bg-light'>
                            <Form>
                                <Form.Group controlId="xMin">
                                    <Form.Label>X-Axis Min</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={planxMin}
                                        onChange={(e) => setPlanXMin(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="xMax">
                                    <Form.Label>X-Axis Max</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={planxMax}
                                        onChange={(e) => setPlanXMax(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="yMin">
                                    <Form.Label>Y-Axis Min</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={planyMin}
                                        onChange={(e) => setPlanYMin(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="yMax">
                                    <Form.Label>Y-Axis Max</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={planyMax}
                                        onChange={(e) => setPlanYMax(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        label="Show Chart Title"
                                        checked={showPlanTitle}
                                        onChange={(e) => setShowPlanTitle(e.target.checked)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        label="Show Data Labels"
                                        checked={showPlanDataLabels}
                                        onChange={(e) => setShowPlanDataLabels(e.target.checked)}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        label="Show Grid Lines"
                                        checked={showPlanGridLines}
                                        onChange={(e) => setShowPlanGridLines(e.target.checked)}
                                    />
                                </Form.Group>

                                <Button variant="primary" onClick={updateChartScales}>Update Chart</Button>
                            </Form>
                        </Col>
                    )}

                </Row>
                <Row>
                    <Col md={viewVsChartControls ? 9 : 12}>
                        <Form.Group controlId='vsCheckbox' className='text-primary'>
                            <Form.Check
                                type='checkbox'
                                label='View chart controls'
                                checked={viewVsChartControls}
                                onChange={handleViewVsControlsCheckboxChange}

                            />
                        </Form.Group>
                        <Scatter ref={chartRef} data={tvdDisplacementChartData} options={chartOptions} />

                    </Col>
                    {viewVsChartControls && (
                        <Col md="3" className='bg-light'>
                            <Tabs id='vsControls'>
                                <Tab title='Tab 1' eventKey='tab1'>
                                    <Form>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Chart Title"
                                                checked={showTitle}
                                                onChange={(e) => setShowTitle(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Data Labels"
                                                checked={showDataLabels}
                                                onChange={(e) => setShowDataLabels(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Grid Lines"
                                                checked={showGridLines}
                                                onChange={(e) => setShowGridLines(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={updateChartScales}>Update Chart</Button>
                                    </Form>
                                </Tab>
                                <Tab title='Tab 2' eventKey='tab2'>
                                    <Form>
                                        <Form.Group controlId="xMin">
                                            <Form.Label>X-Axis Min</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={xMin}
                                                onChange={(e) => setXMin(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="xMax">
                                            <Form.Label>X-Axis Max</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={xMax}
                                                onChange={(e) => setXMax(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="yMin">
                                            <Form.Label>Y-Axis Min</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={yMin}
                                                onChange={(e) => setYMin(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="yMax">
                                            <Form.Label>Y-Axis Max</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={yMax}
                                                onChange={(e) => setYMax(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Chart Title"
                                                checked={showTitle}
                                                onChange={(e) => setShowTitle(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Data Labels"
                                                checked={showDataLabels}
                                                onChange={(e) => setShowDataLabels(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                label="Show Grid Lines"
                                                checked={showGridLines}
                                                onChange={(e) => setShowGridLines(e.target.checked)}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={updateChartScales}>Update Chart</Button>
                                    </Form>
                                </Tab>
                            </Tabs>
                            
                        </Col>
                    )}
                    
                </Row>
            </div>
        </div>
    );
};

export default TrajectoryDesigner;
