import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spinner } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel"; // Material-UI icon
import IconButton from "@mui/material/IconButton";

const CancelSubscription = ({ userId }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cancelingModules, setCancelingModules] = useState({});
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchSubscriptions = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`${baseURL}/view-active-subscriptions`, {
                    params: { user_id: userId },
                    withCredentials: true,
                });
                const { active_subscriptions, message } = response.data;

                if (active_subscriptions.length === 0) {
                    setError(message);
                }

                setSubscriptions(active_subscriptions);
            } catch (error) {
                console.error("Failed to fetch subscriptions:", error);
                setError(
                    error.response?.data?.error ||
                    "An error occurred while fetching your subscriptions. Please try again later."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptions();
    }, [userId, baseURL]);

    const handleCancel = async (subscriptionId) => {
        if (!window.confirm("Are you sure you want to cancel this module?")) {
            return;
        }

        setCancelingModules((prev) => ({ ...prev, [subscriptionId]: true }));

        try {
            const response = await axios.post(
                `${baseURL}/cancel-subscription`,
                { subscription_id: subscriptionId },
                { withCredentials: true }
            );
            alert(response.data.message || "Subscription cancelled successfully.");
            setSubscriptions((prev) =>
                prev.filter((sub) => sub.subscription_id !== subscriptionId)
            );
        } catch (error) {
            console.error("Failed to cancel module:", error);
            alert(
                error.response?.data?.error ||
                "An error occurred while canceling the module."
            );
        } finally {
            setCancelingModules((prev) => ({ ...prev, [subscriptionId]: false }));
        }
    };

    if (loading) {
        return <Spinner animation="border" role="status" />;
    }

    if (error) {
        return <p className="text-danger">{error}</p>;
    }

    return (
        <div>
            <p>Your Active Subscriptions</p>
            {subscriptions.length === 0 ? (
                <p>You have no active subscriptions.</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Module Name</th>
                            <th>Description</th>
                                <th>Price</th>
                                <th>Expiring</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscriptions.map((sub, index) => (
                            <tr key={sub.module_id}>
                                <td>{index + 1}</td>
                                <td>{sub.module_name}</td>
                                <td>{sub.description}</td>
                                <td>${Number(sub.price).toFixed(2)}</td>
                                <td>{new Date(sub.subscription_start_date).toLocaleDateString()}</td>
                                <td>
                                    <IconButton
                                        onClick={() => handleCancel(sub.subscription_id)}
                                        disabled={cancelingModules[sub.subscription_id]}
                                        color="error"
                                        size="small"
                                    >
                                        {cancelingModules[sub.subscription_id] ? (
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                size="sm"
                                                style={{ margin: "0 auto" }}
                                            />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default CancelSubscription;
