// this component contains the first form for design creation (company, field, etc.)
import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";

import AddCompanyModal from "./addCompanyModal";
import AddBlockModal from "./addBlockModal";
import AddFieldModal from "./addFieldModal";
import AddWellModal from "./addWellModal";
import AddWellboreModal from "./addWellboreModal";
import AddWelldesignCaseModal from "./addWellDesignCaseModal";

import { GlobalStateContext } from "../GlobalStateContext";

function DesignHeaders(props) {
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    const [userIdEgCo] = useState('a27125f157cd447fb23f4d6c99536413');
    const [userId, setUserId] = useState(null);
    const [companyData, setCompanyData] = useState([]);
    const selectedCompany = useState({ company_id: null, company_name: '' });
    const [blocks, setBlocks] = useState([]);
    const [selectedBlock, setSelectedBlock] = useState({ block_id: null, block_name: '' });
    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState({ field_id: null, field_name: '' });
    const [wells, setWells] = useState([]);
    const [selectedWell, setSelectedWell] = useState({ well_id: null, well_name: '' });
    const [wellbores, setWellbores] = useState([]);
    const [wellTypes, setWellTypes] = useState(['Exploration', 'Appraisal', 'Development']);
    const [selectedWellType, setSelectedWellType] = useState({ well_type_id: null, well_type: ''});

    const [selectedWellbore, setSelectedWellbore] = useState({ wellbore_id: null, wellbore_name: '' });

    const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState({ units_id: null, units_type: ''})

    const [wellDesignCases, setWellDesignCases] = useState([]);
    const [showCompanyModal, setShowCompanyModal] = useState(false);

    const [showBlockModal, setShowBlockModal] = useState(false);
    const [showFieldModal, setShowFieldModal] = useState(false);
    const [showWellModal, setShowWellModal] = useState(false);
    const [showWellboreModal, setShowWellboreModal] = useState(false);
    const [showWellDesignModal, setShowWellDesignModal] = useState(false)

    useEffect(() => {
        // Fetch profile data on mount
        const getUserProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/profile`, {
                    withCredentials: true, // Sends cookies with request
                });
                const { user_id } = response.data;

                setUserId(user_id);
                
            } catch (error) {
                console.error("Error fetching user profile:", error.response.data);
            }
        };
        getUserProfile();
    }, [baseURL]);

    // Function to fetch companies from a special user to be shared
    const fetchCompanyData = useCallback(async () => {
        try {
            // Fetch companies for the special user (userIdSpecial)
            const exampleResponse = await axios({
                method: "GET",
                url: `${baseURL}/input/companies/${userIdEgCo}`,
                withCredentials: true
            });

            // Fetch companies for the current user (userId)
            const userResponse = await axios({
                method: "GET",
                url: `${baseURL}/input/companies/${userId}`,
                withCredentials: true
            });

            // Combine the company data from both responses
            const combinedCompanies = [...exampleResponse.data, ...userResponse.data];
            setCompanyData(combinedCompanies); // Update company data state with combined list
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    }, [userId, userIdEgCo, baseURL]);

    const fetchUnitsData = useCallback(() => {
        axios({
            method: "GET",
            url: `${baseURL}/input/units`,
            withCredentials: true
        })
            .then((response) => {
                const res = response.data;
                setUnits(res); // Update units data state with fetched data
            })
            .catch((error) => {
                console.error("Error fetching units:", error);
            });
    }, [baseURL]);


    const fetchWellTypeData = useCallback(() => {
        axios({
            method: "GET",
            url: `${baseURL}/input/well_types`,
            withCredentials: true
        })
            .then((response) => {
                const res = response.data;
                setWellTypes(res); // Update well type data state with fetched data
            })
            .catch((error) => {
                console.error("Error fetching units:", error);
            });
    }, [baseURL]);

    useEffect(() => {
        if (userId) {
            fetchCompanyData();
            fetchUnitsData();
            fetchWellTypeData();
        }
    }, [userId, fetchCompanyData, fetchUnitsData, fetchWellTypeData]); // Update useEffect dependencies

    // Function to fetch blocks by company_id
    const fetchBlocksByCompanyId = useCallback((companyId) => {
        axios({
            method: "GET",
            url: `${baseURL}/input/blocks/${companyId}`,
            withCredentials: true
        })
            .then((response) => {
                const blocksData = response.data;
                setBlocks(blocksData); // Update blocks state with fetched data

            })
            .catch((error) => {
                console.error("Error fetching blocks:", error);
            });
    }, [baseURL]);

    // Function to fetch fields by block_id
    const fetchFieldsByBlockId = useCallback((blockId) => {
        axios({
            method: "GET",
            url: `${baseURL}/input/fields/${blockId}`,
            withCredentials: true
        })
            .then((response) => {
                const fieldsData = response.data;
                setFields(fieldsData); // Update fields state with fetched data
            })
            .catch((error) => {
                console.error("Error fetching fields:", error);
            });
    }, [baseURL]);

    // Function to fetch wells by field_id
    const fetchWellsByFieldId = useCallback((fieldId) => {
        axios({
            method: "GET",
            url: `${baseURL}/input/wells/${fieldId}`,
            withCredentials: true
        })
            .then((response) => {
                const wellsData = response.data;
                setWells(wellsData); // Update wells state with fetched data
            })
            .catch((error) => {
                console.error("Error fetching wells:", error);
            });
    }, [baseURL]);

    // Function to fetch wellbores by well_id
    const fetchWellboresByWellId = useCallback((wellId) => {
        axios({
            method: "GET",
            url: `${baseURL}/input/wellbores/${wellId}`,
            withCredentials: true
        })
            .then((response) => {
                const wellboresData = response.data;
                setWellbores(wellboresData); // Update wellbores state with fetched data
            })
            .catch((error) => {
                console.error("Error fetching wellbores:", error);
            });
    }, [baseURL]);

    // Function to fetch well design cases by well_id and wellbore_id
    const fetchWellDesignCasesByUnitIdWellIdWellboreId = useCallback((unitId, wellId, wellboreId) => {
        axios({
            method: "GET",
            url: `${baseURL}/input/well_design_cases/${unitId}/${wellId}/${wellboreId}`,
            withCredentials: true
        })
            .then((response) => {
                const res = response.data
                setWellDesignCases(res); // Update well design cases state with fetched data

            })
            .catch((error) => {
                console.error("Error fetching well design cases:", error);
            });
    }, [baseURL]);

    useEffect(() => {
        // Fetch necessary data based on selected items if they exist
        if (selectedItems.company_id) {
            fetchBlocksByCompanyId(selectedItems.company_id);
        }
        if (selectedItems.block_id) {
            fetchFieldsByBlockId(selectedItems.block_id);
        }
        if (selectedItems.field_id) {
            fetchWellsByFieldId(selectedItems.field_id);
        }
        if (selectedItems.well_id) {
            fetchWellboresByWellId(selectedItems.well_id);
        }
        if (selectedItems.wellbore_id) {
            fetchUnitsData();
        }
        if (selectedItems.units_id) {
            fetchWellDesignCasesByUnitIdWellIdWellboreId(selectedItems.units_id, selectedItems.well_id, selectedItems.wellbore_id,)
        }
    }, [selectedItems, fetchBlocksByCompanyId, fetchFieldsByBlockId, fetchUnitsData, fetchWellDesignCasesByUnitIdWellIdWellboreId, fetchWellboresByWellId, fetchWellsByFieldId]);

    // Function to handle company selection
    function handleCompanySelection(companyId, companyName) {
        fetchUnitsData()

        if (companyId === "new") { 
            setShowCompanyModal(true)

        } else {
            console.log(companyId, companyId);
            setSelectedItems(prevState => ({
                ...prevState,
                company_id: companyId,
                company_name: companyName,
            }));

            fetchBlocksByCompanyId(companyId);

        }

    }

    const handleBlockSelection = (blockId, blockName) => {
        if (blockId === "new") { 
            setShowBlockModal(true)

        } else {

            setSelectedItems(prevState => ({
                ...prevState,
                block_id: blockId,
                block_name: blockName
            }));

            setSelectedBlock({ block_id: blockId, block_name: blockName }); // Update selected block
            fetchFieldsByBlockId(blockId); // Fetch fields by block id
        }
        
    }


    function handleFieldSelection(fielId, fieldName) {
        if (fielId === "new") {
            // Prompt the user to provide details for the new field
            // Implement logic to create a new field in the database

            // Prompt the user to provide details for the new field
            setShowFieldModal(true); // Show the modal for adding a new field

        } else {

            setSelectedItems(prevState => ({
                ...prevState,
                field_id: fielId,
                field_name: fieldName
            }));
            setSelectedField({ field_id: fielId, field_name: fieldName }); // Update selected block

            fetchWellsByFieldId(fielId); // Fetch wells by field id

        }
    }

    function handleWellSelection(wellId, wellName) {

        if (wellId === "new") {
            // Prompt the user to provide details for the new field
            // Implement logic to create a new field in the database

            // Prompt the user to provide details for the new well
            setShowWellModal(true); // Show the modal for adding a new well

        } else {
            // Handle the selection of an existing block

            setSelectedWell({ well_id: wellId, well_name: wellName }); // Update selected block
            fetchWellboresByWellId(wellId); // Fetch well by field id

            setSelectedItems(prevState => ({
                ...prevState,
                well_id: wellId,
                well_name: wellName
            }));
        }
    }

    function handleWellboreSelection(wellboreId, wellboreName) {
        
        if (wellboreId === "new") {
            // Prompt the user to provide details for the new wellbore
            // Implement logic to create a new wellbore in the database

            setShowWellboreModal(true); // Show the modal for adding a new wellbore
            fetchWellboresByWellId(selectedItems.well_id); // Refresh wellbores after adding a new one

            // Optionally, you can reset the selected wellbore to null or an initial state
            // setSelectedWellbore(null); // Reset selected wellbore
        } else {
            // Handle the selection of an existing wellbore
            setSelectedWellbore({ wellbore_id: wellboreId, wellbore_name: wellboreName }); // Update selected block
            // sendWellboreDataToParent({ company_name: selectedCompany.company_name, block_name: selectedBlock.block_name, field_name: selectedField.field_name, wellbore_id: wellboreId, wellbore_name: wellboreName, well_id: selectedWell.well_id, well_name:selectedWell.well_name}); // Sending both wellboreId and wellboreName to the parent
            // fetchWellDesignCasesByUnitIdWellIdWellboreId(selectedUnits.units_id, selectedWell.well_id, wellboreId); // Fetch well design case

            setSelectedItems(prevState => ({
                ...prevState,
                wellbore_id: wellboreId,
                wellbore_name: wellboreName
            }));
        }
    }

    function handleWellTypeSelection(wellTypeId, wellType) {
        setSelectedItems(prevState => ({
            ...prevState,
            well_type_id: wellTypeId,
            well_type: wellType
        }));

        // setSelectedWellType(wellType);
        setSelectedWellType({ well_type_id: wellTypeId, well_type: wellType });


    }

    function handleUnitsSelection(unitsId, unitsType) {
        
        setSelectedItems(prevState => ({
            ...prevState,
            units_id: unitsId,
            units_type: unitsType
        }));

        setSelectedUnits({ units_id: unitsId, units_type: unitsType });

        // fetchWellDesignCasesByUnitIdWellIdWellboreId(unitsId, selectedWell.well_id, selectedWellbore.wellbore_id)


    }

    function handleWellDesignCaseSelection(well_design_caseId, well_design_caseName) {

        if (well_design_caseId === "new") {

            // Prompt the user to provide details for the new design case
            setShowWellDesignModal(true); // Show the modal for adding a new design case

            // Optionally, you can reset the selected wellbore to null or an initial state
            // setSelectedWellDesignCase(null); // Reset selected design case
        } else {
            // Handle the selection of an existing wellbore
            
            setSelectedItems(prevState => ({
                ...prevState,
                well_design_case_id: well_design_caseId,
                well_design_case_name: well_design_caseName
            }));
        }
    }

    // Function to handle adding a new block
    const handleAddCompany = (companyName) => {
        // Send a request to create a new company using the provided name

        // You can make an API call here to create a new block
        // After successfully creating the block, you may want to update the UI accordingly
        setShowCompanyModal(false); // Close the modal after adding the block

        // refresh the dropdown options
        fetchCompanyData()
    };
    
    // Function to handle adding a new block
    const handleAddBlock = (companyId, blockName) => {
        // Send a request to create a new block using the provided name

        // You can make an API call here to create a new block
        // After successfully creating the block, you may want to update the UI accordingly
        setShowBlockModal(false); // Close the modal after adding the block
        // refresh the dropdown options
        companyId = selectedItems.company_id;
        fetchBlocksByCompanyId(companyId);
    };

    const handleAddField = (blockId, fieldName) => {
        
        setShowFieldModal(false);
        blockId = selectedItems.block_id;
        fetchFieldsByBlockId(blockId);
    };

    const handleAddWell = (fieldId, wellName) => {
        
        setShowWellModal(false);
        fieldId = selectedItems.field_id;
        fetchWellsByFieldId(fieldId);
    };

    const handleAddWellbore = (wellId, wellboreName) => {
        setShowWellboreModal(false);
        wellId = selectedItems.well_id;
        fetchWellboresByWellId(wellId); // Refresh wellbores after adding a new one
    };

    const handleAddWellDesign = (wellDesignCaseName) => {
        setShowWellDesignModal(false);
        fetchWellDesignCasesByUnitIdWellIdWellboreId(selectedItems.units_id, selectedItems.well_id, selectedItems.wellbore_id); // Fetch well design case

    };

    return (
        <div className="container mt-2">
            
            {showCompanyModal && <AddCompanyModal userId={userId} onSubmit={handleAddCompany} onClose={() => setShowCompanyModal(false)} />}

            {showBlockModal && <AddBlockModal companyId={parseInt(selectedItems.company_id)} companyName={(selectedCompany.company_name)} onSubmit={handleAddBlock} onClose={() => setShowBlockModal(false)} />}
            {showFieldModal && <AddFieldModal blockId={parseInt(selectedItems.block_id)} blockName={(selectedBlock.block_name)} onSubmit={handleAddField} onClose={() => setShowFieldModal(false)} />}
            {showWellModal && <AddWellModal fieldId={parseInt(selectedItems.field_id)} fieldName={(selectedField.field_name)} onSubmit={handleAddWell} onClose={() => setShowWellModal(false)} />}
            {showWellboreModal && <AddWellboreModal wellId={parseInt(selectedWell.well_id)} wellName={(selectedWell.well_name)} onSubmit={handleAddWellbore} onClose={() => setShowWellboreModal(false)} />}
            {showWellDesignModal && <AddWelldesignCaseModal unitsId={parseInt(selectedItems.units_id)} unitName={(selectedUnits.unit_name)} wellTypeId={parseInt(selectedWellType.well_type_id)} wellType={selectedItems.well_type} wellId={parseInt(selectedItems.well_id)} wellName={(selectedWell.well_name)} wellboreId={parseInt(selectedItems.wellbore_id)} wellboreName={(selectedItems.wellbore_name)} onSubmit={handleAddWellDesign} onClose={() => setShowWellDesignModal(false)} />}

            <div className="row">

                <div className="col">
                    <form>
                        <div>
                            {/* Select Company */}
                            <div className="form-group row">
                                <label htmlFor="company" className="col-sm-2 col-form-label">Company</label>
                                <div className="col-sm-10">
    
                                    <select
                                        className="form-select"
                                        id="company"
                                        value={selectedItems.company_id}
                                        onChange={(e) => handleCompanySelection(e.target.value, e.target.options[e.target.selectedIndex].text)}
                                    >
                                        <option value="">Choose...</option>
                                        <option value="new">Add a new company</option>
                                        {companyData.map((company, index) => (
                                            <option key={index} value={company.company_id}>
                                                {company.company_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Select Block */}
                            {selectedCompany && (
                                <div className="form-group row">
                                    <label htmlFor="block" className="col-sm-2 col-form-label">Block</label>
                                    <div className="col-sm-10">
                                        <select
                                            className="form-select"
                                            id="block" 
                                            
                                            value={selectedItems.block_id || ""}
                                            onChange={(e) => handleBlockSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}
                                        >
                                            <option value="">Choose...</option>
                                            <option value="new">Add a new block</option> {/* Add a new block option */}
                                            {blocks.map(block => (
                                                <option key={block.block_id} value={block.block_id}>
                                                    {block.block_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            
                            {/* Select Field */}
                            {selectedBlock && (
                                <div className="form-group row">
                                    <label htmlFor="field" className="col-sm-2 col-form-label">Field</label>
                                    <div className="col-sm-10">
                                        <select className="form-select"
                                            id="field"
                                            value={selectedItems.field_id || ""}
                                            onChange={(e) => handleFieldSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                            <option value="">Choose...</option>
                                            <option value="new" >Add a new field</option>

                                            {fields.map(field => (
                                                <option key={field.field_id} value={field.field_id}>
                                                    {field.field_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* Select Well */}
                            {selectedField && (
                                <div className="form-group row">
                                    <label htmlFor="well" className="col-sm-2 col-form-label">Well</label>
                                    <div className="col-sm-10">
                                        <select className="form-select"
                                            id="well"
                                            value={selectedItems.well_id || ""}
                                            onChange={(e) => handleWellSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                            <option value="">Choose...</option>
                                            <option value="new" >Add a new well</option>

                                            {wells.map(well => (
                                                <option key={well.well_id} value={well.well_id}>
                                                    {well.well_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* Select Wellbore */}
                            {selectedWell && (
                                <div className="form-group row">
                                    <label htmlFor="wellbore" className="col-sm-2 col-form-label">Wellbores</label>
                                    <div className="col-sm-10">
                                        {/* <select className="form-select" id="wellbore" defaultValue="" value={newWellboreId || ''} onChange={(e) => handleWellboreSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}> */}
                                        <select className="form-select"
                                            id="wellbore"
                                            value={selectedItems.wellbore_id || ""}
                                            onChange={(e) => handleWellboreSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}>

                                            <option value="">Choose...</option>
                                            <option value="new" >Add a new wellbore</option>
                                            
                                            {wellbores.map(wellbore => (
                                                <option key={wellbore.wellbore_id} value={wellbore.wellbore_id}>
                                                    {wellbore.wellbore_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            <div className="form-group row">
                                <label htmlFor="welltype" className="col-sm-2 col-form-label">Well Type</label>
                                <div className="col-sm-10">
                                    <select
                                        className="form-select"
                                        id="wellType"
                                        // value={selectedWellType || ""}
                                        value={selectedItems.well_type_id || ""}
                                        onChange={(e) => handleWellTypeSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}
                                    >
                                        <option value="">Choose...</option>
                                        {wellTypes.map((well_type) => (
                                            <option key={well_type.well_type_id} value={well_type.well_type_id}>
                                                {well_type.well_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Select Units */}
                            
                                <div className="form-group row">
                                    <label htmlFor="units" className="col-sm-2 col-form-label">Units</label>
                                    <div className="col-sm-10">
                                    <select className="form-select"
                                        id="units"
                                        value={selectedItems.units_id || ""}
                                        onChange={(e) => handleUnitsSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                            <option value="">Choose...</option>
                                            {units.map(unit => (
                                                <option key={unit.units_id} value={unit.units_id}>
                                                    {unit.units_type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            

                            {/* Display Well Design Cases */}
                            {selectedUnits && selectedWell && selectedWellbore && (
                                <div className="form-group row">
                                    <label htmlFor="well_design_case" className="col-sm-2 col-form-label">Name of Well Design Case</label>
                                    <div className="col-sm-10">
                                        <select className="form-select"
                                            id="well_design_case"
                                            value={selectedItems.well_design_case_id || ""}
                                            onChange={(e) => handleWellDesignCaseSelection(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                            <option value="">Choose...</option>
                                            <option value="new" >Add a new design case</option>
                                            {wellDesignCases.map(well_design_case => (
                                                <option key={well_design_case.well_design_case_id} value={well_design_case.well_design_case_id}>
                                                    {well_design_case.well_design_case_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        {/* Selected Company: {selectedCompany && selectedCompany.company_name} */}
                                    </div>
                                </div>
                            )}
                            
                        </div>

                        {/* <div className="form-group row">
                            <div className="col-sm-10">
                                <button type="submit" className="btn btn-primary mr-2">Save</button>
                                <button type="submit" className="btn btn-danger">Delete</button>
                            </div>
                        </div> */}
                       
                    </form>
                </div>

            </div>
        </div>
        
        
    );
}

export default DesignHeaders;
