import React, { useState } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";

const GasMigrationRateCalculator = () => {
    const [mudWeight, setMudWeight] = useState(10); // Mud weight in ppg
    const [tvd, setTvd] = useState(5000); // True vertical depth in ft
    const [surfaceTemp, setSurfaceTemp] = useState(70); // Surface temperature in °F
    const [tempGradient, setTempGradient] = useState(1.5); // Geothermal gradient in °F/100ft
    const [bubbleDiameter, setBubbleDiameter] = useState(0.1); // Bubble diameter in ft
    const [mudViscosity, setMudViscosity] = useState(10); // Mud viscosity in cP
    const [gasPressure, setGasPressure] = useState(1000); // Initial gas pressure in psi
    const [molecularWeight, setMolecularWeight] = useState(16); // Molecular weight of gas (methane = 16)
    const [migrationRate, setMigrationRate] = useState(null);

    const [kickVolume, setKickVolume] = useState(5); // Kick volume in barrels
    const [entryTime, setEntryTime] = useState(60); // Kick entry time in seconds
    const [gasFlowRate, setGasFlowRate] = useState(0); // Gas flow rate in ft³/s
    // const [slipVelocity, setSlipVelocity] = useState(1); // Slip velocity in ft/s
    // let bubbleDiameter = 0.2; // Default bubble diameter
    // const [migrationRate, setMigrationRate] = useState(null);

    // Gas Flow Rate Estimation Formula
    const estimateGasFlowRate = (V_k, t) => {
        if (t === 0) return 0; // Prevent divide by zero
        return (V_k * 5.615) / t; // Convert barrels to ft³ and divide by time
    };

    // Bubble Size Estimation Formula
    // const calculateBubbleSize = (Q, v_s) => {
    //     return Math.sqrt((6 * Q) / (Math.PI * v_s));
    // };

    // Bubble Size Estimation Formula
    const calculateBubbleSize = (Q) => {
        if (Q === 0) return 0; // Prevent invalid values
        return Math.sqrt((6 * Q) / (Math.PI * 1)); // Using default slip velocity for estimation
    };

    // Migration Rate Formula
    // const calculateMigrationRate = (d_b) => {
    //     const slipVelocity = 1; // Assuming a constant slip velocity
    //     return slipVelocity * d_b; // Simple example; adjust as needed
    // };

    const handleEstimateFlowRate = () => {
        const estimatedRate = estimateGasFlowRate(kickVolume, entryTime);
        setGasFlowRate(estimatedRate);

        const estimatedBubbleSize = calculateBubbleSize(estimatedRate);
        setBubbleDiameter(estimatedBubbleSize);
    };

    // const handleCalculate = () => {
    //     const estimatedBubbleSize = calculateBubbleSize(gasFlowRate, slipVelocity);
    //     setBubbleDiameter(estimatedBubbleSize);

    //     const rate = calculateMigrationRate(estimatedBubbleSize);
    //     setMigrationRate(rate);
    // };

    const gasConstant = 10.73; // R in psi·ft³/(lb-mol·°R)

    // Methane critical properties
    const criticalPressure = 667; // psi
    const criticalTemperature = 343; // °R (Rankine)

    const calculateZFactor = (pressure, temperature) => {
        const pr = pressure / criticalPressure; // Reduced pressure
        const tr = (temperature + 460) / criticalTemperature; // Reduced temperature

        // Coefficients for DAK equation
        const A = 0.3265,
            B = -1.07,
            C = -0.5339,
            D = 0.01569,
            E = -0.05165,
            F = 0.5475,
            G = -0.7361,
            H = 0.1844,
            I = 0.1056,
            J = 0.6134;

        let z = 1.0;
        for (let i = 0; i < 10; i++) {
            const rho_r = 0.27 * (pr / (z * tr));
            z =
                1 +
                A +
                B / tr +
                C / (tr ** 3) +
                D / (tr ** 4) +
                E / (tr ** 5) +
                F * rho_r +
                G * rho_r ** 2 -
                H * rho_r ** 5 +
                (I * (1 + J * rho_r ** 2) * rho_r ** 2) / (tr ** 3);
        }
        return z;
    };

    const calculateMigrationRate = () => {
        // Calculate temperature at depth
        const depthTemp = surfaceTemp + (tempGradient * tvd) / 100;

        // Calculate Z-factor
        const zFactor = calculateZFactor(gasPressure, depthTemp);

        // Calculate gas density
        const gasDensity = (gasPressure * molecularWeight) / (zFactor * gasConstant * (depthTemp + 460));

        // Convert mud weight to density (ppg to lb/ft³)
        const mudDensity = mudWeight * 0.052 * 144;

        // Slip velocity equation
        const g = 32.2; // ft/s² (acceleration due to gravity)
        const deltaDensity = mudDensity - gasDensity;
        const slipVelocity = (deltaDensity * g * bubbleDiameter ** 2) / (18 * (mudViscosity / 1000)); // ft/s

        setMigrationRate(slipVelocity > 0 ? slipVelocity : 0); // No negative migration rate
    };

    return (
        <div className="container mt-3 min-vh-100">
            <h3>Gas Migration Rate Calculator (Shut-In Well)</h3>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="mudWeight">
                            <Form.Label>Mud Weight (ppg)</Form.Label>
                            <Form.Control
                                type="number"
                                value={mudWeight}
                                onChange={(e) => setMudWeight(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="tvd">
                            <Form.Label>True Vertical Depth (ft)</Form.Label>
                            <Form.Control
                                type="number"
                                value={tvd}
                                onChange={(e) => setTvd(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="surfaceTemp">
                            <Form.Label>Surface Temperature (°F)</Form.Label>
                            <Form.Control
                                type="number"
                                value={surfaceTemp}
                                onChange={(e) => setSurfaceTemp(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="tempGradient">
                            <Form.Label>Geothermal Gradient (°F/100ft)</Form.Label>
                            <Form.Control
                                type="number"
                                value={tempGradient}
                                onChange={(e) => setTempGradient(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {/* <Col md={6}>
                        <Form.Group controlId="bubbleDiameter">
                            <Form.Label>Gas Bubble Diameter (ft)</Form.Label>
                            <Form.Control
                                type="number"
                                value={bubbleDiameter}
                                onChange={(e) => setBubbleDiameter(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col> */}
                    <Col md={6}>
                        <Form.Group controlId="mudViscosity">
                            <Form.Label>Mud Viscosity (cP)</Form.Label>
                            <Form.Control
                                type="number"
                                value={mudViscosity}
                                onChange={(e) => setMudViscosity(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="gasPressure">
                            <Form.Label>Initial Gas Pressure (psi)</Form.Label>
                            <Form.Control
                                type="number"
                                value={gasPressure}
                                onChange={(e) => setGasPressure(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="molecularWeight">
                            <Form.Label>Molecular Weight of Gas</Form.Label>
                            <Form.Control
                                type="number"
                                value={molecularWeight}
                                onChange={(e) => setMolecularWeight(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="kickVolume">
                                <Form.Label>Kick Volume (barrels)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter kick volume"
                                    value={kickVolume}
                                    onChange={(e) => setKickVolume(parseFloat(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="entryTime">
                                <Form.Label>Kick Entry Time (seconds)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter entry time"
                                    value={entryTime}
                                    onChange={(e) => setEntryTime(parseFloat(e.target.value))}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="gasFlowRate">
                                <Form.Label>Estimated Gas Flow Rate (ft³/s)</Form.Label>
                                <Row>
                                    <Col md={8}>
                                        <Form.Control
                                            type="text"
                                            value={gasFlowRate.toFixed(2)}
                                            readOnly
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Button variant="info" onClick={handleEstimateFlowRate}>
                                            Estimate
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        {/* <Col md={6}>
                            <Form.Group controlId="slipVelocity">
                                <Form.Label>Slip Velocity (ft/s)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter slip velocity"
                                    value={slipVelocity}
                                    onChange={(e) => setSlipVelocity(parseFloat(e.target.value))}
                                />
                            </Form.Group>
                        </Col> */}
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="bubbleDiameter">
                                <Form.Label>Estimated Bubble Diameter (ft)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={bubbleDiameter.toFixed(2)}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="migrationRate">
                                <Form.Label>Calculated Migration Rate (ft/s)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={migrationRate ? migrationRate.toFixed(2) : ""}
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Row>
                <Button variant="primary" onClick={calculateMigrationRate}>
                    Calculate Migration Rate
                </Button>
            </Form>

            {migrationRate !== null && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gas Migration Rate (ft/s)</td>
                            <td>{migrationRate.toFixed(4)}</td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default GasMigrationRateCalculator;
