import React, { useState, useContext } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { GlobalStateContext } from '../GlobalStateContext';

function AddWellboreModal({ wellName, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [wellboreName, setWellboreName] = useState("");
    const [saving, setSaving] = useState(false); // State to track saving process
    const [error, setError] = useState(null); // Track error messages
    const { selectedItems } = useContext(GlobalStateContext);

    const wellId = selectedItems?.well_id;

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setSaving(true); // Set saving state to true while request is in progress
        setError(null);  // Reset any previous errors
        try {
            // const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage

            // Make HTTP POST request to save the new wellbore name with authorization headers
            await axios.post(
                `${baseURL}/input/wellbores`,
                {
                    well_id: parseInt(wellId), // Use the wellId prop passed from parent component
                    wellbore_name: wellboreName
                },
                {
                    withCredentials: true
                }
            );

            // Call onSubmit prop with the saved wellbore name
            onSubmit(wellboreName);

            setWellboreName(""); // Reset the wellbore name after submission

            alert(`${wellboreName} successfully added to ${wellName}`);
            onClose(); // Close the modal after successful submission

        } catch (error) {
            console.error('Error saving wellbore name: ', error);

            // Handle different types of errors
            if (error.response) {
                setError(error.response.data.message || 'Failed to save wellbore name!');
            } else {
                setError('An error occurred. Please try again.');
            }

            alert(`${wellboreName} was not added!`);
        } finally {
            setSaving(false); // Reset saving state after request is completed
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new wellbore for {wellName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <div className="alert alert-danger">{error}</div>} {/* Display error */}
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Wellbore Name</Form.Label>
                    <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter the name of the new wellbore"
                        value={wellboreName}
                        onChange={(e) => setWellboreName(e.target.value)}
                    />
                    {/* Ensuring the submit button is inside the form */}
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onClose} disabled={saving}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={saving}>
                            {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddWellboreModal;
