import React, { useState, useContext } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { GlobalStateContext } from '../GlobalStateContext';

function AddWelldesignCaseModal({ unitsId, wellId, wellboreId, wellTypeId, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [wellDesignName, setWellDesignName] = useState("");
    const [saving, setSaving] = useState(false); // State to track saving process
    const selectedItems = useContext(GlobalStateContext);
    const wellboreName = selectedItems?.wellbore_name;


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true); // Set saving state to true while request is in progress
        try {
            // Make HTTP POST request to save the new well name with authorization headers
            await axios.post(
                `${baseURL}/input/well_design_cases`,
                {
                    units_id: unitsId,
                    well_id: wellId, // Use the wellId prop passed from parent component
                    wellbore_id: wellboreId, // Use the wellId prop passed from parent component
                    well_type_id: wellTypeId,
                    well_design_case_name: wellDesignName
                },
                {
                    withCredentials: true
                }
            )

            // Call onSubmit prop with the saved well name

            onSubmit(wellDesignName);
            setWellDesignName(""); // Reset the well name after submission

            alert(`${wellDesignName} successfully added to ${wellboreName}`)
        } catch (error) {
            console.error('Error saving well design name: ', error);

            alert(`${wellDesignName} was not added!`)
        } finally {
            setSaving(false); // Reset saving state after request is completed
            onClose(); // Close the modal
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new design case for {wellboreName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Well Design Case Name</Form.Label>
                    <Form.Control size="lg"
                        type="text"
                        placeholder="Enter the name of the new well design case"
                        value={wellDesignName}
                        onChange={(e) => setWellDesignName(e.target.value)}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddWelldesignCaseModal;
