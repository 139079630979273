import React, { useState } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

// Helper function to round numbers
const round = (value, precision) => {
    return Number(value).toFixed(precision);
};

const DrillBitPressureLossCalculator = () => {
    const [cumRate, setCumRate] = useState('');
    const [mw, setMw] = useState('');
    const [nSizes1, setNSizes1] = useState('');
    const [numberNozz1, setNumberNozz1] = useState('');
    const [nSizes2, setNSizes2] = useState('');
    const [numberNozz2, setNumberNozz2] = useState('');
    const [bitPressLoss, setBitPressLoss] = useState(null);
    const [totalTfa, setTotalTfa] = useState(0);

    const calculateBitPressLoss = () => {
        // Parse the input values as numbers
        const rate = parseFloat(cumRate);
        console.log('rate', rate);
        const weight = parseFloat(mw);
        const size1 = parseFloat(nSizes1);
        const nozz1 = parseFloat(numberNozz1);
        const size2 = parseFloat(nSizes2);
        const nozz2 = parseFloat(numberNozz2);

        // Check for invalid inputs
        if (isNaN(rate) || isNaN(weight) || isNaN(size1) || isNaN(nozz1) || isNaN(size2) || isNaN(nozz2)) {
            alert("Please enter valid numeric values.");
            return;
        }

        // Calculate the Total Flow Area (TFA) for both nozzles
        const tfa1 = round(((size1 ** 2 / 1303.8) * nozz1), 3);
        const tfa2 = round(((size2 ** 2 / 1303.8) * nozz2), 3);
        const cumTfa = round((tfa1 + tfa2), 3);

        // Calculate the Bit Pressure Loss
        const result = round(((rate ** 2) * weight) / (10858 * (cumTfa ** 2)), 3);

        setTotalTfa(cumTfa);
        setBitPressLoss(result);
    };

    return (
        <Container>
            <h3>Drill Bit Pressure Loss Calculator</h3>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="cumRate">
                            <Form.Label>Cumulative Rate (cum_rate)</Form.Label>
                            <Form.Control
                                type="number"
                                value={cumRate}
                                onChange={(e) => setCumRate(e.target.value)}
                                placeholder="Enter Cumulative Rate"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="mw">
                            <Form.Label>Mud Weight (MW)</Form.Label>
                            <Form.Control
                                type="number"
                                value={mw}
                                onChange={(e) => setMw(e.target.value)}
                                placeholder="Enter Mud Weight"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="nSizes1">
                            <Form.Label>Bit Nozzle Size 1 (n_sizes1)</Form.Label>
                            <Form.Control
                                type="number"
                                value={nSizes1}
                                onChange={(e) => setNSizes1(e.target.value)}
                                placeholder="Enter Nozzle Size 1"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="numberNozz1">
                            <Form.Label>Number of Nozzles 1 (number_nozz1)</Form.Label>
                            <Form.Control
                                type="number"
                                value={numberNozz1}
                                onChange={(e) => setNumberNozz1(e.target.value)}
                                placeholder="Enter Number of Nozzles 1"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="nSizes2">
                            <Form.Label>Bit Nozzle Size 2 (n_sizes2)</Form.Label>
                            <Form.Control
                                type="number"
                                value={nSizes2}
                                onChange={(e) => setNSizes2(e.target.value)}
                                placeholder="Enter Nozzle Size 2"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="numberNozz2">
                            <Form.Label>Number of Nozzles 2 (number_nozz2)</Form.Label>
                            <Form.Control
                                type="number"
                                value={numberNozz2}
                                onChange={(e) => setNumberNozz2(e.target.value)}
                                placeholder="Enter Number of Nozzles 2"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={calculateBitPressLoss}>
                    Calculate Bit Pressure Loss
                </Button>
            </Form>

            {bitPressLoss !== null && (
                <div className="mt-3">
                    <h4>Total TFA: {totalTfa} sq in</h4>
                    <h4>Bit Pressure Loss: {bitPressLoss} psi</h4>
                </div>
            )}
        </Container>
    );
};

export default DrillBitPressureLossCalculator;
