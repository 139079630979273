import React, { useContext } from 'react';

import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useHoleSection } from '../../components/HoleSectionProvider';
import { GlobalStateContext } from '../../components/GlobalStateContext';

function SidebarInputs() {
    const { holeSection } = useHoleSection();
    const { selectedItems } = useContext(GlobalStateContext);
    const activeModules = selectedItems?.active_modules;

    return (

        <Nav defaultActiveKey="/design_case" className="flex-column ms-3">
            <Nav.Link as={Link} to="/welcome">Dashboard</Nav.Link>
            <Nav.Link as={Link} to={activeModules.length > 0 ? "/design_case" : "#"}
                disabled={!activeModules.length > 0}
            >Design case</Nav.Link>
            <Nav.Link
                as={Link}
                to={holeSection.length > 0 ? "/well_information" : "#"}
                disabled={!holeSection.length > 0 || !activeModules.length > 0}
            >
                Well information
            </Nav.Link>

            {/* <Nav.Link eventKey="/design_case">Design case</Nav.Link>
            <Nav.Link eventKey="/well_information">Well information</Nav.Link> */}

        </Nav>
    );
}

export default SidebarInputs;