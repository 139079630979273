import { Link } from "react-router-dom";
import SubscriptionPage from "./SubscriptionPage";
import { Container, Row, Col } from "react-bootstrap";

const ActiveModules = ({ activeModules, userId }) => {
    return (
        <Container>
            <Row>
                {/* Left Column */}
                <Col xs={12} sm={12} md={6} className="mb-4">
                    {activeModules.length > 0 ? (
                        <>
                            <p>You have the following active subscriptions:</p>
                            <ul>
                                {activeModules.map((module, index) => (
                                    <li key={index}>{module}</li>
                                ))}
                            </ul>
                            <Link to="/design_case">
                                Get started: click here to set up a design case.
                            </Link>
                        </>
                    ) : (
                            <div className="bg-light">
                                <p>You don't have any active modules. Please subscribe to at least one module to get started.</p>
                            </div>
                    )}
                </Col>

                {/* Right Column */}
                <Col xs={12} sm={12} md={6}>
                    <SubscriptionPage userId={userId} />
                </Col>
            </Row>
        </Container>
    );
};

export default ActiveModules;
