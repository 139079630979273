import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { GlobalStateContext } from "../GlobalStateContext";
import LeftSidebar from "../../pages/tabs_components/LeftSidebar";
import Breadcrumbs from "../../pages/tabs_components/Breadcrumb";
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

const WelcomeRegisteredUser = () => {
    const [activeModules, setActiveModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);
    const userId = selectedItems?.userId;
    const fullName = selectedItems?.fullName;

    useEffect(() => {
        const fetchActiveModules = async () => {
            console.log('fetching modules... with user ID: ', userId);

            try {
                const response = await axios.get(`${baseURL}/user/active-modules`, {
                    headers: {
                        "User-Id": userId
                    },
                    withCredentials: true
                });
                setActiveModules(response.data);
                console.log('active modules: ', response.data);
                const active_modules = response.data;

                // Update the global state
                setSelectedItems(prevState => ({
                    ...prevState,
                    active_modules: active_modules,
                }));

            } catch (err) {
                setError(err.response ? err.response.data.error : "Failed to fetch modules");
            } finally {
                setLoading(false);
            }
        };

        fetchActiveModules();
    }, [baseURL, userId, setSelectedItems]);

    if (loading) return <p>Loading modules...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <Breadcrumbs />
            <Container className="min-vh-100">
                <Row>
                    {/* Left Sidebar */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />
                    </Col>

                    {/* Main Content Area */}
                    <Col className="content-area mt-5">
                        <h6 className="mb-3">Welcome back, {fullName}</h6>
                        {!fullName && (
                            <p className="text-danger">
                                {!fullName && (
                                    <p className="text-danger">
                                        Please update your <Link to="/profile" style={{ display: 'inline' }}>profile</Link> to include your full name.
                                    </p>
                                )}

                            </p>
                        )}

                        {activeModules.length > 0 ? (
                            <>
                                <p>You have the following active subscriptions:</p>
                                <ul>
                                    {activeModules.map((module, index) => (
                                        <li key={index}>{module}</li>
                                    ))}
                                </ul>
                                <Link to="/design_case">
                                    Get started: click here to set up a design case.
                                </Link>
                                <div className="mt-3">
                                    {activeModules.length < 6 && (
                                        <Link to="/subscribe">Subscribe to the remaining modules.</Link>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div>
                                <p className="text-danger">You don't have any active modules.</p>
                                <Link to="/subscribe">Please subscribe to at least one module to get started.</Link>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default WelcomeRegisteredUser;
