import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Modal, Button, Form, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import AddNewDrillPipeForm from './addNewDrillPipeForm';

function AddDpBhaItem({ show, handleClose, bhaType, wellId, wellboreId, wellDesignCaseId, onBhaItemAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [od, setOd] = useState([]);
    const [nomWeights, setNomWeights] = useState([]);
    const dpRange = ['1', '2', '3'];
    const dpClass = ['New', 'Premium', 'C-1', 'C-2', 'C-3'];
    const [dpInfo, setDpInfo] = useState(null);
    const [dpGrade, setDpGrade] = useState(null);
    const [dpConn, setDpConn] = useState(null);
    const [dpConnOd, setDpConnOd] = useState(null);
    const [dpConnId, setDpConnId] = useState(null);


    const [formData, setFormData] = useState({
        bha_type: bhaType,
        tool_unique_id: '',
        dp_size_id: '',
        dc_num_id: '',
        hwdp_size_id: '',
        jar_unique_id: '',
        casing_size_id: '',
        dp_size: '',
        dp_nom_wt: '',
        dp_id: '',
        dp_tj_od: '',
        dp_tj_id: '',
        tool_class: '',
        dp_grade: '',
        dp_conn: '',
        range_length: '',
        tool_length: ''
        // hint: use table names from dp_library where applicable

    });

    const handleOpenAddDrillPipeModal = () => setShowAddDrillPipeModal(true);
    const handleCloseAddDrillPipeModal = () => setShowAddDrillPipeModal(false);
    const handleNewDrillPipeAdded = () => {
        console.log('New drill pipe added!');
    };
    const [showAddDrillPipeModal, setShowAddDrillPipeModal] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const processOdValues = (odValues) => {
        return odValues.map(od => {
            od = od.toString();
            if (!od.includes('.')) {
                return parseFloat(od).toFixed(1);
            }
            return od;
        });
    };

    useEffect(() => {
        // fetchDrillPipeData();
        axios.get(`${baseURL}/input/dp_library/unique_od`, {
            withCredentials: true
        })
            .then(response => {
                const processedOdValues = processOdValues(response.data.od_values);
                setOd(processedOdValues);
            })
            .catch(error => {
                console.error('Error fetching unique ODs available in db:', error);
            });
    }, [baseURL]);

    const handleOdChange = (e) => {
        const dpSize = e.target.value;
        setFormData({ ...formData, dp_size: dpSize });

        axios.get(`${baseURL}/input/dp_library/${dpSize}`, {
            withCredentials: true
        })
            .then(response => {
                const parsedNomWeights = response.data.map(item => {
                    if (Number.isInteger(item.dp_nom_wt)) {
                        return { ...item, dp_nom_wt: parseFloat(item.dp_nom_wt).toFixed(1) };
                    }
                    return item;
                });
                setNomWeights(parsedNomWeights);
            })
            .catch(error => {
                console.error('Error fetching dp nom wt:', error);
            });
    };

    const handleNomWtChange = (e) => {
        const dpNomWt = e.target.value;
        setFormData(prevState => ({ ...prevState, dp_nom_wt: dpNomWt }));

        axios.get(`${baseURL}/input/dp_library/${formData.dp_size}/${dpNomWt}`, {
            withCredentials: true
        })
            .then(response => {
                setDpInfo(response.data);
                setDpGrade(response.data.dp_grade);
            })
            .catch(error => {
                console.error('Error fetching DP Info:', error);
            });
    };

    const handleGradeChange = (e) => {
        const dpGrade = e.target.value;
        setFormData(prevState => ({ ...prevState, dp_grade: dpGrade }));

        axios.get(`${baseURL}/input/dp_library/${formData.dp_size}/${formData.dp_nom_wt}/${dpGrade}`, {
            withCredentials: true
        })
            .then(response => {
                setDpConn(response.data.dp_conn);
                if (formData.dp_conn) {
                    handleConnChange({ target: { value: '' } });
                }
            })
            .catch(error => {
                console.error('Error fetching DP TJ:', error);
            });
    };

    const handleConnChange = (e) => {
        const dpConn = e.target.value;
        setFormData(prevState => ({ ...prevState, dp_conn: dpConn }));

        axios.get(`${baseURL}/input/dp_library/${formData.dp_size}/${formData.dp_nom_wt}/${formData.dp_grade}/${dpConn}`, {
            withCredentials: true
        })
            .then(response => {
                setFormData(prevState => ({ ...prevState, dp_size_id: response.data.dp_size_id }));
                setDpConnOd(response.data.dp_tj_od);
                setDpConnId(response.data.dp_tj_id);

            })
            .catch(error => {
                console.error('Error fetching DP TJ:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: `${baseURL}/input/bha_items`,
            withCredentials: true,
            data: {
                well_id: parseFloat(wellId),
                wellbore_id: parseFloat(wellboreId),
                well_design_case_id: parseFloat(wellDesignCaseId),
                // bha_type: bhaType,
                tool_unique_id: null,
                dp_size_id: parseFloat(formData.dp_size_id[0]),
                dc_num_id: null,
                hwdp_size_id: null,
                jar_unique_id: null,
                casing_size_id: null,
                // dp_size: parseFloat(formData.dp_size),
                // dp_nom_wt: parseFloat(formData.dp_nom_wt),
                // dp_id: parseFloat(dpInfo.dp_id),
                tool_jt_od: parseFloat(dpConnOd),
                tool_jt_id: parseFloat(dpConnId),
                tool_class: formData.tool_class,
                dp_grade: formData.dp_grade,
                // dp_conn: formData.dp_conn,
                range_length: parseFloat(formData.range_length),
                tool_length: parseFloat(formData.tool_length)

                // ...formData
                // hint: use table names from bha_items where applicable

            }
        }).then(response => {
            onBhaItemAdded();
            handleClose();
        }).catch(error => {
            console.error("Error adding BHA item:", error);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add {bhaType} to the BHA</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="od">
                        <Form.Label column sm={4}>Outer Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_size} onChange={handleOdChange} required>
                                <option value="">Select OD</option>
                                {od.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="nomWt">
                        <Form.Label column sm={4}>Nominal Weight</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_nom_wt} onChange={handleNomWtChange} required>
                                <option value="">Select Nom. Wt.</option>
                                {nomWeights.map((nomWt, index) => (
                                    <option key={index} value={nomWt.dp_nom_wt}>{nomWt.dp_nom_wt}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpId">
                        <Form.Label column sm={4}>Internal Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="dp_id"
                                value={dpInfo ? dpInfo.dp_id || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpGrade">
                        <Form.Label column sm={4}>Grade</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_grade} onChange={handleGradeChange} required>
                                <option value="">Select Grade</option>
                                {dpGrade && dpGrade.length > 0 && dpGrade.map((grade, index) => (
                                    <option key={index} value={grade}>{grade}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="connection">
                        <Form.Label column sm={4}>Connection</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_conn} onChange={handleConnChange} required>
                                <option value="">Select Connection</option>
                                {dpConn && dpConn.length > 0 && dpConn.map((connection, index) => (
                                    <option key={index} value={connection}>{connection}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="class">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.tool_class} onChange={(e) => setFormData({ ...formData, tool_class: e.target.value })} required>
                                <option value="">Select Class</option>
                                {dpClass.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjOd">
                        <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_od"
                                value={dpConnOd ? dpConnOd || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjId">
                        <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="dp_tj_id"
                                value={dpConnId ? dpConnId || '' : ''}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpRange">
                        <Form.Label column sm={4}>Range</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.range_length} onChange={(e) => setFormData({ ...formData, range_length: e.target.value })} required>
                                <option value="">Select Range</option>
                                {dpRange.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="length">
                        <Form.Label column sm={4}>Length</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                // placeholder="Enter length"
                                name="tool_length"
                                value={formData.tool_length}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    {/* Additional form fields based on bhaType */}

                    <Button variant="primary" type="submit">
                        Add
                    </Button>

                    <div className='mt-2'>
                        Can't find the right {bhaType}?{' '}
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="add-to-db-tooltip">Click to add a new BHA item to the database</Tooltip>}
                        >
                            <button type='button' className="text-decoration-none btn btn-link p-0" onClick={handleOpenAddDrillPipeModal}>Add it to the database.</button>
                        </OverlayTrigger>

                        <AddNewDrillPipeForm
                            show={showAddDrillPipeModal}
                            handleClose={handleCloseAddDrillPipeModal}
                            onNewDrillPipeAdded={handleNewDrillPipeAdded}
                        />
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddDpBhaItem;
