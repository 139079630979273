import React, { useState } from 'react';
import { Modal, Button, Col, Form, Alert, Row, Tabs, Tab } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import axios from 'axios';
import HelpComponent from '../../help/helpComponent';

// import AddNewDrillCollarForm from './AddNewDrillCollarForm';

function AddNewDrillPipeForm({ show, handleClose, onNewDrillPipeAdded }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [key, setKey] = useState('dp');
    const [showCalculatedFields, setShowCalculatedFields] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [uploadMethod, setUploadMethod] = useState('manual'); // 'manual', 'file', or 'dragdrop'
    const [showHelp, setShowHelp] = useState(false); // State for showing help modal

    const [formData, setFormData] = useState({
        dp_size: '',
        dp_nom_wt: '',
        dp_grade: '',
        dp_conn: '',
        dp_tj_od: '',
        dp_tj_id: '',
        dp_tj_len: '',
        dp_tj_boxdia: '',
        dp_liu: '',
        dp_id: '',
        dp_tnom: '',
        dp_dt: '',
        dp_as: '',
        dp_ao: '',
        dp_ai: '',
        dp_name: ''
    });

    // Function to calculate dependent values
    const calculateValues = (updatedFormData) => {
        const { dp_size, dp_id } = updatedFormData;

        if (dp_size && dp_id) {
            updatedFormData.dp_tnom = parseFloat(((dp_size - dp_id) / 2).toFixed(5));
            updatedFormData.dp_dt = parseFloat((dp_size / (dp_size - dp_id) / 2).toFixed(5));

            const radiusOuter = dp_size / 2;
            const radiusInner = dp_id / 2;
            updatedFormData.dp_as = parseFloat((Math.PI * (radiusOuter ** 2 - radiusInner ** 2)).toFixed(5));
            updatedFormData.dp_ao = parseFloat((Math.PI / 4 * dp_size ** 2).toFixed(5));
            updatedFormData.dp_ai = parseFloat((Math.PI / 4 * dp_id ** 2).toFixed(5));
            updatedFormData.dp_name = dp_size + 'inch ' + updatedFormData.dp_nom_wt + 'ppf ' + updatedFormData.dp_grade + ' ' + updatedFormData.dp_conn;
        }

        return updatedFormData;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };
        updatedFormData = calculateValues(updatedFormData);
        setFormData(updatedFormData);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        parseFile(file);
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        parseFile(file);
    };

    const parseFile = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data[0]; // Assuming the file contains a single record
                let updatedFormData = { ...formData, ...data };
                updatedFormData = calculateValues(updatedFormData);
                setFormData(updatedFormData);
                setUploadMethod('manual'); // Switch to manual input to allow further editing
            },
            error: (error) => {
                console.error('Error parsing file:', error);
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { dp_size_id, ...dataWithoutId } = formData;

        axios({
            method: 'POST',
            url: `${baseURL}/input/dp_library`,
            withCredentials: true,
            data: dataWithoutId
        })
            .then(response => {
                if (response.status === 201) {
                    onNewDrillPipeAdded();
                    setShowSuccess(true);
                    // handleClose();
                }
            })
            .catch(error => {
                console.error('Error adding new drill pipe:', error);
            });
    };

    const handleUploadMethodChange = (method) => {
        setUploadMethod(method);
        setFormData({
            dp_size: '',
            dp_nom_wt: '',
            dp_grade: '',
            dp_conn: '',
            dp_tj_od: '',
            dp_tj_id: '',
            dp_tj_len: '',
            dp_tj_boxdia: '',
            dp_liu: '',
            dp_id: '',
            dp_tnom: '',
            dp_dt: '',
            dp_as: '',
            dp_ao: '',
            dp_ai: '',
            dp_name: ''
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new drill pipe to the database</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="float-end">
                    <Button variant="outline-secondary" onClick={() => setShowHelp(true)} style={{ marginLeft: '10px' }}>
                        Help
                    </Button>
                </div>
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        You have successfully added a new drill pipe record to the database.
                        It should now be available for selection in the Add BHA Item form.
                    </Alert>
                )}
                <Tabs
                    id="controlled-tab-header"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 mt-5"
                >
                    <Tab eventKey="dp" title="Drill pipe">
                        <div className="upload-method-selection mb-3">
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('manual')}>
                                Enter Data Manually
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('file')} style={{ marginLeft: '10px' }}>
                                Upload File
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleUploadMethodChange('dragdrop')} style={{ marginLeft: '10px' }}>
                                Drag & Drop File
                            </Button>

                        </div>
                        {uploadMethod === 'manual' && (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} controlId="dp_size">
                                    <Form.Label column sm={4}>Pipe Size</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_size"
                                            value={formData.dp_size}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_nom_wt">
                                    <Form.Label column sm={4}>Pipe Nominal Weight</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_nom_wt"
                                            value={formData.dp_nom_wt}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_grade">
                                    <Form.Label column sm={4}>Pipe Grade</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_grade"
                                            value={formData.dp_grade}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_conn">
                                    <Form.Label column sm={4}>Pipe Connection</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_conn"
                                            value={formData.dp_conn}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_tj_od">
                                    <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_tj_od"
                                            value={formData.dp_tj_od}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_tj_id">
                                    <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_tj_id"
                                            value={formData.dp_tj_id}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_tj_len">
                                    <Form.Label column sm={4}>Tool Joint Length</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_tj_len"
                                            value={formData.dp_tj_len}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_tj_boxdia">
                                    <Form.Label column sm={4}>Tool Joint Box Diameter</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_tj_boxdia"
                                            value={formData.dp_tj_boxdia}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_liu">
                                    <Form.Label column sm={4}>Pipe LIU</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_liu"
                                            value={formData.dp_liu}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="dp_id">
                                    <Form.Label column sm={4}>Pipe ID</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            type="text"
                                            name="dp_id"
                                            value={formData.dp_id}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                {showCalculatedFields && (
                                    <>
                                        <Form.Group as={Row} controlId="dp_tnom">
                                            <Form.Label column sm={4}>Tnom</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_tnom"
                                                    value={formData.dp_tnom}
                                                    onChange={handleInputChange}
                                                    required
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="dp_dt">
                                            <Form.Label column sm={4}>D/T Ratio</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_dt"
                                                    value={formData.dp_dt}
                                                    onChange={handleInputChange}
                                                    readOnly
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="dp_as">
                                            <Form.Label column sm={4}>DP AS</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_as"
                                                    value={formData.dp_as}
                                                    onChange={handleInputChange}
                                                    required
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="dp_ao">
                                            <Form.Label column sm={4}>DP AO</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_ao"
                                                    value={formData.dp_ao}
                                                    onChange={handleInputChange}
                                                    required
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="dp_ai">
                                            <Form.Label column sm={4}>DP AI</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_ai"
                                                    value={formData.dp_ai}
                                                    onChange={handleInputChange}
                                                    required
                                                    disabled
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="dp_name">
                                            <Form.Label column sm={4}>DP Name</Form.Label>
                                            <Col sm={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="dp_name"
                                                    value={formData.dp_name}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                <Button variant="primary" type="submit" onClick={() => setShowSuccess(true)}>
                                    Add to Database
                                </Button>
                                <Button variant="primary" type="button" style={{ marginLeft: '17px' }} onClick={() => setShowCalculatedFields(!showCalculatedFields)}>
                                    {showCalculatedFields ? 'Hide Calculated Fields' : 'View Calculated Fields'}
                                </Button>
                            </Form>
                        )}
                        {uploadMethod === 'file' && (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Upload a data file</Form.Label>
                                    <Form.Control type="file" onChange={handleFileUpload} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Upload
                                </Button>
                            </Form>
                        )}
                        {uploadMethod === 'dragdrop' && (
                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
                                        <input {...getInputProps()} />
                                        <p>Drag and drop a file here, or click to select a file</p>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </Tab>
                    
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            <HelpComponent show={showHelp} handleClose={() => setShowHelp(false)} section="addDrillPipeForm" />
        </Modal>
    );
}

export default AddNewDrillPipeForm;
