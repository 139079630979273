import { Admin, Resource, Layout } from 'react-admin';

import Dashboard from './dashboard/Dashboard';
import CustomMenu from './CustomMenu';
import UserList from './UserList';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';
// import Profile from './Profile';
import { CompanyList } from './CompanyList';
import { BlockList } from './BlockList';
import { FieldList } from './FieldList';
import { WellList } from './WellList';
import { WellboreList } from './WellboreList';
import WellDesignCaseList from './WellDesignCaseList';
import WellDesignCaseEdit from './WellDesignCaseEdit';
import customDataProvider from './dataProvider';

const MyLayout = (props) => <Layout {...props} menu={CustomMenu} />;

const AdminPanel = () => (
    <Admin
            layout={MyLayout}    
            basename="/admin"
            // dataProvider={dataProvider}
            dataProvider={customDataProvider}
            dashboard={Dashboard}
            menu={CustomMenu}
        >
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="companies" list={CompanyList} />

        <Resource name="blocks" list={BlockList} />
        <Resource name="fields" list={FieldList} />
        <Resource name="wells" list={WellList} />
        <Resource name="wellbores" list={WellboreList}/>
        <Resource name="well_design_cases" list={WellDesignCaseList} edit={WellDesignCaseEdit} />

        {/* <Resource name="wellbores" list={ListGuesser} /> */}

            {/* <CustomRoutes>
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard" element={<Dashboard />} />

            </CustomRoutes> */}
        </Admin>
    
);

export default AdminPanel;
