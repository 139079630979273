import React, { useState } from 'react';
// import { GlobalStateContext } from '../components/GlobalStateContext';

import { Col, Row, Container } from 'react-bootstrap';
import HelpComponent from '../components/help/helpComponent';

import WellInformationTabs from './tabs_components/wellInformationTabs';
import LeftSidebar from './tabs_components/LeftSidebar';
import RightSidebar from './tabs_components/RightSidebar';
import Breadcrumbs from './tabs_components/Breadcrumb';

function WellInformationPage(isAuthenticated) {
    // const { selectedItems } = useContext(GlobalStateContext);
    const [showHelp, setShowHelp] = useState(false); // State for showing help modal

    return (
        <>
            <Breadcrumbs />

            <Container>
                <Row>
                    {/* Breadcrumb Navigation */}
                    {/* <Breadcrumbs /> */}
                    {/* Left Sidebar */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />
                    </Col>

                    {/* Main Content Area */}
                    <Col xs={12} md={6} lg={8} className="content-area">
                        {isAuthenticated &&
                            <WellInformationTabs />
                        }
                    </Col>

                    {/* Right Sidebar (Optional) */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <RightSidebar />

                    </Col>
                </Row>
                <HelpComponent show={showHelp} handleClose={() => setShowHelp(false)} section="designCase" />
            </Container>
        </>
        
    );
}

export default WellInformationPage;