import { List, Datagrid, TextField, EmailField, EditButton, DeleteButton, BooleanField, TextInput } from 'react-admin';

const UserFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const UserList = () => (
    <List filters={UserFilters}>
        <Datagrid>
            {/* <TextField source="id" /> */}

            <TextField source="full_name" />
            <EmailField source="email" />
            <TextField source="company_name" />
            <TextField source="job_title" />
            <TextField source="created_on" />

            <BooleanField source="is_confirmed" />
            <TextField source="confirmed_on" />
            <TextField source="last_login" />
            <TextField source="login_count" />

            <BooleanField source="two_factor_enabled" />

            {/* <TextField source="logo_path" /> */}
            <BooleanField source="is_admin" />

            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default UserList;
