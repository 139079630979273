import React, { useState } from "react";
import { Form, Button, Row, Col, Table } from "react-bootstrap";

const CasingWearCalculator = () => {
    const [toolJointSize, setToolJointSize] = useState(0); // in inches
    const [drillPipeSize, setDrillPipeSize] = useState(0); // in inches
    const [rpm, setRpm] = useState(0); // Revolutions per minute
    const [weightOnBit, setWeightOnBit] = useState(0); // in lbs or kg
    const [drillingTime, setDrillingTime] = useState(0); // in hours

    const [wearFactor, setWearFactor] = useState(1e-7); // Default wear factor
    const [wearResult, setWearResult] = useState(null);

    const calculateCasingWear = () => {
        if (!toolJointSize || !drillPipeSize || !rpm || !weightOnBit || !drillingTime) {
            alert("Please fill in all fields.");
            return;
        }

        // Calculate contact pressure based on weight-on-bit and tool joint size
        const contactPressure = weightOnBit / (Math.PI * (toolJointSize / 2) ** 2); // in psi

        // Calculate wear volume
        const wearVolume =
            wearFactor *
            contactPressure *
            rpm *
            drillingTime; // Simplified wear formula

        // Estimate wear percentage based on casing thickness (assuming a standard thickness of 0.5 inches)
        const casingThickness = 0.5; // in inches
        const wearPercentage = (wearVolume / casingThickness) * 100;

        setWearResult({
            contactPressure: contactPressure.toFixed(2),
            wearVolume: wearVolume.toFixed(5),
            wearPercentage: wearPercentage.toFixed(2),
        });
    };

    return (
        <div className="container mt-3 min-vh-100">
            <h3>Casing Wear Calculator</h3>
            <Form>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="toolJointSize">
                            <Form.Label>Tool Joint Size (in)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter tool joint size"
                                value={toolJointSize}
                                onChange={(e) => setToolJointSize(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="drillPipeSize">
                            <Form.Label>Drill Pipe Size (in)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter drill pipe size"
                                value={drillPipeSize}
                                onChange={(e) => setDrillPipeSize(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="rpm">
                            <Form.Label>Rotary Speed (RPM)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter RPM"
                                value={rpm}
                                onChange={(e) => setRpm(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="weightOnBit">
                            <Form.Label>Weight on Bit (lbs or kg)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter weight on bit"
                                value={weightOnBit}
                                onChange={(e) => setWeightOnBit(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="drillingTime">
                            <Form.Label>Drilling Time (hours)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter drilling time"
                                value={drillingTime}
                                onChange={(e) => setDrillingTime(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="wearFactor">
                            <Form.Label>Wear Factor</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter wear factor (default 1e-7)"
                                value={wearFactor}
                                onChange={(e) => setWearFactor(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button
                    className="mt-3"
                    variant="primary"
                    onClick={calculateCasingWear}
                >
                    Calculate
                </Button>
            </Form>

            {wearResult && (
                <div className="mt-4">
                    <h5>Results</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Contact Pressure (psi)</td>
                                <td>{wearResult.contactPressure}</td>
                            </tr>
                            <tr>
                                <td>Wear Volume (in³)</td>
                                <td>{wearResult.wearVolume}</td>
                            </tr>
                            <tr>
                                <td>Wear Percentage (%)</td>
                                <td>{wearResult.wearPercentage}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default CasingWearCalculator;
