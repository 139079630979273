import React, { useState } from "react";

export default function PressureDropInsidePipeCalculator({ formData, setFormData }) {
    const [result, setResult] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { mw, toolLength, cumRate, pipeId } = formData;

        if (!mw || !toolLength || !cumRate || !pipeId) {
            alert("Please fill in all fields.");
            return;
        }

        // Calculate internal pressure loss
        const intPressLoss = round(
            (0.000061 * mw * toolLength * Math.pow(cumRate, 1.86)) / Math.pow(pipeId, 4.86),
            1
        );
        setResult(intPressLoss);
    };

    const round = (value, decimals) => {
        return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-4">
                    <label>Mud Weight (mw)</label>
                    <input
                        type="number"
                        step="any"
                        name="mw"
                        value={formData.mw}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="col-md-4">
                    <label>Tool Length</label>
                    <input
                        type="number"
                        step="any"
                        name="toolLength"
                        value={formData.toolLength}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="col-md-4">
                    <label>Cumulative Rate (cumRate)</label>
                    <input
                        type="number"
                        step="any"
                        name="cumRate"
                        value={formData.cumRate}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4">
                    <label>Pipe ID</label>
                    <input
                        type="number"
                        step="any"
                        name="pipeId"
                        value={formData.pipeId}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <button type="submit" className="btn btn-primary mt-3">
                Calculate
            </button>
            {result !== null && (
                <div className="mt-4">
                    <h3>Calculated Pressure Drop Inside Pipe:</h3>
                    <p>{result} psi</p>
                </div>
            )}
        </form>
    );
}
