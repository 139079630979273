import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { GlobalStateContext } from "../../GlobalStateContext";
import AddRheology from "./addRheology";
import EditRheologyModal from "./editRheologyModal";
import { useRheologyData } from "./RheologyProvider";

export default function RheologyTable(props) {
    const { selectedItems } = useContext(GlobalStateContext);
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const {
        holeSection,
        sectionTypeMap,
        fetchRheologyDatabyWellWellbore,
        fetchHoleSectionTypebySectionTypeId
    } = useRheologyData();

    // const [holeSection, setHoleSection] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [rheologyIdToDelete, setRheologyIdToDelete] = useState(null);
    // const [sectionTypeMap, setSectionTypeMap] = useState({});
    const [showAddRheologyModal, setShowAddRheologyModal] = useState(false);
    const [showEditRheologyModal, setShowEditRheologyModal] = useState(false);
    const [rheologyToEdit, setRheologyToEdit] = useState(null);

    useEffect(() => {
        if (selectedItems.well_id && selectedItems.wellbore_id && selectedItems.well_design_case_id) {
            fetchRheologyDatabyWellWellbore();
        }
    }, [
        selectedItems.well_id,
        selectedItems.wellbore_id,
        selectedItems.well_design_case_id,
        selectedItems.well_design_case_name,
        fetchRheologyDatabyWellWellbore,
    ]);

    useEffect(() => {
        holeSection.forEach((holeSectionItem) => {
            fetchHoleSectionTypebySectionTypeId(holeSectionItem.section_type_id);
        });
    }, [holeSection, fetchHoleSectionTypebySectionTypeId]);

    useEffect(() => {
        // console.log("Updated sectionTypeMap:", sectionTypeMap);
    }, [sectionTypeMap]);

    const handleRheologyAdded = () => {
        fetchRheologyDatabyWellWellbore();
    };

    const handleRheologyUpdated = (updatedRheology) => {
        fetchRheologyDatabyWellWellbore();
    };

    const deleteHoleRheology = () => {
        setShowConfirmationModal(false);

        axios.delete(`${baseURL}/input/rheology/${rheologyIdToDelete}`, {
            withCredentials: true
        })
            .then(function (response) {
                console.log(response.data);
                fetchRheologyDatabyWellWellbore();
            })
            .catch(function (error) {
                console.error('Error deleting rheology:', error);
            });

        alert("Successfully Deleted");
    };

    return (
        <div>
            <div className="container h-0">
                <div className="row h-20">
                    <div className="table-responsive small">
                        <table className="table table-striped">
                            <thead className="border-top border-primary">
                                <tr>
                                    <th className="fw-normal">Section</th>
                                    <th className="fw-normal">Type</th>
                                    <th className="fw-normal">Mwt</th>
                                    <th className="fw-normal">PV</th>
                                    <th className="fw-normal">Yp</th>
                                    <th className="fw-normal">3rpm</th>
                                    <th className="fw-normal">6rpm</th>
                                    <th className="fw-normal">300rpm</th>
                                    <th className="fw-normal">600rpm</th>
                                    <th className="fw-normal">Cuttings</th>
                                    <th className="fw-normal">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {holeSection.map((holeSectionItem, index) => (
                                    <tr key={index}>
                                        <td>{sectionTypeMap[holeSectionItem.section_type_id] ? sectionTypeMap[holeSectionItem.section_type_id].section_type : 'Loading...'}</td>
                                        <td>{holeSectionItem.mud_type}</td>
                                        <td>{holeSectionItem.mwt_rheology}</td>
                                        <td>{holeSectionItem.pv}</td>
                                        <td>{holeSectionItem.yp}</td>
                                        <td>{holeSectionItem.fann3}</td>
                                        <td>{holeSectionItem.fann6}</td>
                                        <td>{holeSectionItem.fann300}</td>
                                        <td>{holeSectionItem.fann600}</td>
                                        <td>{holeSectionItem.cuttings_diam}</td>
                                        <td>
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                className="rounded-circle"
                                                onClick={() => {
                                                    setRheologyToEdit(holeSectionItem);
                                                    setShowEditRheologyModal(true);
                                                }}>
                                                <i className="bi bi-pencil"></i>
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                className="rounded-circle"
                                                size="sm"
                                                onClick={() => {
                                                    setRheologyIdToDelete(holeSectionItem.rheology_id);
                                                    setShowConfirmationModal(true);
                                                }}>
                                                <i className="bi bi-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button
                            variant="primary"
                            onClick={() => { setShowAddRheologyModal(true); }}
                            style={{ marginTop: '20px' }}
                        >
                            Add Rheology
                        </Button>

                        <AddRheology
                            show={showAddRheologyModal}
                            handleClose={() => setShowAddRheologyModal(false)}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onRheologyAdded={handleRheologyAdded}
                        />
                    </div>
                </div>
            </div>

            <EditRheologyModal
                show={showEditRheologyModal}
                handleClose={() => setShowEditRheologyModal(false)}
                rheologyData={rheologyToEdit}
                onRheologyUpdated={handleRheologyUpdated}
            />

            {/* Confirmation Modal */}
            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this hole section?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHoleRheology}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
