import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { GlobalStateContext } from '../GlobalStateContext';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import proj4 from 'proj4';
import { useCoordinateConversion } from '../trajectory/CoordinateConversionProvider';

function HeaderSite({ wellId, wellboreId }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    const { CoordinateConverter } = useCoordinateConversion();    

    const { setSelectedItems } = useContext(GlobalStateContext);

    const utmZone = useState('32N');
    const [useLatitudeLongitude, setUseLatitudeLongitude] = useState(false);
    const [initialLatitude, setInitialLatitude] = useState('');
    const [initialLongitude, setInitialLongitude] = useState('');

    const [initialEasting, setInitialEasting] = useState('');
    const [initialNorthing, setInitialNorthing] = useState('');
    const [epsgCode, setEpsgCode] = useState('');

    const [format, setFormat] = useState('DMS');

    const [latitudes, setLatitudes] = useState(null);
    const [longitudes, setLongitudes] = useState(null);
    const [declination, setDeclination] = useState(null);
    // const setConvergence = useState(null);

    const [epsgName, setEpsgName] = useState(''); // New state for storing EPSG name
    const {setEpsgAlert} = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success'); // success, danger, etc.
    const [siteInfoCheck, setSiteInfoCheck] = useState('');

    const [formData, setFormData] = useState({
        rigName: '',
        rkbMsl: '',
        waterDepth: '',
        surfTemp: '',
        geothermalGrad: '',
        wellLocation: 0, // Default to off
        easting: '',
        northing: '',
        epsg: '',
        latLongFormat: '',
    });

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleCoordinateChange = debounce(() => {
        const latitude = parseFloat(initialLatitude);
        const longitude = parseFloat(initialLongitude);
        const easting = parseFloat(initialEasting);
        const northing = parseFloat(initialNorthing);

        if (useLatitudeLongitude) {
            if (validateNumber(latitude) && validateNumber(longitude)) {
                const [easting, northing] = convertLatLonToUTM(latitude, longitude, utmZone);
                setInitialEasting(easting);
                setInitialNorthing(northing);
            } else {
                console.log('Invalid latitude or longitude');
            }
        } else {
            if (validateNumber(easting) && validateNumber(northing)) {
                const [latitude, longitude] = convertUTMToLatLon(easting, northing, utmZone);
                setInitialLatitude(latitude);
                setInitialLongitude(longitude);
            } else {
                console.log('Invalid easting or northing');
            }
        }
    }, 500);

    const convertLatLonToUTM = (latitude, longitude, utmZone) => {
        const utm = proj4(`+proj=utm +zone=${utmZone}`);
        const utmCoordinates = utm.forward([longitude, latitude]);
        return utmCoordinates;
    };

    const convertUTMToLatLon = (easting, northing, utmZone) => {
        const utm = proj4(`+proj=utm +zone=${utmZone}`);
        const latLon = utm.inverse([easting, northing]);
        return latLon;
    };

    const fetchEPSGData = async (code) => {
        try {
            const projResponse = await axios.get(`https://epsg.io/${code}.proj4`);
            const jsonResponse = await axios.get(`https://epsg.io/${code}.json`);
            const epsgName = jsonResponse.data.name || 'Unknown EPSG Code'; // Get EPSG name
            setEpsgName(epsgName); // Set EPSG name in state
            return projResponse.data;
        } catch (error) {
            console.error('Error fetching EPSG data:', error);
            setEpsgName('Error fetching EPSG data'); // Set error message
            return null;
        }
    };

    const handleTransformCoordinates = useCallback(async () => {
        if (!initialEasting || !initialNorthing || !epsgCode) return;

        const projString = await fetchEPSGData(epsgCode);
        if (projString) {
            try {
                const sourceProj = proj4(projString);
                const [longitude, latitude] = proj4(sourceProj, proj4.WGS84, [parseFloat(initialEasting), parseFloat(initialNorthing)]);
                setLatitudes(latitude);
                setLongitudes(longitude);
            } catch (error) {
                console.error('Error during transformation:', error);
            }
        } else {
            console.log('Invalid EPSG code');
            setEpsgAlert(true);
        }
    }, [epsgCode, initialEasting, initialNorthing, setEpsgAlert, setLatitudes, setLongitudes]);

    useEffect(() => {
        handleTransformCoordinates();
    }, [initialEasting, initialNorthing, epsgCode, handleTransformCoordinates]);

    const convertToDMS = (decimal) => {
        const degrees = Math.floor(decimal);
        const minutesDecimal = (decimal - degrees) * 60;
        const minutes = Math.floor(minutesDecimal);
        const seconds = Math.round((minutesDecimal - minutes) * 60);
        return `${degrees}°${minutes}'${seconds}"`;
    };

    const convertToDM = (decimal) => {
        const degrees = Math.floor(decimal);
        const minutesDecimal = (decimal - degrees) * 60;
        const minutes = minutesDecimal.toFixed(3);
        return `${degrees}°${minutes}'`;
    };

    const formatCoordinates = (value) => {
        value = validateNumber(value);
        if (typeof value !== 'number' || isNaN(value)) {
            return '';
        }
        switch (format) {
            case 'DD':
                return value.toFixed(6);
            case 'DM':
                return convertToDM(value);
            case 'DMS':
                return convertToDMS(value);
            default:
                return value.toFixed(6);
        }
    };

    const handleInitialEastingChange = (event) => {
        const value = parseFloat(event.target.value);

        setFormData((prevData) => ({
            ...prevData,
            easting: parseFloat(value),

        }));

        if (!isNaN(value)) {
            setInitialEasting(value);
        } else {
            console.log('Invalid easting value');
        }
    };

    const handleInitialNorthingChange = (event) => {
        const value = parseFloat(event.target.value);

        setFormData((prevData) => ({
            ...prevData,
            northing: parseFloat(value),

        }));

        if (!isNaN(value)) {
            setInitialNorthing(value);
        } else {
            console.log('Invalid northing value');
        }
    };

    const handleEpsgCodeChange = (event) => {
        const value = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            epsg: parseInt(value),
        }));

        if (/^\d+$/.test(value)) { // Check if the value is numeric
            setEpsgCode(value);
            setEpsgName(''); // Clear previous EPSG name when the code changes
        } else {
            console.log('EPSG code must be numeric');
            setEpsgName('EPSG code must be numeric'); // Set error message
        }
    };

    const fetchSiteData = useCallback(() => {
        axios
            .get(`${baseURL}/input/site/${wellId}/${wellboreId}`, {
                withCredentials: true,
            })
            .then((response) => {
                const siteData = response.data[0]; // Access the first element of the array

                setSiteInfoCheck(siteData);

                setFormData({
                    rigName: siteData?.rig_name || '',
                    rkbMsl: siteData?.rkb_msl || '',
                    waterDepth: siteData?.water_depth || '',
                    surfTemp: siteData?.surf_temp || '',
                    geothermalGrad: siteData?.temp_grad || '',
                    wellLocation: siteData?.well_location || 0,
                    easting: siteData?.easting || '',
                    northing: siteData?.northing || '',
                    epsg: siteData?.epsg_code || '',

                });

                setSelectedItems((prevState) => ({
                    ...prevState,
                    rkbMsl: formData.rkbMsl,
                    waterDepth: formData.waterDepth,
                    surfTemp: formData.surfTemp,
                    geothermalGrad: formData.geothermalGrad,
                    epsg_code: formData.epsg,
                    epsg_name: epsgName,
                    easting: formData.easting,
                    northing: formData.northing,
                    siteData: siteData,
                    latLongFormat: formData.latLongFormat,
                    
                }));

                // Update initialEasting, initialNorthing, and epsgCode with the fetched data
                setInitialEasting(siteData?.easting || '');
                setInitialNorthing(siteData?.northing || '');
                setEpsgCode(siteData?.epsg_code || '');
                setLatitudes(siteData?.latitude || '');
                setLongitudes(siteData?.longitude || '');

                handleTransformCoordinates(); // Perform the transformation after setting the fetched data
            })
            .catch((error) => {
                console.error('Error fetching site data:', error);
            });
    }, [baseURL, epsgName, formData.easting, formData.epsg, formData.geothermalGrad, formData.latLongFormat, formData.northing, formData.rkbMsl, formData.surfTemp, formData.waterDepth, handleTransformCoordinates, setSelectedItems, wellId, wellboreId, setLongitudes, setLatitudes]);

    useEffect(() => {
        if (wellId) {
            fetchSiteData();
        }
    }, [wellId, wellboreId, fetchSiteData]);

    const handleSubmitPost = (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formData,
            well_id: parseInt(wellId),
            wellbore_id: parseInt(wellboreId),
            rkb_msl: parseFloat(formData.rkbMsl),
            water_depth: parseFloat(formData.waterDepth),
            surf_temp: parseFloat(formData.surfTemp),
            temp_grad: parseFloat(formData.geothermalGrad),
            easting: parseFloat(formData.easting),
            northing: parseFloat(formData.northing), 
            epsg: parseInt(formData.epsg, 10),
        };

        axios
            .post(`${baseURL}/input/site`, updatedFormData, {
                withCredentials: true,
            })
            .then((response) => {
                console.log('Form data saved successfully:', response.data);
                setShowAlert(true);
                setAlertMessage('Form data saved successfully');
                setAlertVariant('success'); // Set the variant to success
                setTimeout(() => setShowAlert(false), 5000);
            })
            .catch((error) => {
                console.error('Error saving form data:', error);
                setShowAlert(true);
                setAlertMessage('Error saving form data');
                setAlertVariant('danger'); // Set the variant to danger
                setTimeout(() => setShowAlert(false), 5000);
            });
    };

    const handleSubmitPut = (e) => {
        e.preventDefault();

        const updatedFormData = {
            ...formData,
            epsg: parseInt(formData.epsg, 10),
        };

        axios
            .put(`${baseURL}/input/site/${wellId}/${wellboreId}`, updatedFormData, {
                withCredentials: true,
            })
            .then((response) => {
                setShowAlert(true);
                setAlertMessage('Form data updated successfully');
                setAlertVariant('success'); // Set the variant to success
                setTimeout(() => setShowAlert(false), 5000);
            })
            .catch((error) => {
                console.error('Error updating form data:', error);
                setShowAlert(true);
                setAlertMessage('Error updating form data');
                setAlertVariant('danger'); // Set the variant to danger
                setTimeout(() => setShowAlert(false), 5000);
            });
    };

    const validateNumber = (num) => {
        if (typeof num === 'number' && !isNaN(num)) {
            return num;
        }
        const parsed = parseFloat(num);
        if (!isNaN(parsed)) {
            return parsed;
        }
        return NaN;
    };

    const formatEPSGName = (name) => {
        if (name === 'Unknown EPSG Code' || name === 'Error fetching EPSG data' || name === 'EPSG code must be numeric') {
            return <span style={{ color: 'red' }}>{name}</span>;
        }
        return name;
    };

    const handleLocationChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            wellLocation: parseInt(value),
        }));
    };

    const handleFormatChange = (event) => {
        setFormat(event.target.value);
        setFormData((prevData) => ({
            ...prevData,
            latLongFormat: (event.target.value)
        }));
    };

    // useEffect(() => {
    //     const fetchCoordinates = async () => {
    //         try {

    //             const { latitude, longitude, declination, convergence_angle } = await CoordinateConverter(initialNorthing, initialEasting, epsgCode);
    //             setLatitudes(latitude);
    //             setLongitudes(longitude);
    //             setDeclination(declination);
    //             setConvergence(convergence_angle);

    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     fetchCoordinates();
    // }, [initialNorthing, initialEasting, epsgCode, setConvergence]);

    useEffect(() => {
        // Define a function that performs the coordinate conversion
        const handleConvert = async () => {
            if (initialNorthing && initialEasting && epsgCode) {
                try {
                    const result = await CoordinateConverter(initialNorthing, initialEasting, epsgCode);
                    console.log("Converted coordinates:", result);
                    setLatitudes(result.latitude);
                    setLongitudes(result.longitude);
                    setDeclination(result.declination);

                } catch (error) {
                    console.error("Error during conversion:", error);
                }
            }
        };

        // Call the conversion function
        handleConvert();

    }, [initialNorthing, initialEasting, epsgCode, CoordinateConverter]);

    return (
        <div className="mx-4">
            {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>}
            <Form onSubmit={siteInfoCheck ? handleSubmitPut : handleSubmitPost }>
                <Row className='mb-1'>
                    <Col sm="2">
                        <div>
                            <legend className="col-form-label col-sm-2 pt-0">Location:</legend>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="wellLocation"
                                    id="gridRadios1"
                                    value="0"
                                    checked={formData.wellLocation === 0}
                                    onChange={handleLocationChange}
                                />
                                <label className="form-check-label" htmlFor="gridRadios1">
                                    Onshore
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="wellLocation"
                                    id="gridRadios2"
                                    value="1"
                                    checked={formData.wellLocation === 1}
                                    onChange={handleLocationChange}
                                />
                                <label className="form-check-label" htmlFor="gridRadios2">
                                    Offshore
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Row} className="mb-1">
                        <Form.Label column sm="2">Rig Name:</Form.Label>
                        <Col sm="3">
                        <Form.Control
                            type="text"
                            id="rigName"
                            placeholder="Rig Name"
                            value={formData.rigName}
                            onChange={(e) => setFormData({ ...formData, rigName: e.target.value })}
                            />
                        </Col>
                    
                    
                    <Form.Label column sm="2">RKB MSL:</Form.Label>
                    <Col sm='3'>
                        <Form.Control
                            type="number"
                            placeholder="RKB MSL"
                            value={formData.rkbMsl}
                            onChange={(e) => setFormData({ ...formData, rkbMsl: parseFloat(e.target.value) })}
                        />
                        </Col>
                    </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Row} className="mb-1">

                    <Form.Label column sm="2">Water Depth:</Form.Label>
                    <Col sm='3'>
                        <Form.Control
                            type="number"
                            placeholder="Water Depth"
                            value={formData.waterDepth}
                                onChange={(e) => setFormData({ ...formData, waterDepth: parseFloat(e.target.value) })}
                        />
                    </Col>
                            <Form.Label column sm="2">Surface Temp:</Form.Label>
                        <Col sm='3'>

                        <Form.Control
                            type="number"
                            placeholder="Surface Temperature"
                            value={formData.surfTemp}
                                onChange={(e) => setFormData({ ...formData, surfTemp: parseFloat(e.target.value) })}
                        />
                    </Col>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Row} className="mb-1">

                    <Form.Label column sm="2">Temp Gradient:</Form.Label>
                    <Col sm='3'>    
                    <Form.Control
                            type="number"
                            placeholder="Geothermal Gradient"
                            value={formData.geothermalGrad}
                                onChange={(e) => setFormData({ ...formData, geothermalGrad: parseFloat(e.target.value) })}
                        />
                        </Col>
                    </Form.Group>
                </Row>
                <hr />
                <Row>
                    <Form.Group as={Row} className="mb-1">
                    <Col sm='5'>
                        <Form.Check
                            type="checkbox"
                            label="Use Latitude and Longitude"
                            checked={useLatitudeLongitude}
                            onChange={() => setUseLatitudeLongitude(!useLatitudeLongitude)}
                        />
                    </Col>
                    
                    <Form.Label column sm="2">EPSG Code:</Form.Label>
                        <Col sm='3'>
                        <Form.Control
                            type="text"
                            placeholder="EPSG Code"
                            value={epsgCode}
                            onChange={handleEpsgCodeChange}
                        />
                        {epsgName && <div>EPSG Name: {formatEPSGName(epsgName)}</div>}
                    </Col>
                    </Form.Group>
                </Row>
                <Row>
                    {useLatitudeLongitude ? (
                        <>
                            <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="2">Latitude:</Form.Label>
                                <Col sm='3'>
                                <Form.Control
                                    type="number"
                                    placeholder="Latitude"
                                    value={initialLatitude}
                                    onChange={(e) => setInitialLatitude(e.target.value)}
                                    onBlur={handleCoordinateChange}
                                />
                            </Col>
                                <Form.Label column sm="2">Longitude:</Form.Label>
                                <Col sm='4'>

                                <Form.Control
                                    type="number"
                                    placeholder="Longitude"
                                    value={initialLongitude}
                                    onChange={(e) => setInitialLongitude(e.target.value)}
                                    onBlur={handleCoordinateChange}
                                />
                                </Col>
                            </Form.Group>
                        </>
                    ) : (
                        <>
                                <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="2">Easting:</Form.Label>
                                <Col sm='3'>
                                <Form.Control
                                    type="number"
                                    placeholder="Easting"
                                    value={initialEasting}
                                    onChange={handleInitialEastingChange}
                                    onBlur={handleCoordinateChange}
                                />
                            </Col>
                                
                                    <Form.Label column sm="2">Northing:</Form.Label>
                                    <Col sm='3'>
                                <Form.Control
                                    type="number"
                                    placeholder="Northing"
                                    value={initialNorthing}
                                    onChange={handleInitialNorthingChange}
                                    onBlur={handleCoordinateChange}
                                />
                                    </Col>
                                </Form.Group>
                        </>
                    )}
                </Row>
                {/* <Row className="mb-1">
                    <Col sm='4'>
                        <Form.Label>EPSG Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="EPSG Code"
                            value={epsgCode}
                            onChange={handleEpsgCodeChange}
                        />
                        {epsgName && <div>EPSG Name: {formatEPSGName(epsgName)}</div>}
                    </Col>
                </Row> */}
                <Row className="mb-1">
                    <Col sm='3'>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col className='mt-3'>
                    <p>Magnetic Declination: {declination?.toFixed(3)}</p>
                </Col>
                <Col className='mt-3'>
                    <p>Model: World Magnetic Model (WMM2020)</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <p>Grid Correction: {convergence + declination}</p> */}
                </Col>
            </Row>
                
                <Row className="mb-1">
                    <Row>
                        <Form.Group as={Row} className="mb-1">
                            <Form.Label column sm="2">Coordinate Format:</Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" value={format} onChange={handleFormatChange}>
                                    <option value="DD">Decimal Degrees (DD)</option>
                                    <option value="DM">Degrees Minutes (DM)</option>
                                    <option value="DMS">Degrees Minutes Seconds (DMS)</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Row>
                    <Row>
                    <Col sm='2'>
                        <p>
                            <strong>Latitude: </strong>
                        </p>
                    </Col>
                        <Col sm='3'>
                        <p>
                            {formatCoordinates(longitudes)}
                        </p>
                    </Col>
                    
                    <Col sm='2'>
                        <p>
                            <strong>Longitude: </strong>
                        </p>
                    </Col>
                        <Col sm='4'>
                        <p>
                            {formatCoordinates(latitudes)}
                        </p>
                    </Col>
                    </Row>
                </Row>

        </div>
    );
}

export default HeaderSite;
