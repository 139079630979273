import React, { } from "react";

export default function PricingPage() {
    // const priceTableId = process.env.REACT_STRIPE_TABLE_ID;

    return (
        <div>
            <div className="container d-flex flex-column min-vh-100">
                <div className="row h-100">
                    <div className="col-12">

                        Please check back soon.
                        
                    </div>
                </div>
            </div>
        </div>
    );
}