import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { GlobalStateContext } from "../GlobalStateContext";
import { useHoleSection } from "../HoleSectionProvider";

export const EarthModelContext = createContext();

export const fetchEarthModelData = async (wellId, wellboreId, wellDesignCaseId) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    if (!baseURL) {
        throw new Error("API base URL is not defined.");
    }

    try {
        const response = await axios({
            method: "GET",
            url: `${baseURL}/input/earth_model/${wellId}/${wellboreId}/${wellDesignCaseId}`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching earth model data:", error);
        throw error;
    }
};

export const EarthModelProvider = ({ children }) => {
    const [earthModelData, setEarthModelData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { selectedItems } = useContext(GlobalStateContext);
    const wellId = selectedItems?.well_id;
    const wellboreId = selectedItems?.wellbore_id;
    const wellDesignCaseId = selectedItems?.well_design_case_id;
    const { selectedHoleItems } = useHoleSection();

    const fetchAndSetEarthModelData = async (wellId, wellboreId, wellDesignCaseId) => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchEarthModelData(wellId, wellboreId, wellDesignCaseId);
            setEarthModelData(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (wellId && wellboreId && wellDesignCaseId) {
            fetchAndSetEarthModelData(wellId, wellboreId, wellDesignCaseId);
        }
    }, [wellId, wellboreId, wellDesignCaseId]);

    useEffect(() => {

        const fetchData = async () => {
            try {

                if (earthModelData && earthModelData.length > 0 && selectedHoleItems?.openHoleTvd) {
                    let closestPorePressure = null;
                    let closestFracGradient = null;
                    let smallestDifference = Number.MAX_SAFE_INTEGER;

                    earthModelData.forEach(dataPoint => {
                        const tvd = dataPoint.tvd;
                        const porePressure = parseFloat(dataPoint.pore_press);
                        const fracGradient = parseFloat(dataPoint.frac_grad);

                        const difference = Math.abs(tvd - selectedHoleItems?.openHoleTvd);

                        if (difference < smallestDifference) {
                            smallestDifference = difference;
                            closestPorePressure = porePressure;
                            closestFracGradient = fracGradient;
                        }
                    });

                    setEarthModelData(prevSettings => ({
                        ...prevSettings,
                        fsgTD: closestFracGradient,
                        formationPressureTD: closestPorePressure,
                    }));
                }
            } catch (error) {
                console.error("Error fetching earth model data:", error);
            }
        };

        if (selectedHoleItems?.openHoleTvd && wellId && wellboreId && wellDesignCaseId) {
            fetchData();
        }
    }, [selectedHoleItems?.openHoleTvd, wellId, wellboreId, wellDesignCaseId, earthModelData]);

    return (
        <EarthModelContext.Provider value={{ earthModelData, setEarthModelData, fetchAndSetEarthModelData, loading, error }}>
            {children}
        </EarthModelContext.Provider>
    );
};

export const useEarthModel = () => useContext(EarthModelContext);
