import React from 'react';
import { Button, Modal} from 'react-bootstrap';

function HelpComponent({ show, handleClose, section }) {
    const renderHelpContent = () => {
        switch (section) {
            case 'addDrillPipeForm':
                return (
                    <>
                        <h5>Purpose of this Form</h5>
                        <p>
                            This form is used to add new drill pipe records to the database. The information provided
                            will be used in the Add BHA Item form and other relevant sections of the application.
                        </p>
                        <h5>How it Works</h5>
                        <p>
                            You can manually enter data, upload a CSV file, or use drag and drop to provide the required
                            information about the drill pipe. The form will automatically calculate certain values based
                            on the input.
                        </p>
                        <h5>CSV File Format</h5>
                        <p>
                            If you choose to upload a CSV file, ensure it is formatted as follows:
                        </p>
                        <pre>
                            dp_size,dp_nom_wt,dp_grade,dp_conn,dp_tj_od,dp_tj_id,dp_tj_len,dp_tj_boxdia,dp_liu,dp_id
                            <br />
                            5,19.5,Grade S,Connection Type,4.5,4,10,8,3000,3.5
                        </pre>
                    </>
                );
            case 'addBhaForm':
                return (
                    <>
                        <h5>Purpose of this Form</h5>
                        <p>
                            This form is used to add new BHA (Bottom Hole Assembly) items to the database. The information provided
                            will be used in various sections of the application to track and manage BHA components.
                        </p>
                        <h5>How it Works</h5>
                        <p>
                            You can manually enter data about the BHA components. Ensure all required fields are filled out
                            accurately to avoid any errors during submission.
                        </p>
                        <h5>Required Fields</h5>
                        <p>
                            The following fields are required for adding a new BHA item:
                        </p>
                        <ul>
                            <li>BHA Name</li>
                            <li>BHA Type</li>
                            <li>Component Details (Size, Weight, Grade, etc.)</li>
                            <li>Connection Type</li>
                            <li>Length. Note that cumulative length is calculated automatically.</li>
                        </ul>
                    </>
                );
            case 'kickTolerance':
                return (
                    <>
                        <h5>Purpose of this Form</h5>
                        <p>
                            This form is used to calculate kick tolerance.
                        </p>
                        <h5>How it Works</h5>
                        
                    </>
                );
            case 'designCase':
                return (
                    <>
                        <h5>Purpose of this Form</h5>
                        <p>
                            This form is used to create a well design case.
                        </p>
                        <h5>How it Works</h5>
                        <p>
                            Select one of the hole sections to set it as the default hole section for your current design. The casing above it will be the casing section for the design.
                        </p>

                    </>
                );
            // Add cases for other sections of the website as needed
            default:
                return <p>No help content available for this section.</p>;
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderHelpContent()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default HelpComponent;
