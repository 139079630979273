// import { useState } from "react";

export default function AnnularPressureLossCalculator({
    mw,
    yp,
    pv,
    av,
    diamHole,
    diamPipe,
    length,
    fann3,
    fann6,
    fann600,
    fann300,
    cuttingsConc,
    pipeEccentricity,
    hydraulic_model
}) {
    console.log('Inputs annPressLossSurge', mw, yp, pv, av, diamHole, diamPipe, length, fann3, fann6, fann600, fann300, cuttingsConc, pipeEccentricity)

    // const c = cuttingsConc;
    const e = pipeEccentricity;

    // Define a function 'round' that rounds a number 'n' to the specified number of 'decimals'
    const round = (n, decimals = 0) =>
        // Convert 'n' to a string in scientific notation with the desired number of decimals
        Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);
    const calculateAnnLoss = () => {

        // H-B parameters
        const yieldStress = round(2 * fann3 - fann6, 3);
        const na = round(3.32 * Math.log10(fann600 - yieldStress) / (fann300 - yieldStress), 3);
        const k = round((fann300 - yieldStress) / Math.pow(511, na), 3);

        // const rhoC = 2.60; // Cuttings Density
        // const ESDa = mw; // Annular static density (update formula if necessary)
        const alpha = 1; // Geometry factor (alpha = 0 for pipe, alpha = 1 for annulus)

        const nP = 3.32 * Math.log10(fann600) / fann300;
        const kP = fann300 / Math.pow(511, nP);

        const bA = (((3 - alpha) * na + 1) / ((4 - alpha) * na)) * (1 + alpha / 2);
        const g = bA;

        const tauY = 2 * fann3 - fann6;
        const R = tauY / yp;

        const dHyd = diamHole - diamPipe;
        const gammaW = (1.6 * g * av) / dHyd;

        const tauF = Math.pow((4 - alpha) / (3 - alpha), na) * tauY + k * Math.pow(gammaW, na);
        const tauW = 1.066 * tauF;

        const nReG = (mw * Math.pow(av, 2)) / (19.36 * tauW); // Reynolds number
        const nCRe = 3470 - 1370 * na; // Critical Reynolds number

        const b = (pv * (1 + alpha / 2)) / dHyd;
        const vCb = (67.86 / mw) * (b + Math.sqrt(Math.pow(b, 2) + 9.42 * mw * yp * ((4 - alpha) / (3 - alpha))));
        const gP = (((3 - alpha) * nP + 1) / (4 - alpha)) * (1 + alpha / 2);
        const vCp = Math.pow(((28277 * (2.533 - nP) * kP) / mw) * Math.pow((1.6 * gP) / dHyd, nP), 1 / (2 - nP));
        const vC = vCp + (vCb - vCp) * Math.pow(R, Math.sqrt(vCp / vCb));

        let critVel, n;
        if (hydraulic_model === "Herschel Bulkley") {
            critVel = round(vC);
            n = na;
        } else if (hydraulic_model === "Power law") {
            critVel = round(vCp);
            n = nP;
        } else {
            critVel = round(vCb); // for Bingham plastic model
        }

        const Rlam = 1 - 0.072 * (e / n) * Math.pow(diamPipe / diamHole, 0.8454) - 3 / 2 * Math.pow(e, 2) * Math.sqrt(n) * Math.pow(diamPipe / diamHole, 0.1852) + 0.96 * Math.pow(e, 3) * Math.sqrt(n) * Math.pow(diamPipe / diamHole, 0.2527);
        const Rturb = 1 - 0.048 * (e / n) * Math.pow(diamPipe / diamHole, 0.8454) - 2 / 3 * Math.pow(e, 2) * Math.sqrt(n) * Math.pow(diamPipe / diamHole, 0.1852) + 0.285 * Math.pow(e, 3) * Math.sqrt(n) * Math.pow(diamPipe / diamHole, 0.2527);

        const flowType = av < critVel ? "Laminar" : "Turbulent";
        const r = flowType === "Laminar" ? Rlam : Rturb;

        const fLam = 16 / nReG;
        const fTrans = (16 * nReG) / Math.pow(nCRe, 2);

        const a = (Math.log10(nP) + 3.93) / 50;
        const bT = (1.75 - Math.log10(nP)) / 7;
        const fTurb = a / Math.pow(nReG, bT);

        const fInt = Math.pow(Math.pow(fTrans, -8) + Math.pow(fTurb, -8), -1 / 8);
        const f = Math.pow(Math.pow(fInt, 12) + Math.pow(fLam, 12), 1 / 12);

        const annPressLoss = round(((1.076 * mw * length * Math.pow(av, 2) * f) / (100000 * (diamHole - diamPipe))) * r, 2);

        return annPressLoss;
    };

    return calculateAnnLoss();
}
