import { List, Datagrid, TextField, TextInput, EditButton, DeleteButton, ReferenceField } from 'react-admin';

const DesignFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

const WellDesignCaseList = () => (
    <List filters={DesignFilters}>
        <Datagrid>
            <TextField source="id" aria-label="Case ID" />

            <ReferenceField source="well_id" reference="wells" label="Well">
                <TextField source="well_name" />
            </ReferenceField>

            <ReferenceField source="wellbore_id" reference="wellbores" label="Wellbore">
                <TextField source="wellbore_name" />
            </ReferenceField>

            <ReferenceField source="units_id" reference="units_selection" label="Units">
                <TextField source="units_type" />
            </ReferenceField>

            <TextField source="well_design_case_name" label="Design Case Name" />
            <TextField source="wd_date_created" label="Date Created" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default WellDesignCaseList;
