import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

export const CompanyList = () => (
    <List>
        <Datagrid>
            <TextField source="company_name" />
            <TextField source="address" />
            <TextField source="country" />
            <TextField source="id" />
            <ReferenceField source="user_id" reference="users" />
        </Datagrid>
    </List>
);