import { Link, useLocation } from 'react-router-dom';
import { Col, Row, Breadcrumb } from 'react-bootstrap';

function Breadcrumbs() {
    const location = useLocation();

    // Mapping paths to breadcrumb labels and links
    const breadcrumbMap = {
        '/': 'Home',
        '/welcome': 'Dashboard',
        '/subscribe': 'Subscription Page',
        '/design_case': 'Design Case',
        '/well_information': 'Well Information',
        '/hydraulics': 'Hydraulics',
        '/swab_and_surge': 'Swab & Surge',
        '/kick_tolerance': 'Kick Tolerance',
        '/casing_design': 'Casing Design',

    };

    // Split the pathname and filter out any empty segments
    const pathnames = location.pathname.split('/').filter(Boolean);

    return (
        <div className='breadcrumbs bg-info-subtle'>
                <Row>
                    <Col>
                    <Breadcrumb className='mt-3'>
                        {/* Home breadcrumb always present */}
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                            {breadcrumbMap['/']}
                        </Breadcrumb.Item>

                        {/* Dynamically add other breadcrumb items based on path */}
                        {pathnames.map((path, index) => {
                            // Rebuild the path up to this point for the link
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                            const isLast = index === pathnames.length - 1;

                            return isLast ? (
                                <Breadcrumb.Item key={to} active>
                                    {breadcrumbMap[to]}
                                </Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item key={to} linkAs={Link} linkProps={{ to }}>
                                    {breadcrumbMap[to]}
                                </Breadcrumb.Item>
                            );
                        })}
                    </Breadcrumb>
                    </Col>
                </Row>
            </div>
        
    );
}

export default Breadcrumbs;
