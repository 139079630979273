import React from 'react';

import SidebarInputs from './sidebarInputs';
import SidebarModules from './sidebarModules';
import { Nav } from 'react-bootstrap';
import InputIcon from '@mui/icons-material/Input';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

function LeftSidebar() {
    return (

        <>
            <Nav className="flex-column bg-white sidebar border-end">

                <div className='mt-5 fw-semibold align-items-center'><InputIcon className='me-2' />Inputs</div>
                    <span><SidebarInputs /></span>
                            
                <div className='fw-semibold align-items-center'><ViewModuleIcon className='me-2' />Modules</div>
                    <span><SidebarModules /></span>
            </Nav>
        </>
    );
}

export default LeftSidebar;