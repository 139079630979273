import React, { useState } from 'react';

const calculateBottomHoleTemperature = (tvd, surf_temp, temp_grad, siteData, flowline_temp) => {
    let _To = parseFloat(surf_temp);
    let tg = parseFloat(temp_grad) / 100;
    let _Dw = siteData.water_depth;
    let tgw = tg;
    let _Tbhs, _Tbhc, _Tml;

    if (siteData.well_location === 'offshore') {
        if (_Dw < 3000) {
            _Tml = Math.round((154.43 - 14.214 * Math.log(_Dw)) * 100) / 100;  // Mud line temperature, deg.F
        } else {
            _Tml = Math.round((41.714 - 0.0003714 * _Dw) * 100) / 100;
        }
        _Tbhs = Math.round((_Tml + tgw * (tvd - _Dw)) * 100) / 100;
        _Tbhc = Math.round((-102.1 + (3354 * tgw) + ((1.342 - 22.28 * tgw) * _Tbhs)) * 100) / 100;
    } else {
        // Onshore well
        _Tbhs = _To + (tg * tvd);
        _Tbhc = -102.1 + (3354 * tg) + ((1.342 - 22.28 * tg) * _Tbhs);
    }

    // Use Flowline Temperature if provided for circulating bottom hole temperature calculation
    if (flowline_temp) {
        _Tbhc = Math.round((_Tbhc + parseFloat(flowline_temp)) / 2 * 100) / 100; // Averaging the Tbhc with Flowline Temperature
    }

    return { _Tbhs, _Tbhc };
};

const TemperatureCalculation = () => {
    const [tvd, setTvd] = useState('');
    const [surfTemp, setSurfTemp] = useState('');
    const [tempGrad, setTempGrad] = useState('');
    const [flowlineTemp, setFlowlineTemp] = useState(''); // New state for Flowline Temperature
    const [locationType, setLocationType] = useState('onshore');
    const [waterDepth, setWaterDepth] = useState('');
    const [bottomHoleTemps, setBottomHoleTemps] = useState(null);

    const handleCalculate = () => {
        const siteData = { water_depth: parseFloat(waterDepth), well_location: locationType };
        const { _Tbhs, _Tbhc } = calculateBottomHoleTemperature(tvd, surfTemp, tempGrad, siteData, flowlineTemp);
        setBottomHoleTemps({ _Tbhs, _Tbhc });
    };

    return (
        <div className='container mt-5 min-vh-100'>
            <h2>Bottom Hole Temperature Calculation</h2>
            <form>
                <div className="form-group">
                    <label>TVD (True Vertical Depth) in feet:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={tvd}
                        onChange={(e) => setTvd(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Surface Temperature in °F:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={surfTemp}
                        onChange={(e) => setSurfTemp(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Temperature Gradient (°F/100ft):</label>
                    <input
                        type="number"
                        className="form-control"
                        value={tempGrad}
                        onChange={(e) => setTempGrad(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Well Location:</label>
                    <select
                        className="form-control"
                        value={locationType}
                        onChange={(e) => setLocationType(e.target.value)}
                    >
                        <option value="onshore">Onshore</option>
                        <option value="offshore">Offshore</option>
                    </select>
                </div>
                {locationType === 'offshore' && (
                    <div className="form-group">
                        <label>Water Depth (in feet, for offshore only):</label>
                        <input
                            type="number"
                            className="form-control"
                            value={waterDepth}
                            onChange={(e) => setWaterDepth(e.target.value)}
                            required
                        />
                    </div>
                )}
                <div className="form-group">
                    <label>Flowline Temperature in °F (for circulating temperature calculation):</label>
                    <input
                        type="number"
                        className="form-control"
                        value={flowlineTemp}
                        onChange={(e) => setFlowlineTemp(e.target.value)}
                    />
                </div>
                <button type="button" className="btn btn-primary" onClick={handleCalculate}>
                    Calculate Temperatures
                </button>
            </form>

            {bottomHoleTemps && (
                <div className="result">
                    <h3>Calculated Temperatures:</h3>
                    <p><strong>Static Bottom Hole Temperature (Tbhs):</strong> {bottomHoleTemps._Tbhs} °F</p>
                    <p><strong>Circulating Bottom Hole Temperature (Tbhc):</strong> {bottomHoleTemps._Tbhc} °F</p>
                </div>
            )}
        </div>
    );
};

export default TemperatureCalculation;
