import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Alert } from 'react-bootstrap';
import Papa from 'papaparse'; // Import papaparse for CSV parsing
import interpolateSurv from '../utils/interpolateSurv';

export default function SurveyInterpolation() {
    const [surveyData, setSurveyData] = useState([]);
    const [interpolationPoint, setInterpolationPoint] = useState('');
    const [results, setResults] = useState(null);
    const [error, setError] = useState('');

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const fileType = file.name.split('.').pop().toLowerCase();
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const content = e.target.result;

                if (fileType === 'json') {
                    const parsedData = JSON.parse(content);
                    setSurveyData(parsedData);
                    setError('');
                } else if (fileType === 'csv') {
                    Papa.parse(content, {
                        header: true,
                        skipEmptyLines: true,
                        complete: (result) => {
                            const parsedData = result.data.map(row => ({
                                md_survey: parseFloat(row.md_survey) || '',
                                tvd_survey: parseFloat(row.tvd_survey) || '',
                                incl_survey: parseFloat(row.incl_survey) || '',
                                azim_survey: parseFloat(row.azim_survey) || ''
                            }));
                            setSurveyData(parsedData);
                            setError('');
                        },
                        error: () => {
                            setError('Invalid CSV file format. Please ensure the file has the correct headers.');
                        }
                    });
                } else {
                    setError('Invalid file format. Please upload a JSON or CSV file.');
                }
            } catch (e) {
                setError('Error reading file. Please ensure the file is formatted correctly.');
            }
        };

        if (file) {
            reader.readAsText(file);
        }
    };

    const handleInterpolation = () => {
        try {
            const ip = parseFloat(interpolationPoint);
            if (!surveyData.length) {
                setError('Please upload survey data or enter data manually.');
                return;
            }

            const result = interpolateSurv(surveyData, ip);
            setResults(result);
            setError('');
        } catch (err) {
            setError(err.message);
            setResults(null);
        }
    };

    const handleInputChange = (event, index, field) => {
        const updatedSurveyData = [...surveyData];
        updatedSurveyData[index][field] = parseFloat(event.target.value);
        setSurveyData(updatedSurveyData);
    };

    const addSurveyRow = () => {
        setSurveyData([...surveyData, { md_survey: '', tvd_survey: '', incl_survey: '', azim_survey: '' }]);
    };

    const handleReset = () => {
        setSurveyData([]);
        setInterpolationPoint('');
        setResults(null);
        setError('');
    };

    return (
        <Container className='min-vh-100'>
            <h2 className="mt-4">Survey Interpolation Tool</h2>

            <Row className="mt-3">
                <Col md={6}>
                    <Form.Group controlId="formFileUpload" className="mb-3">
                        <Form.Label>Upload Survey Data (JSON or CSV format):</Form.Label>
                        <Form.Control type="file" accept=".json,.csv" onChange={handleFileUpload} />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>Or Enter Survey Data Manually</p>
                    {surveyData.map((row, index) => (
                        <Row key={index} className="mb-2">
                            <Col>
                                <Form.Control
                                    type="number"
                                    placeholder="MD"
                                    value={row.md_survey || ''}
                                    onChange={(e) => handleInputChange(e, index, 'md_survey')}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="number"
                                    placeholder="TVD"
                                    value={row.tvd_survey || ''}
                                    onChange={(e) => handleInputChange(e, index, 'tvd_survey')}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="number"
                                    placeholder="Inclination (degrees)"
                                    value={row.incl_survey || ''}
                                    onChange={(e) => handleInputChange(e, index, 'incl_survey')}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="number"
                                    placeholder="Azimuth (degrees)"
                                    value={row.azim_survey || ''}
                                    onChange={(e) => handleInputChange(e, index, 'azim_survey')}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button variant="primary" onClick={addSurveyRow}>
                        Add Row
                    </Button>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={6}>
                    <h5>Enter Interpolation Point (MD):</h5>
                    <Form.Group controlId="formInterpolationPoint" className="mb-3">
                        <Form.Control
                            type="number"
                            placeholder="Interpolation Point"
                            value={interpolationPoint}
                            onChange={(e) => setInterpolationPoint(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} className="d-flex align-items-end">
                    <Button variant="primary" onClick={handleInterpolation}>
                        Interpolate
                    </Button>
                    <Button variant="secondary" className="ms-2" onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
            </Row>

            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}

            {results && (
                <Row className="mt-4">
                    <Col>
                        <h3>Interpolation Results:</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>IP:</td>
                                    <td>{results.ip}</td>
                                </tr>
                                <tr>
                                    <td>Inclination:</td>
                                    <td>{results.inclination.toFixed(2)}°</td>
                                </tr>
                                <tr>
                                    <td>Azimuth:</td>
                                    <td>{results.azimuth.toFixed(2)}°</td>
                                </tr>
                                <tr>
                                    <td>TVD:</td>
                                    <td>{results.tvd.toFixed(2)} ft</td>
                                </tr>
                                <tr>
                                    <td>Dogleg:</td>
                                    <td>{results.dl.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>Dogleg Severity:</td>
                                    <td>{results.dls.toFixed(2)}°/100ft</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </Container>
    );
}
