import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

const UserOrderHistory = () => {
    const [orderHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        axios.get(`${baseURL}/order-history`, {
            withCredentials: true,
        })
            .then((response) => {
                const data = response.data.order_history;
                // setOrderHistory(response.data);
                setOrderHistory(Array.isArray(data) ? data : []);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching order history:', error);
                setOrderHistory([]); // Set an empty array in case of error
                setLoading(false);
            });
    }, [baseURL]);

    if (loading) {
        return <p>Loading order history...</p>;
    }

    // if (!orderHistory.length) {
    //     return <p>No order history available.</p>;
    // }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Invoice ID</th>
                    <th>Module Name</th>
                    <th>Payment Amount</th>
                    <th>Payment Date</th>
                    <th>Subscription Start</th>
                    <th>Subscription End</th>
                </tr>
            </thead>
            <tbody>
                {orderHistory.map((order) => (
                    <tr key={order.invoice_id}>
                        <td>{order.invoice_id}</td>
                        <td>{order.module_name}</td>
                        <td>${order.amount_paid !== null
                            ? parseFloat(order.amount_paid).toFixed(2)
                            : '0.00'}
                        </td>
                        <td>{new Date(order.payment_date).toLocaleDateString()}</td>
                        <td>{new Date(order.subscription_start_date).toLocaleDateString()}</td>
                        <td>{new Date(order.subscription_end_date).toLocaleDateString()}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default UserOrderHistory;
