import React, { useContext } from "react"
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import HeaderSite from "./headerSite";
import DesignHeaders from "./designHeaders";
import { GlobalStateContext } from "../GlobalStateContext";
import { useSurveyData } from "../trajectory/SurveyDataProvider";

// Use React.lazy to load the component only when it's required
const HeaderSurvey = React.lazy(() => import('../trajectory/headerSurvey'));
const DesignHoleSections = React.lazy(() => import('./designHoleSections'));


function DesignCaseTabs() {
    const { surveyData } = useSurveyData();
    const { selectedItems } = useContext(GlobalStateContext);

    // const [key, setKey] = useState('home');
    const [dataFromWellboreChild, setDataFromWellboreChild] = useState(null);

    // Callback function to receive data from child
    const receiveDataFromWellboreChild = (data) => {
        setDataFromWellboreChild(data);
    };


    return (
        
        <Tabs
            id="controlled-tab-header"
            defaultActiveKey="header"
            className="mb-3 mt-5"
        >
            <Tab eventKey="home" title="Get Started">
                <div className="">
                    <div>
                        <h5>Design Case</h5>
                        <p><b>Step 1</b></p>
                        <p>Get started by creating, retrieving, and/or updating a design case in the adjacent tabs:</p>
                        <ul>
                            <li>
                                Header information (company, block, field, well and wellbore)
                            </li>
                            <li>
                                Site information (name of the installation, location type, depth references, and temperature gradient)
                            </li>
                            <li>
                                Wellbore survey, and
                            </li>
                            <li>
                                Hole sections
                            </li>

                        </ul>
                        <p>You can also delete an existing design case.</p>
                        <hr />
                        <p><b>Step 2</b></p>
                        <p>Select Well Information in the sidebar menu, and create the input data to perform various designs.
                            This includes, fluids rheology, BHA, rig equipment, drilling parameters and drill bit information.
                        </p>
                        <hr />
                        <p><b>Step 3</b></p>
                        <p>Select any of the design types under Modules and click calculate, to perform the design and output the results.</p>
                        <hr />
                        <p><b>Step 4</b></p>
                        <p>View Reports and Charts</p>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="header" title="Header">
                {/* <h5>Header Information</h5> */}
                <p></p>
                <DesignHeaders sendWellboreDataToParent={receiveDataFromWellboreChild} />
                
            </Tab>
            <Tab eventKey="site" title="Site">
                <div className="mx-4">
                    <p>{selectedItems.well_name} surface location</p>
                </div>
                <HeaderSite wellId={selectedItems.well_id ? selectedItems.well_id : null} wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null} />

            </Tab>
            <Tab eventKey="survey" title="Survey" >
                {/* <h5>Wellbore Survey</h5> */}
                <div className="mx-2">
                    <p className="text-primary">{selectedItems.wellbore_name} wellbore survey</p>
                </div>
                {selectedItems.wellbore_id && (
                    <HeaderSurvey companyName={dataFromWellboreChild ? dataFromWellboreChild.company_name : null} wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null} wellboreName={selectedItems.wellbore_name ? selectedItems.wellbore_name : null} wellId={selectedItems.well_id ? selectedItems.well_id : null} wellName={selectedItems.wellbore_name ? selectedItems.well_name : null} />
                )}

            </Tab>
            <Tab eventKey="holes" title="Hole sections">
                {surveyData.length > 2 && (
                    <>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}
                                <br />
                                Well design case: {selectedItems.well_design_case_name}
                            </p>
                        </div>
                        <DesignHoleSections
                            companyName={dataFromWellboreChild ? dataFromWellboreChild.company_name : null}
                            wellboreId={selectedItems.wellbore_id ? selectedItems.wellbore_id : null}
                            wellboreName={dataFromWellboreChild ? dataFromWellboreChild.wellbore_name : null}
                            wellId={selectedItems.well_id ? selectedItems.well_id : null}
                            wellName={dataFromWellboreChild ? dataFromWellboreChild.well_name : null}
                        />
                    </>
                )}
            </Tab>

        </Tabs>
    );
}

export default DesignCaseTabs;

