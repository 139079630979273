import React, { useState, useEffect, useContext, useCallback } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { GlobalStateContext } from '../../GlobalStateContext';
import { Modal, Button, Alert, Tabs, Tab } from "react-bootstrap";

import AddEarthModelForm from './AddEarthModelForm';
import {
    ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import EditEarthModelModal from './EditEarthModelModal';

function EarthModelTable(props) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const { selectedItems } = useContext(GlobalStateContext);
    const [earthModelData, setEarthModelData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success'); // success, danger, etc.

    const [showAddEarthModelModal, setShowAddEarthModelModal] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentEarthModel, setCurrentEarthModel] = useState(null);
    const [formData, setFormData] = useState({
        tvd: '',
        pore_press: '',
        frac_grad: '',
        temp: '',
        gas_grad: '',
        well_id: selectedItems.well_id,
        wellbore_id: selectedItems.wellbore_id,
        well_design_case_id: selectedItems.well_design_case_id,
        earth_model_id: '',
        formation_name: '',
        formation_type: '',
        user_id: '',
    });

    const [formationNames, setFormationNames] = useState([]);
    const [formationTypes, setFormationTypes] = useState([]);

    const fetchFormationNames = useCallback(() => {
        axios({
            method: "GET",
            url: `${baseURL}/input/formations`, // Adjust the URL as necessary
            withCredentials: true
        })
            .then((response) => {
                setFormationNames(response.data);
                // console.log('setFormationNames', response.data[0]);

            })
            .catch((error) => {
                console.log(error.response);
            });
    }, [baseURL]);

    const fetchFormationTypes = useCallback(() => {
        axios({
            method: "GET",
            url: `${baseURL}/input/formation_type`, // Adjust the URL as necessary
            withCredentials: true
        })
            .then((response) => {
                setFormationTypes(response.data);
                // console.log('setFormationTypes', response.data[0]);

            })
            .catch((error) => {
                console.log(error.response);
            });
    }, [baseURL]);

    const fetchEarthModelData = useCallback(() => {
        axios({
            method: "GET",
            url: `${baseURL}/input/earth_model/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`,
            withCredentials: true
        })
            .then((response) => {
                const res = response.data;
                setEarthModelData(res);

            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });
    }, [selectedItems.well_id, selectedItems.wellbore_id, selectedItems.well_design_case_id, baseURL]);

    useEffect(() => {
        fetchEarthModelData();
        fetchFormationNames();
        fetchFormationTypes();
    }, [fetchEarthModelData, fetchFormationNames, fetchFormationTypes]);

    const handleEditClick = (earthModel) => {
        setCurrentEarthModel(earthModel);
        setFormData({
            tvd: earthModel.tvd,
            pore_press: earthModel.pore_press,
            frac_grad: earthModel.frac_grad,
            temp: earthModel.temp,
            gas_grad: earthModel.gas_grad,
            well_id: selectedItems.well_id,
            wellbore_id: selectedItems.wellbore_id,
            well_design_case_id: selectedItems.well_design_case_id,
            earth_model_id: earthModel.earth_model_id,
            formation_name: earthModel.formation_name,
            formation_type: earthModel.formation_type,
        });
        setShowEditModal(true);
    };

    const handleDeleteClick = (earthModel) => {
        setCurrentEarthModel(earthModel);
        setShowDeleteModal(true);
    };

    const sanitizeFormData = (data) => {
        return {
            ...data,
            tvd: parseFloat(data.tvd),
            pore_press: parseFloat(data.pore_press),
            frac_grad: parseFloat(data.frac_grad),
            temp: parseFloat(data.temp),
            gas_grad: parseFloat(data.gas_grad),
            well_id: parseInt(data.well_id),
            wellbore_id: parseInt(data.wellbore_id),
            well_design_case_id: parseInt(data.well_design_case_id),
            earth_model_id: data.earth_model_id ? parseInt(data.earth_model_id) : undefined,
            formation_name_id: parseInt(formationNames[0].formation_name_id),
            formation_type_id: parseInt(formationTypes[0].formation_type_id),
            // user_id: data.user_id
        };
    };


    const handleEditSubmit = () => {
        const sanitizedData = sanitizeFormData(formData);
        // console.log("Submitting data:", sanitizedData);
        axios({
            method: "PUT",
            url: `${baseURL}/input/earth_model/${currentEarthModel.earth_model_id}`,
            withCredentials: true,
            data: sanitizedData
        })
            .then((response) => {
                // console.log("Response:", response);
                setShowEditModal(false);
                setAlertMessage('Data updated successfully.');
                setAlertVariant('success');
                setShowAlert(true);
                fetchEarthModelData();
                setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds

            })
            .catch((error) => {
                console.log("Error:", error);
                setAlertMessage('Failed to update data.');
                setAlertVariant('danger');
                setShowAlert(true);
            });
    };

    const handleDeleteConfirm = () => {
        axios({
            method: "DELETE",
            url: `${baseURL}/input/earth_model/${currentEarthModel.earth_model_id}`,
            withCredentials: true
        })
            .then((response) => {
                setShowDeleteModal(false);
                setAlertMessage('Data deleted successfully.');
                setAlertVariant('success');
                setShowAlert(true);
                fetchEarthModelData();
                setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds

            })
            .catch((error) => {
                setAlertMessage('Failed to delete data.');
                setAlertVariant('danger');
                setShowAlert(true);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: ['tvd', 'pore_press', 'frac_grad', 'temp', 'gas_grad'].includes(name) ? parseFloat(value) : value
        });
    };

    const handleEarthAdded = () => {
        setAlertMessage('Data added successfully.');
        setAlertVariant('success');
        setShowAlert(true);
        fetchEarthModelData();
        setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds

    };

    // Determine the min and max values for the X axis
    const xMin = Math.min(
        ...earthModelData.map(d => Math.round(Math.min(d.pore_press - 2, d.frac_grad - 2)))
    );
    const xMax = Math.max(
        ...earthModelData.map(d => Math.max(d.pore_press, d.frac_grad))
    );

    const tMin = Math.min(
        ...earthModelData.map(d => Math.round((Math.min(d.temp - 5))))
    );
    const tMax = Math.max(
        ...earthModelData.map(d => Math.max(d.temp + 5))
    );

    return (
        <div className="container h-0">
            <Alert show={showAlert} variant={alertVariant}>
                {alertMessage}
            </Alert>

            <Tabs defaultActiveKey="chart" id="earth-model-tabs">
                <Tab eventKey="table" title="Table">
                    <div className="table-responsive small" style={{ overflowX: 'auto' }}>
                        <table className="table text-nowrap" style={{ tableLayout: 'auto' }}>
                            <thead className="border-top border-primary">
                                <tr>
                                    <th className='fw-normal'>TVD</th>
                                    <th className='fw-normal'>Formation</th>
                                    <th className='fw-normal'>Type</th>

                                    <th className='fw-normal'>PPG</th>
                                    <th className='fw-normal'>FG</th>
                                    <th className='fw-normal'>Temp</th>
                                    <th className='fw-normal'>&rho;<sub>g</sub></th>
                                    <th className="fw-normal">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {earthModelData.map((earthModel, index) => (
                                    <tr key={earthModel.earth_model_id}>
                                        <td>{earthModel.tvd}</td>
                                        <td>{earthModel.formation_name}</td>
                                        <td>{earthModel.formation_type}</td>

                                        <td>{earthModel.pore_press}</td>
                                        <td>{earthModel.frac_grad}</td>
                                        <td>{earthModel.temp}</td>
                                        <td>{earthModel.gas_grad}</td>
                                        <td>
                                            <Button size='sm' variant='outline-primary' onClick={() => handleEditClick(earthModel)}><i className="bi bi-pencil"></i></Button>{' '}
                                            <Button size='sm' variant='outline-danger' onClick={() => handleDeleteClick(earthModel)}><i className="bi bi-trash"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button
                            variant="primary"
                            onClick={() => { setShowAddEarthModelModal(true); }}
                            style={{ marginTop: '20px' }}
                        >
                            Add Data
                        </Button>

                        <AddEarthModelForm
                            show={showAddEarthModelModal}
                            handleClose={() => setShowAddEarthModelModal(false)}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onEarthDataAdded={handleEarthAdded}
                        />
                    </div>
                </Tab>
                <Tab eventKey="chart" title="Chart">
                    <div className="chart-container d-flex justify-content-between">
                        <ResponsiveContainer width="49%" height={400}>
                            <ScatterChart
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" dataKey="pore_press" name="Pore Pressure Gradient" unit="ppg" domain={[xMin, xMax]} xAxisId="1" />
                                <XAxis type="number" dataKey="frac_grad" name="Frac Gradient" unit="ppg" domain={[xMin, xMax]} xAxisId="2" />
                                <YAxis type="number" dataKey="tvd" name="TVD" unit="ft" reversed />
                                <Tooltip />
                                <Legend />
                                <Scatter name="Pore Pressure Gradient" data={earthModelData} fill="#8884d8" xAxisId="1" line />
                                <Scatter name="Frac Gradient" data={earthModelData} fill="#82ca9d" xAxisId="2" line />
                            </ScatterChart>
                        </ResponsiveContainer>

                        <ResponsiveContainer width="49%" height={400}>
                            <ScatterChart
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" dataKey="temp" name="Temperature" unit="°F" domain={[tMin, tMax]} />
                                <YAxis type="number" dataKey="tvd" name="TVD" unit="ft" reversed />
                                <Tooltip />
                                <Legend />
                                <Scatter name="Temperature" data={earthModelData} fill="#ff7300" line />
                            </ScatterChart>
                        </ResponsiveContainer>
                    </div>
                </Tab>
            </Tabs>

            {/* Edit Modal */}
            {/* Edit Earth Model Data Modal */}
            <EditEarthModelModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                formData={formData}
                handleInputChange={handleInputChange}
                handleEditSubmit={handleEditSubmit}
                formationNames={formationNames}
                formationTypes={formationTypes}
            />

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Earth Model Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this data?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EarthModelTable;
