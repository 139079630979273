import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { Button, Spinner } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import logo from '../../images/logo-blue.png';

function Login() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
        rememberMe: false,
    });
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const { isAuthenticated, setIsAuthenticated, setIsTwoFactorVerified, setIsTwoFactorEnabled, isAdmin, setIsAdmin, isTwoFactorVerified } = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function handleLogin(event) {
        event.preventDefault();

        if (!executeRecaptcha) {
            console.error("reCAPTCHA not initialized");
            return;
        }

        // Validate email and password
        if (!loginForm.email.trim()) {
            setEmailError("Email cannot be empty.");
            return;
        } else {
            setEmailError("");
        }

        if (!loginForm.password.trim()) {
            setPasswordError("Password cannot be empty.");
            return;
        } else {
            setPasswordError("");
        }

        setLoading(true); // Set loading true before the async operation
        try {
            const recaptchaToken = await executeRecaptcha("login");

            const response = await axios.post(
                `${baseURL}/login`,
                {
                    email: loginForm.email,
                    password: loginForm.password,
                    recaptchaToken,
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                const { two_factor_enabled, is_admin } = response.data;
                console.log('API Response:', response.data);
                
                setIsAdmin(is_admin); // Set admin status
                console.log('Updated isAdmin:', isAdmin);

                console.log('is_admin', is_admin)

                if (two_factor_enabled) {
                    setIsAuthenticated(true);
                    setIsTwoFactorEnabled(true);
                } else {
                    verifyToken();
                }
            }
        } catch (error) {
            console.error("reCAPTCHA error:", error);

            if (error.response && error.response.status === 401) {
                setPasswordError("Invalid username or password");
            } else {
                setPasswordError("An error occurred during login");
            }
        } finally {
            setLoading(false); // Ensure loading is false after the operation
        }
    }

    useEffect(() => {
        if (isAuthenticated && !isTwoFactorVerified) {
            // navigate('/2fa_verification');
            window.location.href = "/2fa_verification";
        }
    }, [isAuthenticated, navigate, isTwoFactorVerified]);

    const verifyToken = async () => {
        setLoading(true); // Set loading during token verification
        try {
            const response = await axios.get(`${baseURL}/verify_token`, { withCredentials: true });
            if (response.status === 200) {
                setIsTwoFactorVerified(true);
                setIsAuthenticated(true);
                alert("Successfully logged in");
                // navigate('/welcome');
                window.location.href = "/welcome";
            }
        } catch (error) {
            console.error('Token verification failed:', error);
        } finally {
            setLoading(false); // Ensure loading is reset
        }
    };

    const handleForgotPasswordClick = () => setShowForgotPasswordModal(true);
    const handleCloseForgotPasswordModal = () => setShowForgotPasswordModal(false);

    function handleChange(event) {
        const { value, name, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;

        setLoginForm(prevForm => ({
            ...prevForm,
            [name]: newValue,
        }));
    }

    return (
        <section className="bg-light py-3 py-md-5 vh-100 d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div className="card border border-light-subtle rounded-3 shadow-sm">
                            <div className="card-body p-3 p-md-4 p-xl-5">
                                <div className="text-center mb-3">
                                    <a href="#!">
                                        <img src={logo} alt="WellDesigner Logo" width="30" height="30" />
                                    </a>
                                </div>
                                <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Sign in to your account</h2>
                                <form onSubmit={handleLogin}>
                                    <div className="row gy-2 overflow-hidden">
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                                                    name="email"
                                                    id="email"
                                                    placeholder="name@example.com"
                                                    value={loginForm.email}
                                                    onChange={handleChange}
                                                    required
                                                    disabled={loading} // Disable input when loading
                                                />
                                                <label htmlFor="email">Email</label>
                                                {emailError && <div className="invalid-feedback">{emailError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="password"
                                                    className={`form-control ${passwordError ? "is-invalid" : ""}`}
                                                    name="password"
                                                    id="password"
                                                    placeholder="Password"
                                                    value={loginForm.password}
                                                    onChange={handleChange}
                                                    required
                                                    disabled={loading} // Disable input when loading
                                                />
                                                <label htmlFor="password">Password</label>
                                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex gap-2 justify-content-between">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="rememberMe"
                                                        id="rememberMe"
                                                        checked={loginForm.rememberMe}
                                                        onChange={handleChange}
                                                        disabled={loading} // Disable input when loading
                                                    />
                                                    <label className="form-check-label text-secondary" htmlFor="rememberMe">
                                                        Keep me logged in
                                                    </label>
                                                </div>
                                                <Button variant="link" className="p-0 link-primary text-decoration-none" onClick={handleForgotPasswordClick} disabled={loading}>
                                                    Forgot password?
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-grid my-3">
                                                <button className="btn btn-primary btn-lg" type="submit" disabled={loading}>
                                                    {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Log in"}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="m-0 text-secondary text-center">New to welldesigner.io? <Link to="/register" className="link-primary text-decoration-none">Sign up</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ForgotPasswordModal
                show={showForgotPasswordModal}
                handleClose={handleCloseForgotPasswordModal}
                baseURL={baseURL}
            />
        </section>
    );
}

export default Login;
