import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';

function AddCompanyModal({ userId, onSubmit, onClose }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [countryName, setCountryName] = useState("");

    const [saving, setSaving] = useState(false); // State to track saving process

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true); // Set saving state to true while request is in progress
        
        try {
            console.log('userId', userId);

            await axios.post(
                `${baseURL}/input/companies`,
                {
                    user_id: userId, // Use the userId prop passed from parent component
                    company_name: companyName,
                    address: companyAddress,
                    country: countryName
                },
                {
                    withCredentials: true, // Include credentials with the request
                    // headers: {
                    //     'X-CSRF-Token': document.cookie.replace(/(?:(?:^|.*;\s*)csrf_token\s*=([^;]*).*$)|^.*$/, "$1")
                    // }
                }
            );

            // Call onSubmit prop with the saved company name
            onSubmit(companyName);
            setCompanyName(""); // Reset the company name after submission
            setCompanyAddress(""); // Reset the company address after submission
            setCountryName(""); // Reset the country after submission

            alert(`${companyName} successfully added!`);
        } catch (error) {
            console.error('Error saving company name:', error);
            alert(`${companyName} was not added!`);
        } finally {
            setSaving(false); // Reset saving state after request is completed
            onClose(); // Close the modal
        }
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Enter the name of the company"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Company Address</Form.Label>
                        <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Enter the address of the company"
                            value={companyAddress}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            size="lg"
                            type="text"
                            placeholder="Enter the country of the company"
                            value={countryName}
                            onChange={(e) => setCountryName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={saving}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={saving}>
                    {saving ? 'Saving...' : 'Save'} {/* Show different text based on saving state */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCompanyModal;
