import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

const TrajectoryForm = ({
    surveyName,
    setSurveyName,
    sectionType,
    setSectionType,
    endMD,
    setEndMD,
    startIncl,
    setStartIncl,
    endIncl,
    setEndIncl,
    startAzimuth,
    setStartAzimuth,
    endAzimuth,
    setEndAzimuth,
    handleAddSection,
    showLabel,
    // setShowLabel,
    handleCheckboxChange,
    handleRemoveLast,
    handleReset,
}) => (
    <Form onSubmit={(e) => e.preventDefault()}>
        <Row className='mb-2'>
            <Form.Group as={Col} md={6} controlId="sectionName">
                <Form.Label>Section Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter the name of the section"
                    value={surveyName}
                    onChange={(e) => setSurveyName(e.target.value)}
                    required
                    maxLength={70}
                />
            </Form.Group>
            <Form.Group controlId="formCheckbox">
                <Form.Check
                    type="checkbox"
                    label="Show this name on the trajectory plots"
                    checked={showLabel}
                    onChange={handleCheckboxChange}
                />
            </Form.Group>
        </Row>
        <Row className="">
            <Form.Group as={Col} controlId="formSectionType">
                <Form.Label>Section Type</Form.Label>
                <Form.Control
                    as="select"
                    value={sectionType}
                    onChange={(e) => setSectionType(e.target.value)}
                >
                    <option value="vertical">Vertical Section</option>
                    <option value="build">Build Section</option>
                    <option value="tangent">Tangent Section</option>
                    <option value="horizontal">Horizontal Section</option>
                    <option value="drop">Drop Section</option>
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formEndMD">
                <Form.Label>End MD (ft)</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter End MD"
                    value={endMD}
                    onChange={(e) => setEndMD(e.target.value)}
                    required
                />
            </Form.Group>
        </Row>

        {(sectionType === 'build' || sectionType === 'drop') && (
            <>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formStartIncl">
                        <Form.Label>Start Inclination</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Start Inclination"
                            value={startIncl}
                            onChange={(e) => setStartIncl(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formEndIncl">
                        <Form.Label>End Inclination</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter End Inclination"
                            value={endIncl}
                            onChange={(e) => setEndIncl(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Row>
            </>
        )}

        {sectionType === 'build' && (
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formStartAzimuth">
                    <Form.Label>Start Azimuth</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter Start Azimuth"
                        value={startAzimuth}
                        onChange={(e) => setStartAzimuth(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="formEndAzimuth">
                    <Form.Label>End Azimuth</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter End Azimuth"
                        value={endAzimuth}
                        onChange={(e) => setEndAzimuth(e.target.value)}
                        required
                    />
                </Form.Group>
            </Row>
            
        )}

        <Button variant="primary" onClick={handleAddSection} title="Add section" aria-label="Add Section">
            Add Section
            
        </Button> {' '}
        <Button variant='danger' onClick={handleRemoveLast} title='Remove last section' aria-label="Delete Section">
            {/* Remove Last Section */}
            Delete Section

        </Button>
        
    </Form>
);

export default TrajectoryForm;
