import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Modal, Button, Form, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import useToken from '../../admin/useToken';
// import AddNewDrillPipeForm from '../addNewDrillPipeForm';
import AddNewHWDPForm from './AddNewHWDPForm';
// import { GlobalStateContext } from '../../GlobalStateContext';

function AddHwpBhaItem({ show, handleClose, bhaType, wellId, wellboreId, wellDesignCaseId, onBhaItemAdded }) {
    const { token } = useToken();
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    // const { selectedItems } = useContext(GlobalStateContext);

    const [od, setOd] = useState([]);
    // const [nomWeights, setNomWeights] = useState([]);
    const dpRange = ['1', '2', '3'];
    const dpClass = ['New', 'Premium', 'C-1', 'C-2', 'C-3'];
    const [dpInfo, setDpInfo] = useState(null);
    // const [dpGrade, setDpGrade] = useState(null);
    const dpGrade = ['1340', 'HT55', 'V-125']

    const [hwdpIntDia, setHwdpIntDia] = useState('');

    const [hwdpSizeId, setHwdpSizeId] = useState('');
    const [hwdpNomWt, setHwdpNomWt] = useState('');
    const [hwdpConn, setHwdpConn] = useState('');
    const [hwdpTjOd, setHwdpTjOd] = useState('');
    const [hwdpTjId, setHwdpTjId] = useState('');

    useEffect(() => {
        if (dpInfo && dpInfo.length > 0) {
            const hwdpData = dpInfo[0]; // Assuming dpInfo is an array with at least one object
            setHwdpSizeId(hwdpData.hwdp_size_id);
            setHwdpIntDia(hwdpData.hwdp_int_dia);
            setHwdpNomWt(hwdpData.hwdp_wt_incl_tj);
            setHwdpConn(hwdpData.hwdp_conn);
            setHwdpTjOd(hwdpData.hwdp_conn_od);
            setHwdpTjId(hwdpData.hwdp_conn_id);

        }
    }, [dpInfo]);

    const handleInputChange = (e) => {
        setHwdpSizeId(e.target.value);
        setHwdpIntDia(e.target.value);
        setHwdpNomWt(e.target.value);
        setHwdpConn(e.target.value);
        setHwdpTjOd(e.target.value);
        setHwdpTjId(e.target.value);

    };


    const [formData, setFormData] = useState({
        bha_type: bhaType,
        tool_unique_id: '',
        dp_size_id: '',
        dc_num_id: '',
        hwdp_size_id: '',
        jar_unique_id: '',
        casing_size_id: '',
        hwdp_size: '',
        hwdp_wt_incl_tj: '',
        hwdp_int_dia: '',
        hwdp_conn_od: '',
        hwdp_conn_id: '',
        tool_class: '',
        dp_grade: '',
        hwdp_conn: '',
        range_length: '',
        tool_length: ''
        // hint: use table names from hwdp_library where applicable

    });

    const handleOpenAddDrillPipeModal = () => setShowAddDrillPipeModal(true);
    const handleCloseAddDrillPipeModal = () => setShowAddDrillPipeModal(false);
    const handleNewDrillPipeAdded = () => {
        console.log('New drill pipe added!');
    };
    const [showAddDrillPipeModal, setShowAddDrillPipeModal] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const processOdValues = (odValues) => {
        return odValues.map(od => {
            od = od.toString();
            if (!od.includes('.')) {
                return parseFloat(od).toFixed(1);
            }
            return od;
        });
    };

    useEffect(() => {
        // fetchDrillPipeData();
        axios.get(`${baseURL}/input/hwdp_library/unique_od`, {
            withCredentials: true
        })
            .then(response => {
                const processedOdValues = processOdValues(response.data.od_values);
                setOd(processedOdValues);
            })
            .catch(error => {
                console.error('Error fetching unique ODs available in db:', error);
            });
    }, [baseURL, token]);

    const handleOdChange = (e) => {
        const dpSize = e.target.value;
        setFormData({ ...formData, dp_size: dpSize });

        axios.get(`${baseURL}/input/hwdp_library/${dpSize}`, {
            withCredentials: true
        })
            .then(response => {
                const parsedNomWeights = response.data.map(item => {
                    if (Number.isInteger(item.hwdp_wt_incl_tj)) {
                        return { ...item, hwdp_wt_incl_tj: parseFloat(item.hwdp_wt_incl_tj).toFixed(1) };
                    }
                    return item;
                });
                // setNomWeights(parsedNomWeights);
                // setDpInfo(response.data);
                setDpInfo(parsedNomWeights);

                console.log('setDpInfo', response.data)

            })
            .catch(error => {
                console.error('Error fetching dp nom wt:', error);
            });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: `${baseURL}/input/bha_items`,
            withCredentials: true,
            data: {
                well_id: parseFloat(wellId),
                wellbore_id: parseFloat(wellboreId),
                well_design_case_id: parseFloat(wellDesignCaseId),
                // bha_type: bhaType,
                tool_unique_id: null,
                dp_size_id: null,
                dc_num_id: null,
                hwdp_size_id: parseFloat(hwdpSizeId),
                jar_unique_id: null,
                casing_size_id: null,
                // dp_size: parseFloat(formData.dp_size),
                // dp_nom_wt: parseFloat(formData.dp_nom_wt),
                // dp_id: parseFloat(dpInfo.dp_id),
                tool_jt_od: parseFloat(hwdpTjOd),
                tool_jt_id: parseFloat(hwdpTjId),
                tool_class: formData.tool_class,
                dp_grade: formData.dp_grade,
                // dp_conn: formData.dp_conn,
                range_length: parseFloat(formData.range_length),
                tool_length: parseFloat(formData.tool_length)

                // ...formData
                // hint: use table names from bha_items where applicable

            }
        }).then(response => {
            onBhaItemAdded();
            handleClose();
        }).catch(error => {
            console.error("Error adding BHA item:", error);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add {bhaType} to the BHA</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="od">
                        <Form.Label column sm={4}>Outer Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_size} onChange={handleOdChange} required>
                                <option value="">Select OD</option>
                                {od.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="nomWt">
                        <Form.Label column sm={4}>Nominal Weight</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                className="form-control"
                                value={hwdpNomWt}
                                onChange={handleInputChange}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpId">
                        <Form.Label column sm={4}>Internal Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                className="form-control"
                                value={hwdpIntDia}
                                onChange={handleInputChange}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpGrade">
                        <Form.Label column sm={4}>Grade</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_grade} onChange={(e) => setFormData({ ...formData, dp_grade: e.target.value })} required>
                                <option value="">Select Grade</option>
                                {dpGrade && dpGrade.length > 0 && dpGrade.map((grade, index) => (
                                    <option key={index} value={grade}>{grade}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="connection">
                        <Form.Label column sm={4}>Connection</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                className="form-control"
                                value={hwdpConn}
                                onChange={handleInputChange}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="class">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.tool_class} onChange={(e) => setFormData({ ...formData, tool_class: e.target.value })} required>
                                <option value="">Select Class</option>
                                {dpClass.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjOd">
                        <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                className="form-control"
                                value={hwdpTjOd}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpTjId">
                        <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                className="form-control"
                                value={hwdpTjId}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpRange">
                        <Form.Label column sm={4}>Range</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.range_length} onChange={(e) => setFormData({ ...formData, range_length: e.target.value })} required>
                                <option value="">Select Range</option>
                                {dpRange.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="length">
                        <Form.Label column sm={4}>Length</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                // placeholder="Enter length"
                                name="tool_length"
                                value={formData.tool_length}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    {/* Additional form fields based on bhaType */}

                    <Button variant="primary" type="submit">
                        Add
                    </Button>

                    <div className='mt-2'>
                        Can't find the right {bhaType}?{' '}
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="add-to-db-tooltip">Click to add a new BHA item to the database</Tooltip>}
                        >
                            <button type='button' className="text-decoration-none btn btn-link p-0" onClick={handleOpenAddDrillPipeModal}>Add it to the database.</button>
                        </OverlayTrigger>

                        <AddNewHWDPForm
                            show={showAddDrillPipeModal}
                            handleClose={handleCloseAddDrillPipeModal}
                            onNewDrillPipeAdded={handleNewDrillPipeAdded}
                        />
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AddHwpBhaItem;
