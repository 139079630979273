import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { calculateSurveyDataFields } from '../utils/calculateSurveyDataFields';
import { GlobalStateContext } from '../GlobalStateContext';

const FullScreenModal = ({ show, handleClose, surveyData }) => {
    const { selectedItems } = useContext(GlobalStateContext);
    const siteData = selectedItems?.siteData;

    // State to store the calculated data
    const [calculatedData, setCalculatedData] = useState([]);

    // Round function to round values
    const round = (n, decimals = 0) =>
        Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);

    // Effect to calculate survey data fields asynchronously
    useEffect(() => {
        const fetchCalculatedData = async () => {
            if (surveyData && siteData) {
                // Await the asynchronous calculateSurveyDataFields function
                const data = await calculateSurveyDataFields(surveyData, siteData);
                setCalculatedData(data); // Set the calculated data
            }
        };

        fetchCalculatedData();
    }, [surveyData, siteData]);

    return (
        <Modal show={show} onHide={handleClose} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Survey Data Overview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Survey Data</h4>
                <div>
                    Well: {selectedItems.well_name}<br />
                    Wellbore: {selectedItems.wellbore_name}<br />
                    Survey date: <br />
                    Coordiante reference system: <br />
                    Location lat/long: <br />
                    Location Grid Northing/Easting: <br />
                    CRS Grid Convergence Angle: <br />
                    Grid Scale Factor: <br />
                    Survey/DLS computation method: Minimum Curvature<br />
                    Vertical section azimuth:<br />
                    Vertical Section Origin: 0/0<br />
                    TVD Reference Datum: RKB <br />
                    TVD Reference Elevation: <br />
                    Seabed / Ground Elevation:<br />
                    Magnetic Declination:<br />
                    

                    
                </div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>MD(ft)</th>
                            <th>Incl</th>
                            <th>Azi Grid</th>
                            <th>NS(ft)</th>
                            <th>EW(ft)</th>
                            <th>DLS</th>
                            <th>BR</th>
                            <th>TR</th>
                            <th>TVD(ft)</th>
                            <th>TVDSS(ft)</th>
                            <th>VS(ft)</th>
                            <th>Northing(m)</th>
                            <th>Easting(m)</th>
                            <th>Lat</th>
                            <th>Long</th>
                            {/* Add any other fields as necessary */}
                        </tr>
                    </thead>
                    <tbody>
                        {calculatedData.map((survey, index) => (
                            <tr key={index}>
                                <td>{survey.md_survey}</td>
                                <td>{survey.incl_survey}</td>
                                <td>{survey.azim_survey}</td>
                                <td>{round(survey.ns, 2)}</td>
                                <td>{round(survey.ew, 2)}</td>
                                <td>{round(survey.dls_survey, 2)}</td>
                                <td>{round(survey.build_rate, 2)}</td>
                                <td>{round(survey.turn_rate, 2)}</td>
                                <td>{round(survey.tvd_survey, 2)}</td>
                                <td>{round(survey.tvdss, 2)}</td>
                                <td>{round(survey.phd, 2)}</td>
                                <td>{round(survey.northing, 2)}</td>
                                <td>{round(survey.easting, 2)}</td>
                                <td>{survey.latitude}</td>
                                <td>{survey.longitude}</td>
                                {/* Add other values as necessary */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FullScreenModal;
