import proj4 from 'proj4';
import axios from 'axios';

// Fetch the EPSG data for the given code
const fetchEPSGData = async (code) => {
    try {
        const projResponse = await axios.get(`https://epsg.io/${code}.proj4`);
        // const jsonResponse = await axios.get(`https://epsg.io/${code}.json`);
        // const epsgName = jsonResponse.data.name || 'Unknown EPSG Code'; // Get EPSG name
        return projResponse.data;
    } catch (error) {
        console.error('Error fetching EPSG data:', error);
        return null;
    }
};


const convertToDMS = (decimal) => {
    const degrees = Math.floor(decimal);
    const minutesDecimal = (decimal - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = Math.round((minutesDecimal - minutes) * 60);
    return `${degrees}°${minutes}'${seconds}"`;
};

// const convertToDM = (decimal) => {
//     const degrees = Math.floor(decimal);
//     const minutesDecimal = (decimal - degrees) * 60;
//     const minutes = minutesDecimal.toFixed(3);
//     return `${degrees}°${minutes}'`;
// };

export const calculateSurveyDataFields = async (surveyData, siteData) => {
    // const [utmZone, setUtmZone] = useState('32N');

    let cumulativeNS = 0;  // Store cumulative NS displacement
    let cumulativeEW = 0;  // Store cumulative EW displacement
    let cumulativeTVD = 0; // Store cumulative TVD

    // Fetch EPSG projection string for UTM conversion
    // const epsgCode = siteData?.epsg_code;
    const epsgCode = siteData?.epsg_code;

    const projString = await fetchEPSGData(epsgCode);

    if (!projString) {
        console.error('Failed to fetch EPSG data.');
        return surveyData; // Return the original survey data without transformation
    }

    // Initialize the source projection based on the fetched EPSG string
    const sourceProj = proj4(projString);

    const updatedSurveyData = await Promise.all(surveyData.map(async (survey, index) => {
        // Variables from the current survey point
        const currentMD = survey.md_survey;
        const currentIncl = survey.incl_survey;
        const currentAzim = survey.azim_survey;

        const siteNorthing = siteData?.northing;
        const siteEasting = siteData?.easting;
        // const waterDepth = siteData?.waterDepth;
        const rkbMsl = siteData?.rkb_msl;

        // Initialize NS, EW, TVD, and PHD for the first entry (base point)
        let ns = 0;
        let ew = 0;
        let tvd = survey.tvd_survey;  // TVD from the survey data or the cumulative one
        let phd = 0;  // Projected Horizontal Displacement
        let dl_survey = 0;  // Dogleg
        let dls_survey = 0;  // Dogleg severity

        let tvdss = tvd-rkbMsl;
        let northing = siteNorthing;
        let easting = siteEasting;
        
        let latitude = 0;
        let longitude = 0;

        let build_rate = 0;
        let turn_rate = 0;

        try {
            [longitude, latitude] = proj4(sourceProj, proj4.WGS84, [easting, northing]);
        } catch (error) {
            console.error('Error during UTM to Lat/Lon transformation:', error);
        }

        if (index > 0) {
            // Previous survey point
            const prevSurvey = surveyData[index - 1];
            const currentSurvey = surveyData[index];

            const prevMD = prevSurvey.md_survey;
            const prevIncl = prevSurvey.incl_survey;
            const prevAzim = prevSurvey.azim_survey;
            // const prevTvd = prevSurvey.tvd_survey;
            const currentTvd = currentSurvey.tvd_survey

            // Convert inclinations and azimuths to radians
            const inclRad1 = (prevIncl * Math.PI) / 180;
            const azimRad1 = (prevAzim * Math.PI) / 180;
            const inclRad2 = (currentIncl * Math.PI) / 180;
            const azimRad2 = (currentAzim * Math.PI) / 180;

            // Calculate the dogleg angle (beta)
            const deltaMD = currentMD - prevMD;
            const cosDogleg = Math.sin(inclRad1) * Math.sin(inclRad2) * Math.cos(azimRad2 - azimRad1)
                + Math.cos(inclRad1) * Math.cos(inclRad2);
            dl_survey = Math.acos(cosDogleg);  // Dogleg angle (in radians)

            // Avoid division by zero in case the dogleg is very small (near straight line)
            // const sinDogleg = Math.sin(dl_survey);
            // const RF = dl_survey === 0 ? 1 : (2 / dl_survey) * sinDogleg;
            const deltaIncl = toRadians(currentIncl - prevIncl);
            const deltaAzim = toRadians(currentAzim - prevAzim);


            const beta_i = Math.acos(Math.cos(deltaIncl) - Math.sin(toRadians(prevIncl)) * Math.sin(toRadians(currentIncl)) * (1 - Math.cos(deltaAzim)));
            const RF = beta_i === 0 ? 1 : (2 / beta_i) * Math.tan(beta_i / 2);

            // Calculate the new TVD, NS, EW using the Minimum Curvature Method
            const deltaTVD = (deltaMD / 2) * (Math.cos(inclRad1) + Math.cos(inclRad2)) * RF;
            const deltaNS = (deltaMD / 2) * (Math.sin(inclRad1) * Math.cos(azimRad1) + Math.sin(inclRad2) * Math.cos(azimRad2)) * RF;
            const deltaEW = (deltaMD / 2) * (Math.sin(inclRad1) * Math.sin(azimRad1) + Math.sin(inclRad2) * Math.sin(azimRad2)) * RF;

            // Cumulative North-South (NS) and East-West (EW) displacement
            cumulativeNS += deltaNS;
            cumulativeEW += deltaEW;
            ns = cumulativeNS;
            ew = cumulativeEW;

            // Update TVD
            cumulativeTVD += deltaTVD;
            tvd = cumulativeTVD;

            // Update Projected Horizontal Displacement (PHD)
            // const dN = ns - (prevSurvey.ns || 0);
            // const dE = ew - (prevSurvey.ew || 0);
            // phd = dN * Math.cos(toRadians(currentAzim)) + dE * Math.sin(toRadians(currentAzim));
            phd = Math.sqrt(ns ** 2 + ew ** 2);

            // Convert dogleg to degrees/100ft for reporting
            dls_survey = (dl_survey * 180) / (Math.PI * deltaMD) * 100;  // in degrees/100 ft

            if (prevAzim === 0 && currentAzim !== prevAzim) { 
                // for kick off points
                turn_rate = 0;
                
            } else if (currentIncl === prevIncl && currentAzim === prevAzim) {
                build_rate = 0;
                turn_rate = 0;

            } else if (currentIncl === prevIncl && currentAzim !== prevAzim) {
                build_rate = 0;
                turn_rate = (currentAzim - prevAzim) / deltaMD * 100;

            } else if (currentIncl !== prevIncl && currentAzim === prevAzim) {
                build_rate = (currentIncl - prevIncl) / deltaMD * 100;
                turn_rate = 0;

            } else {
                // both are no zero
                build_rate = (currentIncl - prevIncl) / deltaMD * 100;
                turn_rate = (currentAzim - prevAzim) / deltaMD * 100;

            }


            // parameters for extended survey report
            // tvdss, northing, easting, latitude, longitude
            // others: turn dls, build dls, closure
            tvdss = currentTvd-rkbMsl;

            northing = ns / 3.28 + siteNorthing; // convert ft to m
            easting = ew / 3.28 + siteEasting;

            // Convert UTM to lat/lon using the fetched projection string and sourceProj
            try {
                [longitude, latitude] = proj4(sourceProj, proj4.WGS84, [easting, northing]);
            } catch (error) {
                console.error('Error during UTM to Lat/Lon transformation:', error);
            }
        }

        longitude = convertToDMS(longitude);
        latitude = convertToDMS(latitude);

        // Return updated survey object
        return {
            ...survey,
            ns,  // North-South cumulative
            ew,  // East-West cumulative
            phd, // Projected Horizontal Displacement
            tvd, // True Vertical Depth cumulative
            dl_survey: dl_survey * (180 / Math.PI),  // Convert radians to degrees
            dls_survey,  // Dogleg severity in degrees/100ft
            tvdss,       // TVD subsea level
            northing,    // UTM northing
            easting,     // UTM easting
            latitude,    // Latitude
            longitude,    // Longitude
            turn_rate,
            build_rate
        };
    }));

    return updatedSurveyData;
};

// Helper function to convert degrees to radians
const toRadians = (degrees) => (degrees * Math.PI) / 180;
