import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

export const FieldList = () => (
    <List>
        <Datagrid>
            {/* <ReferenceField source="block_id" reference="blocks" /> */}
            <ReferenceField source="block_id" reference="blocks" label="Block">
                <TextField source="block_name" />
            </ReferenceField>
            <TextField source="id" />
            <TextField source="field_name" />
        </Datagrid>
    </List>
);