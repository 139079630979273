import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useHoleSection } from "../../HoleSectionProvider";

export const CasingContext = createContext();

export const CasingProvider = ({ children }) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [casingData, setCasingData] = useState({
        casingInfo: null,
        pressureRatings: {
            burst: 0,
            collapse: 0,
            tensile: 0,
        },
    });
    const [loading, setLoading] = useState(false);

    const { selectedHoleItems } = useHoleSection();

    const [casingInfo, setCasingInfo] = useState({
        intDia: selectedHoleItems?.casedHoleParameters?.casing_id || "",
        grade: selectedHoleItems?.casedHoleParameters?.casing_grade || "",
        size: parseFloat(selectedHoleItems?.casedHoleParameters?.casing_size || 0),
        wt: parseFloat(selectedHoleItems?.casedHoleParameters?.casing_wt || 0),
        casingProperties: null,
        prevCasingProperties: null,
        prevCasingGrade: selectedHoleItems?.casingAboveDrillingLiner?.casing_grade || "",
        prevCasingSize: selectedHoleItems?.casingAboveDrillingLiner?.casing_size || "",
        prevCasingWt: selectedHoleItems?.casingAboveDrillingLiner?.casing_wt || "",
        casingWear: selectedHoleItems?.casedHoleParameters?.casing_wear,
    });

    const fetchCasingData = async (casingSize, casingWt, casingGrade) => {
        try {
            if (!casingSize || !casingWt || !casingGrade) {
                console.error("casingSize, casingWt, and casingGrade must be provided");
                return null;
            }

            setLoading(true);
            const response = await axios.get(`${baseURL}/input/casing`, {
                params: { casing_size: casingSize, casing_wt: casingWt, casing_grade: casingGrade },
                withCredentials: true,
            });

            return response.data;
        } catch (error) {
            console.error(error.response ? error.response.data : "Error fetching data");
            return null;
        } finally {
            setLoading(false);
        }
    };

    const loadCasingData = async () => {
        const properties = await fetchCasingData(casingInfo.size, casingInfo.wt, casingInfo.grade);
        if (properties) {
            setCasingInfo((prev) => ({
                ...prev,
                casingProperties: properties,
            }));
            setCasingData((prev) => ({
                ...prev,
                casingInfo: properties,
                pressureRatings: {
                    burst: properties.internal_yield_pe,
                    collapse: properties.collapse,
                    tensile: properties.body_yield * 1000,
                },
            }));
        }
    };

    const loadPrevCasingData = async () => {
        const properties = await fetchCasingData(
            casingInfo.prevCasingSize,
            casingInfo.prevCasingWt,
            casingInfo.prevCasingGrade
        );
        if (properties) {
            setCasingInfo((prev) => ({
                ...prev,
                prevCasingProperties: properties,
            }));
        }
    };

    useEffect(() => {
        if (casingInfo.size && casingInfo.wt && casingInfo.grade) {
            loadCasingData();
        }
    }, [casingInfo.size, casingInfo.wt, casingInfo.grade]);

    useEffect(() => {
        if (casingInfo.prevCasingSize && casingInfo.prevCasingWt && casingInfo.prevCasingGrade) {
            loadPrevCasingData();
        }
    }, [casingInfo.prevCasingSize, casingInfo.prevCasingWt, casingInfo.prevCasingGrade]);

    return (
        <CasingContext.Provider value={{ casingInfo, casingData, loadCasingData, loadPrevCasingData, loading }}>
            {children}
        </CasingContext.Provider>
    );
};

export const useCasingData = () => useContext(CasingContext);
