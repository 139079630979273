import React, { useEffect, useState, useContext } from "react";
import ActiveModules from "./ActiveModules";
import axios from "axios";
import { GlobalStateContext } from "../GlobalStateContext";
// import LeftSidebar from "../../pages/tabs_components/LeftSidebar";
import Breadcrumbs from "../../pages/tabs_components/Breadcrumb";
import { Col, Row, Container } from 'react-bootstrap';

const ModulesDashboard = () => {
    const [activeModules, setActiveModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);
    const userId = selectedItems?.userId;
    // const fullName = selectedItems?.fullName;

    useEffect(() => {
        const fetchActiveModules = async () => {
            console.log('fetching modules... with user ID: ', userId);

            try {
                const response = await axios.get(`${baseURL}/user/active-modules`, {
                    headers: {
                        "User-Id": userId // Replace with dynamic user ID
                    },
                    withCredentials: true

                });
                setActiveModules(response.data);
                console.log('active modules: ', response.data);
                const active_modules = response.data;


                // Update the global state
                setSelectedItems(prevState => ({
                    ...prevState,
                    active_modules: active_modules,
                }));

            } catch (err) {
                setError(err.response ? err.response.data.error : "Failed to fetch modules");
            } finally {
                setLoading(false);
            }
        };

        fetchActiveModules();
    }, [baseURL, userId, setSelectedItems]);

    if (loading) return <p>Loading modules...</p>;
    if (error) return <p>Error: {error}</p>;

    return (

        <>
            <Breadcrumbs />

            <Container className="min-vh-100">
                <Row>
                    {/* Left Sidebar */}
                    {/* <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />
                    </Col> */}

                    {/* Main Content Area */}
                    <Col xs={12} md={6} lg={12} className="content-area mt-5">
                        {/* <h6 className="">Welcome back, {fullName}</h6> */}
                        
                        <ActiveModules activeModules={activeModules} userId={userId}  />
                    </Col>

                    {/* Right Sidebar (Optional) */}
                    {/* <Col xs={12} md={3} lg={2} className="d-none d-md-block"> */}
                        {/* <RightSidebar /> */}

                    {/* </Col> */}
                </Row>
            </Container>
        </>
    );
};

export default ModulesDashboard;
