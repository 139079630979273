import React, { useContext, useState } from 'react';
import { Nav, Card, ListGroup, Collapse, Button } from 'react-bootstrap';
import { GlobalStateContext } from '../../components/GlobalStateContext';
import './rightSidebar.css'
import { useHoleSection } from '../../components/HoleSectionProvider';

const RightSidebar = () => {
    const [open, setOpen] = useState(false);
    const { selectedHoleItems } = useHoleSection();

    const { selectedItems } = useContext(GlobalStateContext);
    const {
        company_name,
        block_name,
        field_name,
        well_name,
        wellbore_name,
        well_type,
        units_type,
        well_design_case_name
    } = selectedItems;

    const casedHoleMd = selectedHoleItems?.casedHoleParameters?.depth_md
    const openHoleMd = selectedHoleItems?.openHoleParameters?.depth_md
    const casedHoleTvd = selectedHoleItems?.casedHoleTvd;
    const openHoleTvd = selectedHoleItems?.openHoleTvd;

    const bitDepthMd = selectedItems?.drillingParameters?.string_depth

    return (
        <Nav className="flex-column bg-white sidebar mt-5">
            {/* Help Link */}
            <Nav.Link href="#help">Help</Nav.Link>

            {/* Well Design Case Information */}
            {/* <Nav.Link href="#help">Well Design Case Info</Nav.Link> */}
            
            <div>
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className='btn dropdown-toggle'
                >
                    {open ? 'Hide Design Case': 'View Design Case'}
                </Button>
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        <Card.Body className="p-0">
                            <ListGroup variant="flush" className="custom-list-group">
                                {company_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Company: {company_name}
                                    </ListGroup.Item>
                                )}
                                {block_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Block: {block_name}
                                    </ListGroup.Item>
                                )}
                                {field_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Field: {field_name}
                                    </ListGroup.Item>
                                )}
                                {well_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Well: {well_name}
                                    </ListGroup.Item>
                                )}
                                {wellbore_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Well Bore: {wellbore_name}
                                    </ListGroup.Item>
                                )}
                                {well_type && (
                                    <ListGroup.Item className="custom-list-item">
                                        Well Type: {well_type}
                                    </ListGroup.Item>
                                )}
                                {units_type && (
                                    <ListGroup.Item className="custom-list-item">
                                        Units: {units_type}
                                    </ListGroup.Item>
                                )}
                                {well_design_case_name && (
                                    <ListGroup.Item className="custom-list-item">
                                        Design Case: {well_design_case_name}
                                    </ListGroup.Item>
                                )}
                                {casedHoleMd && (
                                    <ListGroup.Item className="custom-list-item">
                                        Casing Depth: {casedHoleMd} / {casedHoleTvd}
                                    </ListGroup.Item>
                                )}
                                {openHoleMd && (
                                    <ListGroup.Item className="custom-list-item">
                                        Hole Depth: {openHoleMd} / {openHoleTvd}
                                    </ListGroup.Item>
                                )}
                                {bitDepthMd && (
                                    <ListGroup.Item className="custom-list-item">
                                        Bit Depth: {bitDepthMd}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card.Body>
                    </div>
                </Collapse>
            </div>
        </Nav>
    );
};

export default RightSidebar;
