import React from "react";
import { Link } from "react-router-dom";

function SubscriptionSuccess() {
    return (
        <div className="container mt-5 min-vh-100">
            <title>Thanks for your order!</title>
            <h1>Thanks for your order!</h1>
            <p>
                We appreciate your business! If you have any questions, please email 
                <a href="mailto:support@welldesigner.io">support</a>.
            </p>
            <p>
                <Link to='/design_case'>Click here to set up a design case and get started.</Link>

            </p>
        </div>

    );

}
export default SubscriptionSuccess;